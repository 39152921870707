property-store .icon-help {
  color: #54e346 !important;
}

property-store .zone-top {
  height: 150px !important;
}

property-store .grey-light-background {
  background-color: #f7efef;
  border-radius: 10px;
  min-height: 250px;
}

property-store .warning-payment-procedure {
  margin: 0px 5px 0px 5px;
  padding: 2px 2px 2px 2px;
  min-width: 350px !important;
  font-weight: normal;
  font-size: 11px !important;
  border-radius: 10px 10px 10px 10px;
  background-color: #bbdefb !important;
  color: black;
}
