.grey-light-background-check-list {
  background-color: #f7efef;
  border-radius: 10px;
  min-height: 250px;
  padding: 5px;
}

.background-list-grey {
  background-color: #f7efef;
  border-radius: 10px;
  min-height: 100px;
  padding: 5px;
}
