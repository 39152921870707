agency-show-basic .img-agency {
  object-fit: cover;
  height: 300px !important;
  width: 100% !important;
}

agency-show-basic .img-logo-agency {
  object-fit: cover;
  height: 30px !important;
  width: auto !important;
  margin: 5px 10px 5px 20px;
}

agency-show-basic .color-text {
  color: white;
}
