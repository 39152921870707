crm-captcha-simple h1 {
  font-size: 12px !important;
  margin: 10px !important;
}

crm-captcha-simple .margin-top-captcha {
  margin-top: 10px !important;
}

.legals-unsubscribe-link {
  color: #039be5;
  font-weight: 600px;
}

.color-agency {
  color: #039be5;
}

.color-flooges {
  color: #c31c4a !important;
}

.warning-unsubscribe {
  margin: 20px 20px 20px 5px;
  padding: 20px 10px 20px 10px;
  max-height: 60px !important;
  font-weight: 600;
  border-radius: 10px 10px 10px 10px;
  background-color: #c31c4a;
  color: white;
}
