appraisal .icon-help {
  color: #54e346 !important;
}

appraisal #icon-pdf-appraisal {
  margin-bottom: 10px;
}

appraisal .zone-top {
  height: 150px !important;
}
