.grey-light-background-check-list {
  background-color: #f7efef;
  border-radius: 10px;
  min-height: 150px;
  padding: 15px 10px 15px 10px;
}

.grey-light-background-check-list-big {
  background-color: #f7efef;
  border-radius: 10px;
  min-height: 750px;
  padding: 5px;
}
