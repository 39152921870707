dashboard-property-last {
  height: 160px !important;
}

dashboard-property-last md-card-title-text md-icon {
  height: 60px !important;
  width: 60px !important;
}

dashboard-property-last #progress {
  margin-left: 10px !important;
  margin-top: -35px !important;
}

dashboard-property-last .shadow:hover {
  background-color: #fff;
}

dashboard-property-last md-progress-circular svg path {
  stroke: #fbbc05;
}
