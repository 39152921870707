html,
body {
  overflow: hidden;
  position: relative;
  height: 100%;
}

md-dialog md-toolbar {
  background-color: #3330e4 !important;
}

.link-adaix {
  color: #3330e4 !important;
}

.icon-done {
  color: green;
}

.user-transfer-card {
  height: 200px !important;
  padding: 10px;
}

.property-transfer-card {
  padding: 5px;
  margin: 0px;
}

.icon-user-portal {
  height: 40px !important;
  width: 40px !important;
  border-radius: 50%;
  padding: 2px !important;
}

.user-divider-portal {
  margin-bottom: 10px;
  border-bottom: 1px solid #dcdcdc;
}

.agency-show-basic-image {
  object-fit: cover !important;
  height: "180px" !important;
  width: "100%" !important;
}

.dialog-contact-particular-show {
  height: "600px" !important;
}

.colors_info {
  height: 50px !important;
  padding-top: 15px;
  text-align: center !important;
  background-color: #f7ecec !important;
  margin: auto;
}

.ng-invalid {
  border-color: #f00 !important;
}

.warning-pisoscom {
  background-color: #c31c4a;
  color: white !important;
  max-width: 800px !important;
  margin: 20px 20px 20px 5px;
  padding: 10px 10px 10px 10px;
  font-weight: 600;
  border-radius: 10px 10px 10px 10px;
}

.cashier-payment-dialog {
  min-height: 400px !important;
  padding: 10px;
}

.cashier-payment-error {
  max-width: 400px !important;
  text-align: center !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  padding: 10px;
}

.cashier-payment-zone-plan {
  margin-top: 10px !important;
  background-color: #e8b4b4 !important;
  border-radius: 5px;
}

.btn-cashier-payment-cancel-plan {
  height: 35px;
  width: 180px;
  padding: 2px;
  margin: 0px 5px 0px 5px;
  font-size: 10px;
}

.btn-cashier-payment-cancel-plan span {
  padding: 0px;
}

.btn-cashier-payment-cancel-plan md-icon {
  padding: 0px;
  margin: 0px;
}

.auth-register-subdomain-store-subdomain {
  background-color: #349de6;
  color: white;
  padding: 10px;
  border-radius: 10px 10px 10px 10px !important;
}

@media screen and (min-width: 600px) {
  .dialog-agency-show {
    margin-top: 50px !important;
  }
}

.plan-label, .bronze, .silver, .gold, .platinum {
  color: white;
  font-size: 24px;
  font-weight: 600;
  padding: 2px 8px;
  border-radius: 4px;
}

.bronze {
  background: #cd7f32 !important;
}

.silver {
  background: #c0c0c0 !important;
}

.gold {
  background: #ffd700 !important;
}

.platinum {
  background: #e5e4e2 !important;
}

.link-plus-info {
  margin-top: 10px;
  padding-left: 10px;
  text-decoration: none;
  color: #039be5;
}

.logo-portal-dialog {
  height: 50px !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.message-generic-dialog,
.warning-generic-dialog,
.warning-email-marketing-dialog {
  margin: 20px 20px 20px 5px;
  padding: 20px 10px 20px 10px;
  font-weight: 600;
  border-radius: 10px 10px 10px 10px;
}

.warning-generic-dialog,
.warning-email-marketing-dialog {
  background-color: #c31c4a;
  color: white;
}

.warning-meeting-dialog,
.warning-meeting-slim-dialog {
  margin: 0px 20px 5px 20px;
  padding: 2px 2px 2px 2px;
  max-width: 650px !important;
  font-weight: normal;
  font-size: 11px !important;
  border-radius: 10px 10px 10px 10px;
  background-color: #bbdefb !important;
  color: black;
}

.warning-email-marketing-dialog {
  padding: 12px 5px 10px 5px;
  max-width: 350px !important;
  font-weight: 400;
  font-size: 14px;
  height: 50px !important;
}

.property-store-photos-dialog img {
  height: 100%;
  width: 100%;
}

.logo-papernest-p,
.logo-papernest-xs-p {
  max-height: 100px !important;
  margin-top: 10px !important;
  background-color: white;
  padding: 10px;
  border-radius: 50%;
}

.logo-papernest-xs-p {
  max-height: 50px !important;
  padding: 5px;
}

.zone-top-dialog {
  height: 150px !important;
}

.grey-light-background-dialog-descriptions-chatgpt {
  background-color: #f7efef;
  border-radius: 10px;
  min-height: 150px;
  padding: 10px;
}

.grey-light-background-dialog-descriptions-chatgpt,
.grey-light-background-dialog-descriptions-chatgpt-warning {
  background-color: #f7efef;
  border-radius: 10px;
  min-height: 150px;
  padding: 10px;
}

.grey-light-background-dialog-descriptions-chatgpt-warning {
  min-height: 100px;
}

.formatted-description h3,
.formatted-description h2,
.formatted-description h1 {
  font-family: "Gilroy-Regular", sans-serif;
  font-weight: normal;
}

.formatted-description strong {
  font-weight: bold;
}

.formatted-description ul {
  list-style-type: disc;
  padding-left: 20px;
}

.formatted-description li {
  margin-bottom: 5px;
}

.formatted-description p {
  margin-bottom: 10px;
}

.key-property-image {
  width: 90%;
  float: center;
}

.key-card-size-mini {
  min-height: 400px !important;
}

.key-card-size {
  min-height: 680px !important;
}

.img-thumbnail-property-key {
  height: 100px;
  width: 150px;
  cursor: pointer;
}

.img-no-thumbnail-property-key {
  height: 55px;
  width: 55px;
  cursor: pointer;
}

.dialog-content-keychain {
  max-height: 80vh;
  overflow-y: auto;
  padding: 20px;
}

.dialog-content-keychain .dialog-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.dialog-content-keychain .dialog-header md-icon {
  font-size: 48px;
  margin-right: 10px;
}

.dialog-content-keychain .dialog-body .alert-options {
  margin-bottom: 10px;
}

.dialog-content-keychain .dialog-body .resume-section {
  margin-top: 20px;
}

.dialog-content-keychain .dialog-body .resume-section p {
  margin-bottom: 5px;
}

/* Generated by Font Squirrel (https://www.fontsquirrel.com) on June 25, 2016 */
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,400;1,300&display=swap");
body {
  font-family: "Lato", sans-serif !important;
  font-weight: 100;
}

.mdi::before {
  font-size: 24px;
  line-height: 14px;
}

.btn .mdi::before {
  position: relative;
  top: 4px;
}

.btn-xs .mdi::before {
  font-size: 18px;
  top: 3px;
}

.btn-sm .mdi::before {
  font-size: 18px;
  top: 3px;
}

.dropdown-menu .mdi {
  width: 18px;
}

.dropdown-menu .mdi::before {
  position: relative;
  top: 4px;
  left: -8px;
}

.nav .mdi::before {
  position: relative;
  top: 4px;
}

.navbar .navbar-toggle .mdi::before {
  position: relative;
  top: 4px;
  color: #FFF;
}

.breadcrumb .mdi::before {
  position: relative;
  top: 4px;
}

.breadcrumb a:hover {
  text-decoration: none;
}

.breadcrumb a:hover span {
  text-decoration: underline;
}

.alert .mdi::before {
  position: relative;
  top: 4px;
  margin-right: 2px;
}

.input-group-addon .mdi::before {
  position: relative;
  top: 3px;
}

.navbar-brand .mdi::before {
  position: relative;
  top: 2px;
  margin-right: 2px;
}

.list-group-item .mdi::before {
  position: relative;
  top: 3px;
  left: -3px;
}

/*
 * -----------------------------------------------------------------------------
 *	MARGIN
 * -----------------------------------------------------------------------------
*/
.margin-top-0 {
  margin-top: 0px !important;
}

.margin-bottom-0 {
  margin-bottom: 0px !important;
}

.margin-left-0 {
  margin-left: 0px !important;
}

.margin-right-0 {
  margin-right: 0px !important;
}

.margin-0 {
  margin: 0px !important;
}

.margin-top-1 {
  margin-top: 1px !important;
}

.margin-bottom-1 {
  margin-bottom: 1px !important;
}

.margin-left-1 {
  margin-left: 1px !important;
}

.margin-right-1 {
  margin-right: 1px !important;
}

.margin-1 {
  margin: 1px !important;
}

.margin-top-2 {
  margin-top: 2px !important;
}

.margin-bottom-2 {
  margin-bottom: 2px !important;
}

.margin-left-2 {
  margin-left: 2px !important;
}

.margin-right-2 {
  margin-right: 2px !important;
}

.margin-2 {
  margin: 2px !important;
}

.margin-top-3 {
  margin-top: 3px !important;
}

.margin-bottom-3 {
  margin-bottom: 3px !important;
}

.margin-left-3 {
  margin-left: 3px !important;
}

.margin-right-3 {
  margin-right: 3px !important;
}

.margin-3 {
  margin: 3px !important;
}

.margin-top-5 {
  margin-top: 5px !important;
}

.margin-bottom-5 {
  margin-bottom: 5px !important;
}

.margin-left-5 {
  margin-left: 5px !important;
}

.margin-right-5 {
  margin-right: 5px !important;
}

.margin-5 {
  margin: 5px !important;
}

.margin-top-8 {
  margin-top: 8px !important;
}

.margin-bottom-8 {
  margin-bottom: 8px !important;
}

.margin-left-8 {
  margin-left: 8px !important;
}

.margin-right-8 {
  margin-right: 8px !important;
}

.margin-8 {
  margin: 8px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-left-10 {
  margin-left: 10px !important;
}

.margin-right-10 {
  margin-right: 10px !important;
}

.margin-10 {
  margin: 10px !important;
}

.margin-top-15 {
  margin-top: 15px !important;
}

.margin-bottom-15 {
  margin-bottom: 15px !important;
}

.margin-left-15 {
  margin-left: 15px !important;
}

.margin-right-15 {
  margin-right: 15px !important;
}

.margin-15 {
  margin: 15px !important;
}

.margin-top-17 {
  margin-top: 17px !important;
}

.margin-bottom-17 {
  margin-bottom: 17px !important;
}

.margin-left-17 {
  margin-left: 17px !important;
}

.margin-right-17 {
  margin-right: 17px !important;
}

.margin-17 {
  margin: 17px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-left-20 {
  margin-left: 20px !important;
}

.margin-right-20 {
  margin-right: 20px !important;
}

.margin-20 {
  margin: 20px !important;
}

.margin-top-25 {
  margin-top: 25px !important;
}

.margin-bottom-25 {
  margin-bottom: 25px !important;
}

.margin-left-25 {
  margin-left: 25px !important;
}

.margin-right-25 {
  margin-right: 25px !important;
}

.margin-25 {
  margin: 25px !important;
}

.margin-top-30 {
  margin-top: 30px !important;
}

.margin-bottom-30 {
  margin-bottom: 30px !important;
}

.margin-left-30 {
  margin-left: 30px !important;
}

.margin-right-30 {
  margin-right: 30px !important;
}

.margin-30 {
  margin: 30px !important;
}

.margin-top-35 {
  margin-top: 35px !important;
}

.margin-bottom-35 {
  margin-bottom: 35px !important;
}

.margin-left-35 {
  margin-left: 35px !important;
}

.margin-right-35 {
  margin-right: 35px !important;
}

.margin-35 {
  margin: 35px !important;
}

.margin-top-40 {
  margin-top: 40px !important;
}

.margin-bottom-40 {
  margin-bottom: 40px !important;
}

.margin-left-40 {
  margin-left: 40px !important;
}

.margin-right-40 {
  margin-right: 40px !important;
}

.margin-40 {
  margin: 40px !important;
}

.margin-top-45 {
  margin-top: 45px !important;
}

.margin-bottom-45 {
  margin-bottom: 45px !important;
}

.margin-left-45 {
  margin-left: 45px !important;
}

.margin-right-45 {
  margin-right: 45px !important;
}

.margin-45 {
  margin: 45px !important;
}

.margin-top-50 {
  margin-top: 50px !important;
}

.margin-bottom-50 {
  margin-bottom: 50px !important;
}

.margin-left-50 {
  margin-left: 50px !important;
}

.margin-right-50 {
  margin-right: 50px !important;
}

.margin-50 {
  margin: 50px !important;
}

.margin-top-55 {
  margin-top: 55px !important;
}

.margin-bottom-55 {
  margin-bottom: 55px !important;
}

.margin-left-55 {
  margin-left: 55px !important;
}

.margin-right-55 {
  margin-right: 55px !important;
}

.margin-55 {
  margin: 55px !important;
}

.margin-top-60 {
  margin-top: 60px !important;
}

.margin-bottom-60 {
  margin-bottom: 60px !important;
}

.margin-left-60 {
  margin-left: 60px !important;
}

.margin-right-60 {
  margin-right: 60px !important;
}

.margin-60 {
  margin: 60px !important;
}

.margin-top-65 {
  margin-top: 65px !important;
}

.margin-bottom-65 {
  margin-bottom: 65px !important;
}

.margin-left-65 {
  margin-left: 65px !important;
}

.margin-right-65 {
  margin-right: 65px !important;
}

.margin-65 {
  margin: 65px !important;
}

.margin-top-70 {
  margin-top: 70px !important;
}

.margin-bottom-70 {
  margin-bottom: 70px !important;
}

.margin-left-70 {
  margin-left: 70px !important;
}

.margin-right-70 {
  margin-right: 70px !important;
}

.margin-70 {
  margin: 70px !important;
}

.margin-top-75 {
  margin-top: 75px !important;
}

.margin-bottom-75 {
  margin-bottom: 75px !important;
}

.margin-left-75 {
  margin-left: 75px !important;
}

.margin-right-75 {
  margin-right: 75px !important;
}

.margin-75 {
  margin: 75px !important;
}

.margin-top-80 {
  margin-top: 80px !important;
}

.margin-bottom-80 {
  margin-bottom: 80px !important;
}

.margin-left-80 {
  margin-left: 80px !important;
}

.margin-right-80 {
  margin-right: 80px !important;
}

.margin-80 {
  margin: 80px !important;
}

.margin-top-85 {
  margin-top: 85px !important;
}

.margin-bottom-85 {
  margin-bottom: 85px !important;
}

.margin-left-85 {
  margin-left: 85px !important;
}

.margin-right-85 {
  margin-right: 85px !important;
}

.margin-85 {
  margin: 85px !important;
}

.margin-top-90 {
  margin-top: 90px !important;
}

.margin-bottom-90 {
  margin-bottom: 90px !important;
}

.margin-left-90 {
  margin-left: 90px !important;
}

.margin-right-90 {
  margin-right: 90px !important;
}

.margin-90 {
  margin: 90px !important;
}

.margin-top-95 {
  margin-top: 95px !important;
}

.margin-bottom-95 {
  margin-bottom: 95px !important;
}

.margin-left-95 {
  margin-left: 95px !important;
}

.margin-right-95 {
  margin-right: 95px !important;
}

.margin-95 {
  margin: 95px !important;
}

.margin-top-100 {
  margin-top: 100px !important;
}

.margin-bottom-100 {
  margin-bottom: 100px !important;
}

.margin-left-100 {
  margin-left: 100px !important;
}

.margin-right-100 {
  margin-right: 100px !important;
}

.margin-100 {
  margin: 100px !important;
}

.margin-top-105 {
  margin-top: 105px !important;
}

.margin-bottom-105 {
  margin-bottom: 105px !important;
}

.margin-left-105 {
  margin-left: 105px !important;
}

.margin-right-105 {
  margin-right: 105px !important;
}

.margin-105 {
  margin: 105px !important;
}

.margin-top-110 {
  margin-top: 110px !important;
}

.margin-bottom-110 {
  margin-bottom: 110px !important;
}

.margin-left-110 {
  margin-left: 110px !important;
}

.margin-right-110 {
  margin-right: 110px !important;
}

.margin-110 {
  margin: 110px !important;
}

.margin-top-115 {
  margin-top: 115px !important;
}

.margin-bottom-115 {
  margin-bottom: 115px !important;
}

.margin-left-115 {
  margin-left: 115px !important;
}

.margin-right-115 {
  margin-right: 115px !important;
}

.margin-115 {
  margin: 115px !important;
}

.margin-top-120 {
  margin-top: 120px !important;
}

.margin-bottom-120 {
  margin-bottom: 120px !important;
}

.margin-left-120 {
  margin-left: 120px !important;
}

.margin-right-120 {
  margin-right: 120px !important;
}

.margin-120 {
  margin: 120px !important;
}

/*
 * -----------------------------------------------------------------------------
 *	MARGIN-LESS
 * -----------------------------------------------------------------------------
*/
.margin-less-top-0 {
  margin-top: -0px !important;
}

.margin-less-bottom-0 {
  margin-bottom: -0px !important;
}

.margin-less-left-0 {
  margin-left: -0px !important;
}

.margin-less-right-0 {
  margin-right: -0px !important;
}

.margin-less-0 {
  margin: -0px !important;
}

.margin-less-top-1 {
  margin-top: -1px !important;
}

.margin-less-bottom-1 {
  margin-bottom: -1px !important;
}

.margin-less-left-1 {
  margin-left: -1px !important;
}

.margin-less-right-1 {
  margin-right: -1px !important;
}

.margin-less-1 {
  margin: -1px !important;
}

.margin-less-top-2 {
  margin-top: -2px !important;
}

.margin-less-bottom-2 {
  margin-bottom: -2px !important;
}

.margin-less-left-2 {
  margin-left: -2px !important;
}

.margin-less-right-2 {
  margin-right: -2px !important;
}

.margin-less-2 {
  margin: -2px !important;
}

.margin-less-top-3 {
  margin-top: -3px !important;
}

.margin-less-bottom-3 {
  margin-bottom: -3px !important;
}

.margin-less-left-3 {
  margin-left: -3px !important;
}

.margin-less-right-3 {
  margin-right: -3px !important;
}

.margin-less-3 {
  margin: -3px !important;
}

.margin-less-top-5 {
  margin-top: -5px !important;
}

.margin-less-bottom-5 {
  margin-bottom: -5px !important;
}

.margin-less-left-5 {
  margin-left: -5px !important;
}

.margin-less-right-5 {
  margin-right: -5px !important;
}

.margin-less-5 {
  margin: -5px !important;
}

.margin-less-top-8 {
  margin-top: -8px !important;
}

.margin-less-bottom-8 {
  margin-bottom: -8px !important;
}

.margin-less-left-8 {
  margin-left: -8px !important;
}

.margin-less-right-8 {
  margin-right: -8px !important;
}

.margin-less-8 {
  margin: -8px !important;
}

.margin-less-top-10 {
  margin-top: -10px !important;
}

.margin-less-bottom-10 {
  margin-bottom: -10px !important;
}

.margin-less-left-10 {
  margin-left: -10px !important;
}

.margin-less-right-10 {
  margin-right: -10px !important;
}

.margin-less-10 {
  margin: -10px !important;
}

.margin-less-top-15 {
  margin-top: -15px !important;
}

.margin-less-bottom-15 {
  margin-bottom: -15px !important;
}

.margin-less-left-15 {
  margin-left: -15px !important;
}

.margin-less-right-15 {
  margin-right: -15px !important;
}

.margin-less-15 {
  margin: -15px !important;
}

.margin-less-top-17 {
  margin-top: -17px !important;
}

.margin-less-bottom-17 {
  margin-bottom: -17px !important;
}

.margin-less-left-17 {
  margin-left: -17px !important;
}

.margin-less-right-17 {
  margin-right: -17px !important;
}

.margin-less-17 {
  margin: -17px !important;
}

.margin-less-top-20 {
  margin-top: -20px !important;
}

.margin-less-bottom-20 {
  margin-bottom: -20px !important;
}

.margin-less-left-20 {
  margin-left: -20px !important;
}

.margin-less-right-20 {
  margin-right: -20px !important;
}

.margin-less-20 {
  margin: -20px !important;
}

.margin-less-top-25 {
  margin-top: -25px !important;
}

.margin-less-bottom-25 {
  margin-bottom: -25px !important;
}

.margin-less-left-25 {
  margin-left: -25px !important;
}

.margin-less-right-25 {
  margin-right: -25px !important;
}

.margin-less-25 {
  margin: -25px !important;
}

.margin-less-top-30 {
  margin-top: -30px !important;
}

.margin-less-bottom-30 {
  margin-bottom: -30px !important;
}

.margin-less-left-30 {
  margin-left: -30px !important;
}

.margin-less-right-30 {
  margin-right: -30px !important;
}

.margin-less-30 {
  margin: -30px !important;
}

.margin-less-top-35 {
  margin-top: -35px !important;
}

.margin-less-bottom-35 {
  margin-bottom: -35px !important;
}

.margin-less-left-35 {
  margin-left: -35px !important;
}

.margin-less-right-35 {
  margin-right: -35px !important;
}

.margin-less-35 {
  margin: -35px !important;
}

.margin-less-top-40 {
  margin-top: -40px !important;
}

.margin-less-bottom-40 {
  margin-bottom: -40px !important;
}

.margin-less-left-40 {
  margin-left: -40px !important;
}

.margin-less-right-40 {
  margin-right: -40px !important;
}

.margin-less-40 {
  margin: -40px !important;
}

.margin-less-top-45 {
  margin-top: -45px !important;
}

.margin-less-bottom-45 {
  margin-bottom: -45px !important;
}

.margin-less-left-45 {
  margin-left: -45px !important;
}

.margin-less-right-45 {
  margin-right: -45px !important;
}

.margin-less-45 {
  margin: -45px !important;
}

.margin-less-top-50 {
  margin-top: -50px !important;
}

.margin-less-bottom-50 {
  margin-bottom: -50px !important;
}

.margin-less-left-50 {
  margin-left: -50px !important;
}

.margin-less-right-50 {
  margin-right: -50px !important;
}

.margin-less-50 {
  margin: -50px !important;
}

.margin-less-top-55 {
  margin-top: -55px !important;
}

.margin-less-bottom-55 {
  margin-bottom: -55px !important;
}

.margin-less-left-55 {
  margin-left: -55px !important;
}

.margin-less-right-55 {
  margin-right: -55px !important;
}

.margin-less-55 {
  margin: -55px !important;
}

.margin-less-top-60 {
  margin-top: -60px !important;
}

.margin-less-bottom-60 {
  margin-bottom: -60px !important;
}

.margin-less-left-60 {
  margin-left: -60px !important;
}

.margin-less-right-60 {
  margin-right: -60px !important;
}

.margin-less-60 {
  margin: -60px !important;
}

.margin-less-top-65 {
  margin-top: -65px !important;
}

.margin-less-bottom-65 {
  margin-bottom: -65px !important;
}

.margin-less-left-65 {
  margin-left: -65px !important;
}

.margin-less-right-65 {
  margin-right: -65px !important;
}

.margin-less-65 {
  margin: -65px !important;
}

.margin-less-top-70 {
  margin-top: -70px !important;
}

.margin-less-bottom-70 {
  margin-bottom: -70px !important;
}

.margin-less-left-70 {
  margin-left: -70px !important;
}

.margin-less-right-70 {
  margin-right: -70px !important;
}

.margin-less-70 {
  margin: -70px !important;
}

.margin-less-top-75 {
  margin-top: -75px !important;
}

.margin-less-bottom-75 {
  margin-bottom: -75px !important;
}

.margin-less-left-75 {
  margin-left: -75px !important;
}

.margin-less-right-75 {
  margin-right: -75px !important;
}

.margin-less-75 {
  margin: -75px !important;
}

.margin-less-top-80 {
  margin-top: -80px !important;
}

.margin-less-bottom-80 {
  margin-bottom: -80px !important;
}

.margin-less-left-80 {
  margin-left: -80px !important;
}

.margin-less-right-80 {
  margin-right: -80px !important;
}

.margin-less-80 {
  margin: -80px !important;
}

.margin-less-top-85 {
  margin-top: -85px !important;
}

.margin-less-bottom-85 {
  margin-bottom: -85px !important;
}

.margin-less-left-85 {
  margin-left: -85px !important;
}

.margin-less-right-85 {
  margin-right: -85px !important;
}

.margin-less-85 {
  margin: -85px !important;
}

.margin-less-top-90 {
  margin-top: -90px !important;
}

.margin-less-bottom-90 {
  margin-bottom: -90px !important;
}

.margin-less-left-90 {
  margin-left: -90px !important;
}

.margin-less-right-90 {
  margin-right: -90px !important;
}

.margin-less-90 {
  margin: -90px !important;
}

.margin-less-top-95 {
  margin-top: -95px !important;
}

.margin-less-bottom-95 {
  margin-bottom: -95px !important;
}

.margin-less-left-95 {
  margin-left: -95px !important;
}

.margin-less-right-95 {
  margin-right: -95px !important;
}

.margin-less-95 {
  margin: -95px !important;
}

.margin-less-top-100 {
  margin-top: -100px !important;
}

.margin-less-bottom-100 {
  margin-bottom: -100px !important;
}

.margin-less-left-100 {
  margin-left: -100px !important;
}

.margin-less-right-100 {
  margin-right: -100px !important;
}

.margin-less-100 {
  margin: -100px !important;
}

.margin-less-top-105 {
  margin-top: -105px !important;
}

.margin-less-bottom-105 {
  margin-bottom: -105px !important;
}

.margin-less-left-105 {
  margin-left: -105px !important;
}

.margin-less-right-105 {
  margin-right: -105px !important;
}

.margin-less-105 {
  margin: -105px !important;
}

.margin-less-top-110 {
  margin-top: -110px !important;
}

.margin-less-bottom-110 {
  margin-bottom: -110px !important;
}

.margin-less-left-110 {
  margin-left: -110px !important;
}

.margin-less-right-110 {
  margin-right: -110px !important;
}

.margin-less-110 {
  margin: -110px !important;
}

.margin-less-top-115 {
  margin-top: -115px !important;
}

.margin-less-bottom-115 {
  margin-bottom: -115px !important;
}

.margin-less-left-115 {
  margin-left: -115px !important;
}

.margin-less-right-115 {
  margin-right: -115px !important;
}

.margin-less-115 {
  margin: -115px !important;
}

.margin-less-top-120 {
  margin-top: -120px !important;
}

.margin-less-bottom-120 {
  margin-bottom: -120px !important;
}

.margin-less-left-120 {
  margin-left: -120px !important;
}

.margin-less-right-120 {
  margin-right: -120px !important;
}

.margin-less-120 {
  margin: -120px !important;
}

/*
 * -----------------------------------------------------------------------------
 *	MARGIN-AUTO
 * -----------------------------------------------------------------------------
*/
.margin-auto {
  margin: auto !important;
}

/*
 * -----------------------------------------------------------------------------
 *	PADDING
 * -----------------------------------------------------------------------------
*/
.padding-top-0 {
  padding-top: 0px !important;
}

.padding-bottom-0 {
  padding-bottom: 0px !important;
}

.padding-left-0 {
  padding-left: 0px !important;
}

.padding-right-0 {
  padding-right: 0px !important;
}

.padding-0 {
  padding: 0px !important;
}

.padding-top-1 {
  padding-top: 1px !important;
}

.padding-bottom-1 {
  padding-bottom: 1px !important;
}

.padding-left-1 {
  padding-left: 1px !important;
}

.padding-right-1 {
  padding-right: 1px !important;
}

.padding-1 {
  padding: 1px !important;
}

.padding-top-2 {
  padding-top: 2px !important;
}

.padding-bottom-2 {
  padding-bottom: 2px !important;
}

.padding-left-2 {
  padding-left: 2px !important;
}

.padding-right-2 {
  padding-right: 2px !important;
}

.padding-2 {
  padding: 2px !important;
}

.padding-top-5 {
  padding-top: 5px !important;
}

.padding-bottom-5 {
  padding-bottom: 5px !important;
}

.padding-left-5 {
  padding-left: 5px !important;
}

.padding-right-5 {
  padding-right: 5px !important;
}

.padding-5 {
  padding: 5px !important;
}

.padding-top-7 {
  padding-top: 7px !important;
}

.padding-bottom-7 {
  padding-bottom: 7px !important;
}

.padding-left-7 {
  padding-left: 7px !important;
}

.padding-right-7 {
  padding-right: 7px !important;
}

.padding-7 {
  padding: 7px !important;
}

.padding-top-8 {
  padding-top: 8px !important;
}

.padding-bottom-8 {
  padding-bottom: 8px !important;
}

.padding-left-8 {
  padding-left: 8px !important;
}

.padding-right-8 {
  padding-right: 8px !important;
}

.padding-8 {
  padding: 8px !important;
}

.padding-top-10 {
  padding-top: 10px !important;
}

.padding-bottom-10 {
  padding-bottom: 10px !important;
}

.padding-left-10 {
  padding-left: 10px !important;
}

.padding-right-10 {
  padding-right: 10px !important;
}

.padding-10 {
  padding: 10px !important;
}

.padding-top-15 {
  padding-top: 15px !important;
}

.padding-bottom-15 {
  padding-bottom: 15px !important;
}

.padding-left-15 {
  padding-left: 15px !important;
}

.padding-right-15 {
  padding-right: 15px !important;
}

.padding-15 {
  padding: 15px !important;
}

.padding-top-20 {
  padding-top: 20px !important;
}

.padding-bottom-20 {
  padding-bottom: 20px !important;
}

.padding-left-20 {
  padding-left: 20px !important;
}

.padding-right-20 {
  padding-right: 20px !important;
}

.padding-20 {
  padding: 20px !important;
}

.padding-top-25 {
  padding-top: 25px !important;
}

.padding-bottom-25 {
  padding-bottom: 25px !important;
}

.padding-left-25 {
  padding-left: 25px !important;
}

.padding-right-25 {
  padding-right: 25px !important;
}

.padding-25 {
  padding: 25px !important;
}

.padding-top-30 {
  padding-top: 30px !important;
}

.padding-bottom-30 {
  padding-bottom: 30px !important;
}

.padding-left-30 {
  padding-left: 30px !important;
}

.padding-right-30 {
  padding-right: 30px !important;
}

.padding-30 {
  padding: 30px !important;
}

.padding-top-35 {
  padding-top: 35px !important;
}

.padding-bottom-35 {
  padding-bottom: 35px !important;
}

.padding-left-35 {
  padding-left: 35px !important;
}

.padding-right-35 {
  padding-right: 35px !important;
}

.padding-35 {
  padding: 35px !important;
}

.padding-top-40 {
  padding-top: 40px !important;
}

.padding-bottom-40 {
  padding-bottom: 40px !important;
}

.padding-left-40 {
  padding-left: 40px !important;
}

.padding-right-40 {
  padding-right: 40px !important;
}

.padding-40 {
  padding: 40px !important;
}

.padding-top-45 {
  padding-top: 45px !important;
}

.padding-bottom-45 {
  padding-bottom: 45px !important;
}

.padding-left-45 {
  padding-left: 45px !important;
}

.padding-right-45 {
  padding-right: 45px !important;
}

.padding-45 {
  padding: 45px !important;
}

.padding-top-50 {
  padding-top: 50px !important;
}

.padding-bottom-50 {
  padding-bottom: 50px !important;
}

.padding-left-50 {
  padding-left: 50px !important;
}

.padding-right-50 {
  padding-right: 50px !important;
}

.padding-50 {
  padding: 50px !important;
}

.padding-top-55 {
  padding-top: 55px !important;
}

.padding-bottom-55 {
  padding-bottom: 55px !important;
}

.padding-left-55 {
  padding-left: 55px !important;
}

.padding-right-55 {
  padding-right: 55px !important;
}

.padding-55 {
  padding: 55px !important;
}

.padding-top-60 {
  padding-top: 60px !important;
}

.padding-bottom-60 {
  padding-bottom: 60px !important;
}

.padding-left-60 {
  padding-left: 60px !important;
}

.padding-right-60 {
  padding-right: 60px !important;
}

.padding-60 {
  padding: 60px !important;
}

.padding-top-65 {
  padding-top: 65px !important;
}

.padding-bottom-65 {
  padding-bottom: 65px !important;
}

.padding-left-65 {
  padding-left: 65px !important;
}

.padding-right-65 {
  padding-right: 65px !important;
}

.padding-65 {
  padding: 65px !important;
}

.padding-top-70 {
  padding-top: 70px !important;
}

.padding-bottom-70 {
  padding-bottom: 70px !important;
}

.padding-left-70 {
  padding-left: 70px !important;
}

.padding-right-70 {
  padding-right: 70px !important;
}

.padding-70 {
  padding: 70px !important;
}

.padding-top-75 {
  padding-top: 75px !important;
}

.padding-bottom-75 {
  padding-bottom: 75px !important;
}

.padding-left-75 {
  padding-left: 75px !important;
}

.padding-right-75 {
  padding-right: 75px !important;
}

.padding-75 {
  padding: 75px !important;
}

.padding-top-80 {
  padding-top: 80px !important;
}

.padding-bottom-80 {
  padding-bottom: 80px !important;
}

.padding-left-80 {
  padding-left: 80px !important;
}

.padding-right-80 {
  padding-right: 80px !important;
}

.padding-80 {
  padding: 80px !important;
}

.padding-top-85 {
  padding-top: 85px !important;
}

.padding-bottom-85 {
  padding-bottom: 85px !important;
}

.padding-left-85 {
  padding-left: 85px !important;
}

.padding-right-85 {
  padding-right: 85px !important;
}

.padding-85 {
  padding: 85px !important;
}

.padding-top-90 {
  padding-top: 90px !important;
}

.padding-bottom-90 {
  padding-bottom: 90px !important;
}

.padding-left-90 {
  padding-left: 90px !important;
}

.padding-right-90 {
  padding-right: 90px !important;
}

.padding-90 {
  padding: 90px !important;
}

.padding-top-95 {
  padding-top: 95px !important;
}

.padding-bottom-95 {
  padding-bottom: 95px !important;
}

.padding-left-95 {
  padding-left: 95px !important;
}

.padding-right-95 {
  padding-right: 95px !important;
}

.padding-95 {
  padding: 95px !important;
}

.padding-top-100 {
  padding-top: 100px !important;
}

.padding-bottom-100 {
  padding-bottom: 100px !important;
}

.padding-left-100 {
  padding-left: 100px !important;
}

.padding-right-100 {
  padding-right: 100px !important;
}

.padding-100 {
  padding: 100px !important;
}

.padding-top-105 {
  padding-top: 105px !important;
}

.padding-bottom-105 {
  padding-bottom: 105px !important;
}

.padding-left-105 {
  padding-left: 105px !important;
}

.padding-right-105 {
  padding-right: 105px !important;
}

.padding-105 {
  padding: 105px !important;
}

.padding-top-110 {
  padding-top: 110px !important;
}

.padding-bottom-110 {
  padding-bottom: 110px !important;
}

.padding-left-110 {
  padding-left: 110px !important;
}

.padding-right-110 {
  padding-right: 110px !important;
}

.padding-110 {
  padding: 110px !important;
}

.padding-top-115 {
  padding-top: 115px !important;
}

.padding-bottom-115 {
  padding-bottom: 115px !important;
}

.padding-left-115 {
  padding-left: 115px !important;
}

.padding-right-115 {
  padding-right: 115px !important;
}

.padding-115 {
  padding: 115px !important;
}

.padding-top-120 {
  padding-top: 120px !important;
}

.padding-bottom-120 {
  padding-bottom: 120px !important;
}

.padding-left-120 {
  padding-left: 120px !important;
}

.padding-right-120 {
  padding-right: 120px !important;
}

.padding-120 {
  padding: 120px !important;
}

/*
 * -----------------------------------------------------------------------------
 *	POSITION
 * -----------------------------------------------------------------------------
*/
.top-0 {
  top: 0px !important;
}

.bottom-0 {
  bottom: 0px !important;
}

.left-0 {
  left: 0px !important;
}

.right-0 {
  right: 0px !important;
}

.top-2 {
  top: 2px !important;
}

.bottom-2 {
  bottom: 2px !important;
}

.left-2 {
  left: 2px !important;
}

.right-2 {
  right: 2px !important;
}

.top-5 {
  top: 5px !important;
}

.bottom-5 {
  bottom: 5px !important;
}

.left-5 {
  left: 5px !important;
}

.right-5 {
  right: 5px !important;
}

.top-8 {
  top: 8px !important;
}

.bottom-8 {
  bottom: 8px !important;
}

.left-8 {
  left: 8px !important;
}

.right-8 {
  right: 8px !important;
}

.top-10 {
  top: 10px !important;
}

.bottom-10 {
  bottom: 10px !important;
}

.left-10 {
  left: 10px !important;
}

.right-10 {
  right: 10px !important;
}

.top-15 {
  top: 15px !important;
}

.bottom-15 {
  bottom: 15px !important;
}

.left-15 {
  left: 15px !important;
}

.right-15 {
  right: 15px !important;
}

.top-20 {
  top: 20px !important;
}

.bottom-20 {
  bottom: 20px !important;
}

.left-20 {
  left: 20px !important;
}

.right-20 {
  right: 20px !important;
}

.top-25 {
  top: 25px !important;
}

.bottom-25 {
  bottom: 25px !important;
}

.left-25 {
  left: 25px !important;
}

.right-25 {
  right: 25px !important;
}

.top-30 {
  top: 30px !important;
}

.bottom-30 {
  bottom: 30px !important;
}

.left-30 {
  left: 30px !important;
}

.right-30 {
  right: 30px !important;
}

.top-40 {
  top: 40px !important;
}

.bottom-40 {
  bottom: 40px !important;
}

.left-40 {
  left: 40px !important;
}

.right-40 {
  right: 40px !important;
}

.top-45 {
  top: 45px !important;
}

.bottom-45 {
  bottom: 45px !important;
}

.left-45 {
  left: 45px !important;
}

.right-45 {
  right: 45px !important;
}

.top-50 {
  top: 50px !important;
}

.bottom-50 {
  bottom: 50px !important;
}

.left-50 {
  left: 50px !important;
}

.right-50 {
  right: 50px !important;
}

.top-55 {
  top: 55px !important;
}

.bottom-55 {
  bottom: 55px !important;
}

.left-55 {
  left: 55px !important;
}

.right-55 {
  right: 55px !important;
}

.top-60 {
  top: 60px !important;
}

.bottom-60 {
  bottom: 60px !important;
}

.left-60 {
  left: 60px !important;
}

.right-60 {
  right: 60px !important;
}

.top-65 {
  top: 65px !important;
}

.bottom-65 {
  bottom: 65px !important;
}

.left-65 {
  left: 65px !important;
}

.right-65 {
  right: 65px !important;
}

.top-70 {
  top: 70px !important;
}

.bottom-70 {
  bottom: 70px !important;
}

.left-70 {
  left: 70px !important;
}

.right-70 {
  right: 70px !important;
}

.top-75 {
  top: 75px !important;
}

.bottom-75 {
  bottom: 75px !important;
}

.left-75 {
  left: 75px !important;
}

.right-75 {
  right: 75px !important;
}

.top-80 {
  top: 80px !important;
}

.bottom-80 {
  bottom: 80px !important;
}

.left-80 {
  left: 80px !important;
}

.right-80 {
  right: 80px !important;
}

.top-85 {
  top: 85px !important;
}

.bottom-85 {
  bottom: 85px !important;
}

.left-85 {
  left: 85px !important;
}

.right-85 {
  right: 85px !important;
}

.top-90 {
  top: 90px !important;
}

.bottom-90 {
  bottom: 90px !important;
}

.left-90 {
  left: 90px !important;
}

.right-90 {
  right: 90px !important;
}

.top-95 {
  top: 95px !important;
}

.bottom-95 {
  bottom: 95px !important;
}

.left-95 {
  left: 95px !important;
}

.right-95 {
  right: 95px !important;
}

.top-100 {
  top: 100px !important;
}

.bottom-100 {
  bottom: 100px !important;
}

.left-100 {
  left: 100px !important;
}

.right-100 {
  right: 100px !important;
}

.top-105 {
  top: 105px !important;
}

.bottom-105 {
  bottom: 105px !important;
}

.left-105 {
  left: 105px !important;
}

.right-105 {
  right: 105px !important;
}

.top-110 {
  top: 110px !important;
}

.bottom-110 {
  bottom: 110px !important;
}

.left-110 {
  left: 110px !important;
}

.right-110 {
  right: 110px !important;
}

.top-115 {
  top: 115px !important;
}

.bottom-115 {
  bottom: 115px !important;
}

.left-115 {
  left: 115px !important;
}

.right-115 {
  right: 115px !important;
}

.top-120 {
  top: 120px !important;
}

.bottom-120 {
  bottom: 120px !important;
}

.left-120 {
  left: 120px !important;
}

.right-120 {
  right: 120px !important;
}

/*
 * -----------------------------------------------------------------------------
 *	SIZE
 * -----------------------------------------------------------------------------
*/
.font-size-5 {
  font-size: 5px !important;
  min-height: 5px !important;
  height: 5px !important;
  min-width: 5px !important;
  width: 5px !important;
}

.font-size-6 {
  font-size: 6px !important;
  min-height: 6px !important;
  height: 6px !important;
  min-width: 6px !important;
  width: 6px !important;
}

.font-size-8 {
  font-size: 8px !important;
  min-height: 8px !important;
  height: 8px !important;
  min-width: 8px !important;
  width: 8px !important;
}

.font-size-10 {
  font-size: 10px !important;
  min-height: 10px !important;
  height: 10px !important;
  min-width: 10px !important;
  width: 10px !important;
}

.font-size-11 {
  font-size: 11px !important;
  min-height: 11px !important;
  height: 11px !important;
  min-width: 11px !important;
  width: 11px !important;
}

.font-size-12 {
  font-size: 12px !important;
  min-height: 12px !important;
  height: 12px !important;
  min-width: 12px !important;
  width: 12px !important;
}

.font-size-14 {
  font-size: 14px !important;
  min-height: 14px !important;
  height: 14px !important;
  min-width: 14px !important;
  width: 14px !important;
}

.font-size-16 {
  font-size: 16px !important;
  min-height: 16px !important;
  height: 16px !important;
  min-width: 16px !important;
  width: 16px !important;
}

.font-size-18 {
  font-size: 18px !important;
  min-height: 18px !important;
  height: 18px !important;
  min-width: 18px !important;
  width: 18px !important;
}

.font-size-20 {
  font-size: 20px !important;
  min-height: 20px !important;
  height: 20px !important;
  min-width: 20px !important;
  width: 20px !important;
}

.font-size-22 {
  font-size: 22px !important;
  min-height: 22px !important;
  height: 22px !important;
  min-width: 22px !important;
  width: 22px !important;
}

.font-size-24 {
  font-size: 24px !important;
  min-height: 24px !important;
  height: 24px !important;
  min-width: 24px !important;
  width: 24px !important;
}

.font-size-36 {
  font-size: 36px !important;
  min-height: 36px !important;
  height: 36px !important;
  min-width: 36px !important;
  width: 36px !important;
}

.font-size-38 {
  font-size: 38px !important;
  min-height: 38px !important;
  height: 38px !important;
  min-width: 38px !important;
  width: 38px !important;
}

.font-size-40 {
  font-size: 40px !important;
  min-height: 40px !important;
  height: 40px !important;
  min-width: 40px !important;
  width: 40px !important;
}

.font-size-42 {
  font-size: 42px !important;
  min-height: 42px !important;
  height: 42px !important;
  min-width: 42px !important;
  width: 42px !important;
}

.font-size-46 {
  font-size: 46px !important;
  min-height: 46px !important;
  height: 46px !important;
  min-width: 46px !important;
  width: 46px !important;
}

.font-size-48 {
  font-size: 48px !important;
  min-height: 48px !important;
  height: 48px !important;
  min-width: 48px !important;
  width: 48px !important;
}

.font-size-54 {
  font-size: 54px !important;
  min-height: 54px !important;
  height: 54px !important;
  min-width: 54px !important;
  width: 54px !important;
}

.font-size-64 {
  font-size: 64px !important;
  min-height: 64px !important;
  height: 64px !important;
  min-width: 64px !important;
  width: 64px !important;
}

.font-size-72 {
  font-size: 72px !important;
  min-height: 72px !important;
  height: 72px !important;
  min-width: 72px !important;
  width: 72px !important;
}

.font-size-84 {
  font-size: 84px !important;
  min-height: 84px !important;
  height: 84px !important;
  min-width: 84px !important;
  width: 84px !important;
}

.font-size-96 {
  font-size: 96px !important;
  min-height: 96px !important;
  height: 96px !important;
  min-width: 96px !important;
  width: 96px !important;
}

.font-size-112 {
  font-size: 112px !important;
  min-height: 112px !important;
  height: 112px !important;
  min-width: 112px !important;
  width: 112px !important;
}

/*
 * -----------------------------------------------------------------------------
 *	ICONOS SIZE
 * -----------------------------------------------------------------------------
*/
.icon-5 {
  height: 5% !important;
  width: 5% !important;
}

@media screen and (min-width: 600px) {
  .icon-5 {
    margin-left: -10px;
  }
}

.icon-10 {
  height: 10% !important;
  width: 10% !important;
}

@media screen and (min-width: 600px) {
  .icon-10 {
    margin-left: -10px;
  }
}

.icon-15 {
  height: 15% !important;
  width: 15% !important;
}

@media screen and (min-width: 600px) {
  .icon-15 {
    margin-left: -10px;
  }
}

.icon-20 {
  height: 20% !important;
  width: 20% !important;
}

@media screen and (min-width: 600px) {
  .icon-20 {
    margin-left: -10px;
  }
}

.icon-25 {
  height: 25% !important;
  width: 25% !important;
}

@media screen and (min-width: 600px) {
  .icon-25 {
    margin-left: -10px;
  }
}

.icon-30 {
  height: 30% !important;
  width: 30% !important;
}

@media screen and (min-width: 600px) {
  .icon-30 {
    margin-left: -10px;
  }
}

.icon-35 {
  height: 35% !important;
  width: 35% !important;
}

@media screen and (min-width: 600px) {
  .icon-35 {
    margin-left: -10px;
  }
}

.icon-40 {
  height: 40% !important;
  width: 40% !important;
}

@media screen and (min-width: 600px) {
  .icon-40 {
    margin-left: -10px;
  }
}

.icon-42 {
  height: 42% !important;
  width: 42% !important;
}

@media screen and (min-width: 600px) {
  .icon-42 {
    margin-left: -10px;
  }
}

.icon-45 {
  height: 45% !important;
  width: 45% !important;
}

@media screen and (min-width: 600px) {
  .icon-45 {
    margin-left: -10px;
  }
}

.icon-50 {
  height: 50% !important;
  width: 50% !important;
}

@media screen and (min-width: 600px) {
  .icon-50 {
    margin-left: -10px;
  }
}

.icon-55 {
  height: 55% !important;
  width: 55% !important;
}

@media screen and (min-width: 600px) {
  .icon-55 {
    margin-left: -10px;
  }
}

.icon-60 {
  height: 60% !important;
  width: 60% !important;
}

@media screen and (min-width: 600px) {
  .icon-60 {
    margin-left: -10px;
  }
}

.icon-65 {
  height: 65% !important;
  width: 65% !important;
}

@media screen and (min-width: 600px) {
  .icon-65 {
    margin-left: -10px;
  }
}

.icon-70 {
  height: 70% !important;
  width: 70% !important;
}

@media screen and (min-width: 600px) {
  .icon-70 {
    margin-left: -10px;
  }
}

.icon-75 {
  height: 75% !important;
  width: 75% !important;
}

@media screen and (min-width: 600px) {
  .icon-75 {
    margin-left: -10px;
  }
}

.icon-80 {
  height: 80% !important;
  width: 80% !important;
}

@media screen and (min-width: 600px) {
  .icon-80 {
    margin-left: -10px;
  }
}

.icon-85 {
  height: 85% !important;
  width: 85% !important;
}

@media screen and (min-width: 600px) {
  .icon-85 {
    margin-left: -10px;
  }
}

.icon-90 {
  height: 90% !important;
  width: 90% !important;
}

@media screen and (min-width: 600px) {
  .icon-90 {
    margin-left: -10px;
  }
}

.icon-95 {
  height: 95% !important;
  width: 95% !important;
}

@media screen and (min-width: 600px) {
  .icon-95 {
    margin-left: -10px;
  }
}

.icon-100 {
  height: 100% !important;
  width: 100% !important;
}

@media screen and (min-width: 600px) {
  .icon-100 {
    margin-left: -10px;
  }
}

.icon-110 {
  height: 110% !important;
  width: 110% !important;
}

@media screen and (min-width: 600px) {
  .icon-110 {
    margin-left: -10px;
  }
}

.icon-120 {
  height: 120% !important;
  width: 120% !important;
}

@media screen and (min-width: 600px) {
  .icon-120 {
    margin-left: -10px;
  }
}

.icon-130 {
  height: 130% !important;
  width: 130% !important;
}

@media screen and (min-width: 600px) {
  .icon-130 {
    margin-left: -10px;
  }
}

.icon-140 {
  height: 140% !important;
  width: 140% !important;
}

@media screen and (min-width: 600px) {
  .icon-140 {
    margin-left: -10px;
  }
}

.icon-150 {
  height: 150% !important;
  width: 150% !important;
}

@media screen and (min-width: 600px) {
  .icon-150 {
    margin-left: -10px;
  }
}

.icon-180 {
  height: 180% !important;
  width: 180% !important;
}

@media screen and (min-width: 600px) {
  .icon-180 {
    margin-left: -10px;
  }
}

.icon-200 {
  height: 200% !important;
  width: 200% !important;
}

@media screen and (min-width: 600px) {
  .icon-200 {
    margin-left: -10px;
  }
}

.icon-210 {
  height: 210% !important;
  width: 210% !important;
}

@media screen and (min-width: 600px) {
  .icon-210 {
    margin-left: -10px;
  }
}

.icon-220 {
  height: 220% !important;
  width: 220% !important;
}

@media screen and (min-width: 600px) {
  .icon-220 {
    margin-left: -10px;
  }
}

.icon-230 {
  height: 230% !important;
  width: 230% !important;
}

@media screen and (min-width: 600px) {
  .icon-230 {
    margin-left: -10px;
  }
}

.icon-240 {
  height: 240% !important;
  width: 240% !important;
}

@media screen and (min-width: 600px) {
  .icon-240 {
    margin-left: -10px;
  }
}

.icon-250 {
  height: 250% !important;
  width: 250% !important;
}

@media screen and (min-width: 600px) {
  .icon-250 {
    margin-left: -10px;
  }
}

.icon-280 {
  height: 280% !important;
  width: 280% !important;
}

@media screen and (min-width: 600px) {
  .icon-280 {
    margin-left: -10px;
  }
}

.icon-300 {
  height: 300% !important;
  width: 300% !important;
}

@media screen and (min-width: 600px) {
  .icon-300 {
    margin-left: -10px;
  }
}

.icon-350 {
  height: 350% !important;
  width: 350% !important;
}

@media screen and (min-width: 600px) {
  .icon-350 {
    margin-left: -10px;
  }
}

.icon-400 {
  height: 400% !important;
  width: 400% !important;
}

@media screen and (min-width: 600px) {
  .icon-400 {
    margin-left: -10px;
  }
}

/*
 * -----------------------------------------------------------------------------
 *	WIDTH
 * -----------------------------------------------------------------------------
*/
.width-5 {
  width: 5% !important;
}

.width-10 {
  width: 10% !important;
}

.width-15 {
  width: 15% !important;
}

.width-20 {
  width: 20% !important;
}

.width-25 {
  width: 25% !important;
}

.width-30 {
  width: 30% !important;
}

.width-35 {
  width: 35% !important;
}

.width-40 {
  width: 40% !important;
}

.width-42 {
  width: 42% !important;
}

.width-45 {
  width: 45% !important;
}

.width-50 {
  width: 50% !important;
}

.width-55 {
  width: 55% !important;
}

.width-60 {
  width: 60% !important;
}

.width-65 {
  width: 65% !important;
}

.width-70 {
  width: 70% !important;
}

.width-75 {
  width: 75% !important;
}

.width-80 {
  width: 80% !important;
}

.width-85 {
  width: 85% !important;
}

.width-90 {
  width: 90% !important;
}

.width-95 {
  width: 95% !important;
}

.width-100 {
  width: 100% !important;
}

.width-110 {
  width: 110% !important;
}

.width-120 {
  width: 120% !important;
}

.width-130 {
  width: 130% !important;
}

.width-140 {
  width: 140% !important;
}

.width-150 {
  width: 150% !important;
}

.width-180 {
  width: 180% !important;
}

.width-200 {
  width: 200% !important;
}

.width-210 {
  width: 210% !important;
}

.width-220 {
  width: 220% !important;
}

.width-230 {
  width: 230% !important;
}

.width-240 {
  width: 240% !important;
}

.width-250 {
  width: 250% !important;
}

.width-280 {
  width: 280% !important;
}

.width-300 {
  width: 300% !important;
}

.width-350 {
  width: 350% !important;
}

.width-400 {
  width: 400% !important;
}

.width-normal-5 {
  width: 5px !important;
}

.width-normal-10 {
  width: 10px !important;
}

.width-normal-15 {
  width: 15px !important;
}

.width-normal-20 {
  width: 20px !important;
}

.width-normal-25 {
  width: 25px !important;
}

.width-normal-30 {
  width: 30px !important;
}

.width-normal-35 {
  width: 35px !important;
}

.width-normal-40 {
  width: 40px !important;
}

.width-normal-42 {
  width: 42px !important;
}

.width-normal-45 {
  width: 45px !important;
}

.width-normal-50 {
  width: 50px !important;
}

.width-normal-55 {
  width: 55px !important;
}

.width-normal-60 {
  width: 60px !important;
}

.width-normal-65 {
  width: 65px !important;
}

.width-normal-70 {
  width: 70px !important;
}

.width-normal-75 {
  width: 75px !important;
}

.width-normal-80 {
  width: 80px !important;
}

.width-normal-85 {
  width: 85px !important;
}

.width-normal-90 {
  width: 90px !important;
}

.width-normal-95 {
  width: 95px !important;
}

.width-normal-100 {
  width: 100px !important;
}

.width-normal-110 {
  width: 110px !important;
}

.width-normal-120 {
  width: 120px !important;
}

.width-normal-130 {
  width: 130px !important;
}

.width-normal-140 {
  width: 140px !important;
}

.width-normal-150 {
  width: 150px !important;
}

.width-normal-180 {
  width: 180px !important;
}

.width-normal-200 {
  width: 200px !important;
}

.width-normal-210 {
  width: 210px !important;
}

.width-normal-220 {
  width: 220px !important;
}

.width-normal-230 {
  width: 230px !important;
}

.width-normal-240 {
  width: 240px !important;
}

.width-normal-250 {
  width: 250px !important;
}

.width-normal-280 {
  width: 280px !important;
}

.width-normal-300 {
  width: 300px !important;
}

.width-normal-350 {
  width: 350px !important;
}

.width-normal-400 {
  width: 400px !important;
}

/*
 * -----------------------------------------------------------------------------
 *	HEIGHT
 * -----------------------------------------------------------------------------
*/
.height-5 {
  height: 5% !important;
}

.height-10 {
  height: 10% !important;
}

.height-15 {
  height: 15% !important;
}

.height-20 {
  height: 20% !important;
}

.height-25 {
  height: 25% !important;
}

.height-30 {
  height: 30% !important;
}

.height-35 {
  height: 35% !important;
}

.height-40 {
  height: 40% !important;
}

.height-42 {
  height: 42% !important;
}

.height-45 {
  height: 45% !important;
}

.height-50 {
  height: 50% !important;
}

.height-55 {
  height: 55% !important;
}

.height-60 {
  height: 60% !important;
}

.height-65 {
  height: 65% !important;
}

.height-70 {
  height: 70% !important;
}

.height-75 {
  height: 75% !important;
}

.height-80 {
  height: 80% !important;
}

.height-85 {
  height: 85% !important;
}

.height-90 {
  height: 90% !important;
}

.height-95 {
  height: 95% !important;
}

.height-100 {
  height: 100% !important;
}

.height-110 {
  height: 110% !important;
}

.height-120 {
  height: 120% !important;
}

.height-130 {
  height: 130% !important;
}

.height-140 {
  height: 140% !important;
}

.height-150 {
  height: 150% !important;
}

.height-180 {
  height: 180% !important;
}

.height-200 {
  height: 200% !important;
}

.height-210 {
  height: 210% !important;
}

.height-220 {
  height: 220% !important;
}

.height-230 {
  height: 230% !important;
}

.height-240 {
  height: 240% !important;
}

.height-250 {
  height: 250% !important;
}

.height-280 {
  height: 280% !important;
}

.height-300 {
  height: 300% !important;
}

.height-350 {
  height: 350% !important;
}

.height-400 {
  height: 400% !important;
}

.height-450 {
  height: 450% !important;
}

.height-500 {
  height: 500% !important;
}

.height-550 {
  height: 550% !important;
}

.height-600 {
  height: 600% !important;
}

.height-normal-5 {
  height: 5px !important;
}

.height-normal-10 {
  height: 10px !important;
}

.height-normal-15 {
  height: 15px !important;
}

.height-normal-20 {
  height: 20px !important;
}

.height-normal-25 {
  height: 25px !important;
}

.height-normal-30 {
  height: 30px !important;
}

.height-normal-35 {
  height: 35px !important;
}

.height-normal-40 {
  height: 40px !important;
}

.height-normal-42 {
  height: 42px !important;
}

.height-normal-45 {
  height: 45px !important;
}

.height-normal-50 {
  height: 50px !important;
}

.height-normal-55 {
  height: 55px !important;
}

.height-normal-60 {
  height: 60px !important;
}

.height-normal-65 {
  height: 65px !important;
}

.height-normal-70 {
  height: 70px !important;
}

.height-normal-75 {
  height: 75px !important;
}

.height-normal-80 {
  height: 80px !important;
}

.height-normal-85 {
  height: 85px !important;
}

.height-normal-90 {
  height: 90px !important;
}

.height-normal-95 {
  height: 95px !important;
}

.height-normal-100 {
  height: 100px !important;
}

.height-normal-110 {
  height: 110px !important;
}

.height-normal-120 {
  height: 120px !important;
}

.height-normal-130 {
  height: 130px !important;
}

.height-normal-140 {
  height: 140px !important;
}

.height-normal-150 {
  height: 150px !important;
}

.height-normal-180 {
  height: 180px !important;
}

.height-normal-200 {
  height: 200px !important;
}

.height-normal-210 {
  height: 210px !important;
}

.height-normal-220 {
  height: 220px !important;
}

.height-normal-230 {
  height: 230px !important;
}

.height-normal-240 {
  height: 240px !important;
}

.height-normal-250 {
  height: 250px !important;
}

.height-normal-280 {
  height: 280px !important;
}

.height-normal-300 {
  height: 300px !important;
}

.height-normal-350 {
  height: 350px !important;
}

.height-normal-400 {
  height: 400px !important;
}

.height-normal-450 {
  height: 450px !important;
}

.height-normal-500 {
  height: 500px !important;
}

.height-normal-550 {
  height: 550px !important;
}

.height-normal-600 {
  height: 600px !important;
}

/*
 * -----------------------------------------------------------------------------
 *	FONT-WEIGHT
 * -----------------------------------------------------------------------------
*/
.font-weight-0 {
  font-weight: 0 !important;
}

.font-weight-100 {
  font-weight: 100 !important;
}

.font-weight-200 {
  font-weight: 200 !important;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-weight-800 {
  font-weight: 800 !important;
}

.font-weight-900 {
  font-weight: 900 !important;
}

/*
 * -----------------------------------------------------------------------------
 *	ALIGN
 * -----------------------------------------------------------------------------
*/
.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-underline {
  text-decoration: underline !important;
}

.text-decoration-none {
  text-decoration: none;
}

/*
 * -----------------------------------------------------------------------------
 *	MOBILE TEXT ALIGN
 * -----------------------------------------------------------------------------
*/
.mobile-text-justify {
  text-align: center;
}

@media screen and (max-width: 600px) {
  .mobile-text-justify {
    text-align: justify !important;
  }
}

.mobile-text-center {
  text-align: justify;
}

@media screen and (max-width: 600px) {
  .mobile-text-center {
    text-align: center !important;
  }
}

.mobile-text-center-center {
  text-align: center;
}

@media screen and (max-width: 600px) {
  .mobile-text-center-center {
    text-align: center !important;
  }
}

.mobile-text-justify-left {
  text-align: justify;
}

@media screen and (max-width: 600px) {
  .mobile-text-justify-left {
    text-align: left !important;
  }
}

.mobile-text-center-left {
  text-align: center;
}

@media screen and (max-width: 600px) {
  .mobile-text-center-left {
    text-align: left !important;
  }
}

/*
 * -----------------------------------------------------------------------------
 *	BACKGROUND
 * -----------------------------------------------------------------------------
*/
/* BACKGROUND COLOR */
.kw-background-yellow {
  background: #ffc107 !important;
}

.kw-background-orange {
  background: #ff9800 !important;
}

.kw-background-green {
  background: #4caf50 !important;
}

.kw-background-collaborator {
  background: #bbdefb !important;
}

.kw-background-blue {
  background: #3330e4 !important;
}

.kw-background-grey {
  background: #77777a !important;
}

.kw-background-grey-light {
  background: #77777a36 !important;
}

.kw-background-black {
  background: black !important;
}

.kw-background-red {
  background: #c31c4a !important;
}

.kw-background-light-pink {
  background: #ffd3d3 !important;
}

.kw-background-purple {
  background: #c31c4a !important;
}

/*
 * -----------------------------------------------------------------------------
 *	LABEL
 * -----------------------------------------------------------------------------
*/
.kw-label, .kw-label-yellow, .kw-label-orange, .kw-label-green, .kw-label-collaborator, .kw-label-blue, .kw-label-grey, .kw-label-black, .kw-label-red, .kw-label-light-pink, .kw-label-pink, .kw-label-purple, .kw-label-gold {
  color: white;
  font-size: 100%;
  padding: 2px 8px;
  border-radius: 4px;
}

/* LABEL COLOR */
.kw-label-yellow {
  background: #ffc107 !important;
}

.kw-label-orange {
  background: #ff9800 !important;
}

.kw-label-green {
  background: #4caf50 !important;
}

.kw-label-collaborator {
  background: #bbdefb !important;
}

.kw-label-blue {
  background: #3330e4 !important;
}

.kw-label-grey {
  background: #77777a !important;
}

.kw-label-black {
  background: black !important;
}

.kw-label-red {
  background: #c31c4a !important;
}

.kw-label-light-pink {
  background: #ffd3d3 !important;
}

.kw-label-pink {
  background: #c31c4a !important;
}

.kw-label-purple {
  background: #c31c4a !important;
}

.kw-label-gold {
  background: gold !important;
}

/* LABEL CAPTION */
.kw-label-caption, .kw-label-caption-yellow, .kw-label-caption-orange, .kw-label-caption-green, .kw-label-caption-blue, .kw-label-caption-grey, .kw-label-caption-black, .kw-label-caption-red {
  color: white;
  font-size: 60%;
  padding: 2px 4px;
  border-radius: 4px;
}

.kw-label-caption-yellow {
  color: black;
  background: #ffc107 !important;
}

.kw-label-caption-orange {
  background: #ff9800 !important;
}

.kw-label-caption-green {
  background: #4caf50 !important;
}

.kw-label-caption-blue {
  background: #3330e4 !important;
}

.kw-label-caption-grey {
  background: #77777a !important;
}

.kw-label-caption-black {
  background: black !important;
}

.kw-label-caption-red {
  background: #c31c4a !important;
}

/* LABEL PORTAL */
.kw-label-portal, .kw-label-adaix-blue-team, .kw-label-adaix-blue, .kw-label-flooges-red, .kw-label-casatoc-red, .kw-label-myplaze-green {
  color: white !important;
  font-weight: 600 !important;
  padding: 2px 4px !important;
  border-radius: 4px !important;
}

.kw-label-adaix-blue-team {
  background: #2c78b0 !important;
}

.kw-label-adaix-blue {
  background: #0f044c !important;
}

.kw-label-flooges-red {
  background: #c31c4a !important;
}

.kw-label-casatoc-red {
  background: #f73636 !important;
}

.kw-label-myplaze-green {
  background: #0b967f !important;
}

/*
 * -----------------------------------------------------------------------------
 *	BADGE
 * -----------------------------------------------------------------------------
*/
.kw-badge,
.kw-badge {
  position: relative;
  white-space: nowrap;
  margin-right: 24px;
}

.kw-badge:not([data-badge]),
.kw-badge:not([data-badge]) {
  margin-right: auto;
}

.kw-badge[data-badge]:after,
.kw-badge[data-badge]:after {
  content: attr(data-badge);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: absolute;
  top: -11px;
  right: -24px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-size: 12px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #3330e4;
  color: #fff;
}

.kw-button .kw-badge[data-badge]:after, .kw-button
.kw-badge[data-badge]:after {
  top: -10px;
  right: -5px;
}

.kw-badge.kw-badge--no-background[data-badge]:after,
.kw-badge.kw-badge--no-background[data-badge]:after {
  color: #3330e4;
  background: #fff;
  box-shadow: 0 0 1px gray;
}

.kw-badge.kw-badge--overlap,
.kw-badge.kw-badge--overlap {
  margin-right: 10px;
}

.kw-badge.kw-badge--overlap:after,
.kw-badge.kw-badge--overlap:after {
  right: -10px;
}

.kw-badge-yellow[data-badge]:after {
  background: #e3b037;
}

.kw-badge-yellow.kw-badge--no-background[data-badge]:after {
  color: #e3b037 !important;
}

.kw-badge-green[data-badge]:after {
  background: #54e346;
}

.kw-badge-green.kw-badge--no-background[data-badge]:after {
  color: #54e346 !important;
}

/*
 * -----------------------------------------------------------------------------
 *	AVATAR IMG
 * -----------------------------------------------------------------------------
*/
.kw-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-sizing: content-box;
}

/*
 * -----------------------------------------------------------------------------
 *	CURSOR MOUSE
 * -----------------------------------------------------------------------------
*/
.kw-clickable {
  cursor: pointer;
}

/*
 * -----------------------------------------------------------------------------
 *	ICON COLORS
 * -----------------------------------------------------------------------------
*/
.icon-color-white .st0 {
  fill: white !important;
}

.icon-color-black .st0 {
  fill: black !important;
}

.icon-color-blue .st0 {
  fill: #3330e4 !important;
}

.icon-color-blue-dark .st0 {
  fill: #4a72b2 !important;
}

.icon-color-green .st0 {
  fill: green !important;
}

.icon-color-grey .st0 {
  fill: #77777a !important;
}

.icon-color-yellow .st0 {
  fill: #ffc107 !important;
}

.icon-color-red .st0 {
  fill: #c31c4a !important;
}

.icon-color-pink .st0 {
  fill: #ff4081 !important;
}

/*
 * -----------------------------------------------------------------------------
 *	TEXT
 * -----------------------------------------------------------------------------
*/
.kw-strikethrough {
  text-decoration: line-through;
}

.kw-uppercase {
  text-transform: uppercase;
}

.color-white {
  color: white !important;
}

.color-black {
  color: black !important;
}

.color-blue {
  color: #3330e4 !important;
}

.color-green {
  color: green !important;
}

.color-grey {
  color: #77777a !important;
}

.color-yellow {
  color: #ffc107 !important;
}

.color-red {
  color: #c31c4a !important;
}

.color-pink {
  color: #ff4081 !important;
}

/*
 * -----------------------------------------------------------------------------
 *	Z-INDEX:
 * -----------------------------------------------------------------------------
*/
.z-index-0 {
  z-index: 0 !important;
}

.z-index-1 {
  z-index: 1 !important;
}

.z-index-5 {
  z-index: 5 !important;
}

.z-index-10 {
  z-index: 10 !important;
}

.z-index-50 {
  z-index: 50 !important;
}

.z-index-100 {
  z-index: 100 !important;
}

.z-index-200 {
  z-index: 200 !important;
}

.z-index-300 {
  z-index: 300 !important;
}

.z-index-400 {
  z-index: 400 !important;
}

.z-index-500 {
  z-index: 500 !important;
}

.z-index-600 {
  z-index: 600 !important;
}

.z-index-700 {
  z-index: 700 !important;
}

.z-index-800 {
  z-index: 800 !important;
}

.z-index-900 {
  z-index: 900 !important;
}

.z-index-1000 {
  z-index: 1000 !important;
}


body {
  font-family: "Lato", sans-serif;
  overflow-y: hidden;
}

md-toolbar {
  background: #3330e4 !important;
}

.md-fab {
  background: #54e346 !important;
}

.md-toolbar-grey-light {
  background: #fafafa !important;
}

md-toast[md-theme=warn] .md-toast-content {
  background-color: #F44336;
}

[ui-view].ng-enter,
[ui-view].ng-leave {
  transition: opacity ease-in-out 200ms;
}

[ui-view].ng-enter,
[ui-view].ng-leave.ng-leave-active {
  opacity: 0;
}

[ui-view].ng-enter.ng-enter-active {
  opacity: 1;
}

.Page.ng-leave.ng-leave-active {
  display: none;
}

.u-center {
  text-align: center;
}

/* COLOR TEXTO*/
.text-white {
  color: white !important;
}

.text-blue {
  color: #3330e4 !important;
}

.text-black {
  color: black !important;
}

.text-red {
  color: #c31c4a !important;
}

.text-green {
  color: #009895 !important;
}

.text-grey-100 {
  color: #f5f5f5 !important;
}

.text-grey {
  color: #77777a !important;
}

.text-muted {
  color: rgba(0, 0, 0, 0.65);
}

.background-collaborator {
  background-color: #bbdefb;
}


.extranet-container {
  margin-top: 20px;
  margin-bottom: 20px;
}



.register-form-container {
  margin-top: 20px;
  margin-bottom: 20px;
}




appraisal .icon-help {
  color: #54e346 !important;
}

appraisal #icon-pdf-appraisal {
  margin-bottom: 10px;
}

appraisal .zone-top {
  height: 150px !important;
}

.card-hibo md-card-header {
  background-color: #001f49;
  color: white;
}

.zone-button {
  height: 100px !important;
}

.warning-errors {
  margin: 20px 20px 20px 5px;
  padding: 20px 10px 20px 10px;
  font-weight: 600;
  border-radius: 10px 10px 10px 10px;
  background-color: #54e346;
  color: white;
}

.warning-success {
  margin: 10px 10px 10px 5px;
  padding: 10px 5px 10px 5px;
  color: #155724;
  background-color: #d4edda;
  border-color: #bec5bf;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.warning-success md-icon {
  color: #155724;
}

.btn-flash {
  box-shadow: 0 0 30px #4f9 !important;
}

a:link {
  text-decoration: none;
}

.hibo-logo {
  max-width: 280px;
  max-height: 70px;
}

.logo-hibo {
  background-color: white;
  max-width: 50px;
  max-height: 25px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 10px 10px 10px 10px;
}

.md-content-list md-content {
  background-color: white !important;
}

.import-export-card {
  min-height: 750px !important;
}

.zone-contact {
  background-color: #efebeb;
}

.zone-query-details {
  background-color: #54e346;
  color: white;
  margin: 40px 5px 20px 5px;
  padding: 20px 10px 20px 10px;
  border-radius: 10px 10px 10px 10px;
}

.zone-query-details span {
  font-size: 18px;
}

.zone-ticket {
  color: #54e346;
  background-color: white;
  margin: 40px 5px 20px 5px;
  padding: 20px 10px 20px 10px;
  border-radius: 10px 10px 10px 10px;
}

.textarea-query {
  background-color: #fafafa !important;
  border-radius: 10px 10px 10px 10px !important;
  margin-top: 30px !important;
  padding: 10px 20px 10px 10px !important;
}

.mdc-text-blue {
  color: #3330e4 !important;
}

.zone-divider {
  height: 50px;
  background-color: white;
}

.zone-button {
  height: 100px !important;
}

.warning-errors {
  margin: 20px 20px 20px 5px;
  padding: 20px 10px 20px 10px;
  font-weight: 600;
  border-radius: 10px 10px 10px 10px;
  background-color: #54e346;
  color: white;
}

.warning-success {
  margin: 10px 10px 10px 5px;
  padding: 10px 5px 10px 5px;
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.warning-success md-icon {
  color: #155724;
}

.btn-flash {
  box-shadow: 0 0 30px #4f9 !important;
}

a:link {
  text-decoration: none;
}

.img-thumbnail {
  height: 100px;
  width: 150px;
  cursor: pointer;
}

.img-no-thumbnail {
  height: 55px;
  width: 55px;
  cursor: pointer;
}

.toolbar-table {
  padding: 0;
}

@media screen and (max-width: 599px) {
  .limit-select {
    display: none;
  }
}

.md-content-list md-content {
  background-color: white !important;
}

.card-contact {
  min-height: 750px !important;
}

.zone-contact {
  background-color: #efebeb;
}

.form-support {
  min-height: 500px !important;
}

.zone-query-details {
  background-color: #54e346;
  color: white;
  margin: 40px 5px 20px 5px;
  padding: 20px 10px 20px 10px;
  border-radius: 10px 10px 10px 10px;
}

.zone-query-details span {
  font-size: 18px;
}

.zone-ticket {
  color: #54e346;
  background-color: white;
  margin: 40px 5px 20px 5px;
  padding: 20px 10px 20px 10px;
  border-radius: 10px 10px 10px 10px;
}

.textarea-query {
  background-color: #fafafa !important;
  border-radius: 10px 10px 10px 10px !important;
  margin-top: 30px !important;
  padding: 10px 20px 10px 10px !important;
}

.mdc-text-blue {
  color: #3330e4 !important;
}

.zone-divider {
  height: 30px !important;
  background-color: white !important;
}

.zone-button {
  height: 100px !important;
  background-color: !important;
}

#appraisal-send-email-dialog-myself {
  margin: 50px 0px 0px 10px;
}






kw-label-efficiency table, kw-label-efficiency th, kw-label-efficiency td {
  border: 4px solid #f3f3f3;
  border-collapse: collapse;
}

kw-label-efficiency .classA, kw-label-efficiency .classB, kw-label-efficiency .classC, kw-label-efficiency .classD, kw-label-efficiency .classE, kw-label-efficiency .classF, kw-label-efficiency .classG {
  position: relative;
  display: block;
  height: 18px;
  box-sizing: border-box;
  padding-left: 3px;
  line-height: 18px;
  color: #fff;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
}

kw-label-efficiency .classA::after, kw-label-efficiency .classB::after, kw-label-efficiency .classC::after, kw-label-efficiency .classD::after, kw-label-efficiency .classE::after, kw-label-efficiency .classF::after, kw-label-efficiency .classG::after {
  content: '';
  position: absolute;
  left: 100%;
  border: 9px solid transparent;
  border-left: 9px solid #fbb900;
}

kw-label-efficiency .classA > sup, kw-label-efficiency .classB > sup, kw-label-efficiency .classC > sup, kw-label-efficiency .classD > sup, kw-label-efficiency .classE > sup, kw-label-efficiency .classF > sup, kw-label-efficiency .classG > sup {
  position: absolute;
  top: -3px;
}

kw-label-efficiency .classA {
  width: 30px;
  background-color: #63aa5a;
  border-color: #63aa5a;
}

kw-label-efficiency .classA::after {
  border-left-color: #63aa5a;
}

kw-label-efficiency .classB {
  width: 37px;
  background-color: #7bae4a;
  border-color: #7bae4a;
}

kw-label-efficiency .classB::after {
  border-left-color: #7bae4a;
}

kw-label-efficiency .classC {
  width: 44px;
  background-color: #bdd342;
  border-color: #bdd342;
}

kw-label-efficiency .classC::after {
  border-left-color: #bdd342;
}

kw-label-efficiency .classD {
  width: 51px;
  background-color: #ffe731;
  border-color: #ffe731;
}

kw-label-efficiency .classD::after {
  border-left-color: #ffe731;
}

kw-label-efficiency .classE {
  width: 58px;
  background-color: #fbb900;
  border-color: #fbb900;
}

kw-label-efficiency .classE::after {
  border-left-color: #fbb900;
}

kw-label-efficiency .classF {
  width: 65px;
  background-color: #fb8800;
  border-color: #fb8800;
}

kw-label-efficiency .classF::after {
  border-left-color: #fb8800;
}

kw-label-efficiency .classG {
  width: 72px;
  background-color: #e30613;
  border-color: #e30613;
}

kw-label-efficiency .classG::after {
  border-left-color: #e30613;
}

kw-label-efficiency .tagBlack::before {
  content: '';
  position: absolute;
  right: 100%;
  border: 9px solid transparent;
  border-right: 9px solid black;
}

kw-label-efficiency .tagBlack {
  left: 16px;
  width: 45px;
  background-color: black;
  color: white;
  position: relative;
  display: block;
  height: 18px;
  box-sizing: border-box;
  padding-left: 8px;
  line-height: 18px;
  color: #fff;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
}

kw-label-efficiency .font-efficiency-11 {
  font-size: 11px !important;
}

kw-map #kw-map {
  height: 100%;
  width: 100%;
  z-index: 10;
}

kw-map .cursor-pointer {
  cursor: pointer;
}

kw-map .icon-property {
  height: 24px;
  width: 24px;
}

kw-map .loading-map {
  position: absolute;
  top: 35%;
  left: 45%;
  z-index: 10000000;
}

@media screen and (max-width: 960px) {
  kw-map .loading-map {
    left: 40%;
  }
}

kw-map .leaflet-pane {
  z-index: 10;
}

kw-map .message {
  z-index: 100;
  height: 60px;
  position: relative;
  left: 46px;
  right: 5px;
  top: 12px;
  padding: 10px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  margin-left: 5px;
}

kw-map .message .icon {
  font-size: 32px;
  height: 32px;
  width: 32px;
  color: white;
}









.autocomplete-template li {
  border-bottom: 1px solid #CCCCCC;
  height: auto10px;
  padding-top: 8px;
  padding-bottom: 8px;
  white-space: normal;
}

.autocomplete-template li:last-child {
  border-bottom-width: 0;
}

.autocomplete-template .item-info-basic, .autocomplete-template .item-info-extra {
  display: block;
  line-height: 2;
}

.autocomplete-template li {
  border-bottom: 1px solid #CCCCCC;
  height: auto10px;
  padding-top: 8px;
  padding-bottom: 8px;
  white-space: normal;
}

.autocomplete-template li:last-child {
  border-bottom-width: 0;
}

.autocomplete-template .item-info-basic, .autocomplete-template .item-info-extra {
  display: block;
  line-height: 2;
}


.autocomplete-template li {
  border-bottom: 1px solid #CCCCCC;
  height: auto10px;
  padding-top: 8px;
  padding-bottom: 8px;
  white-space: normal;
}

.autocomplete-template li:last-child {
  border-bottom-width: 0;
}

.autocomplete-template .item-info-basic, .autocomplete-template .item-info-extra {
  display: block;
  line-height: 2;
}









.icon-info-tax {
  margin: 0px 5px 0px 0px;
  color: black;
}






agency-show-collaboration .img-agency {
  object-fit: cover;
  height: 300px !important;
  width: 100% !important;
}

agency-show-collaboration .img-logo-agency {
  object-fit: cover;
  height: 30px !important;
  width: auto !important;
  margin: 5px 10px 5px 20px;
}

agency-show-collaboration #agency-show-header {
  background-size: 100% 100% !important;
}

agency-show-collaboration #agency-show-header h1 {
  font-family: 'Varela Round', sans-serif !important;
  font-weight: bold;
  margin: 0px;
}

agency-show-collaboration #agency-show-header .agency-show-botton-zone {
  width: 800px !important;
}

@media screen and (max-width: 599px) {
  agency-show-collaboration #agency-show-header .agency-show-botton-zone {
    width: 400px !important;
  }
}

@media screen and (max-width: 599px) {
  agency-show-collaboration #angency-show-map .agency-show-map-info {
    height: 320px !important;
    width: 320px !important;
  }
}

agency-show-collaboration .as-map-overlay {
  position: relative;
  top: 20px;
  z-index: 11;
}

agency-show-collaboration .as-map-overlay-button {
  position: absolute;
  right: calc(0%);
}

agency-show-collaboration .agency-img {
  max-height: 500px;
  max-width: 650px;
}

agency-show-collaboration .contact-agency {
  padding: 5px 30px 5px 30px;
  margin-top: 25px;
  margin-bottom: 0px;
}

@media screen and (max-width: 599px) {
  agency-show-collaboration .contact-agency {
    padding: 0px;
    margin-bottom: 25px;
  }
}

agency-show-collaboration .contact-agency .button-contact {
  width: 100px !important;
  margin-left: 20px !important;
}

@media screen and (max-width: 599px) {
  agency-show-collaboration .contact-agency .button-contact {
    width: 140px !important;
    margin-left: 5px !important;
  }
}

agency-show-basic .img-agency {
  object-fit: cover;
  height: 300px !important;
  width: 100% !important;
}

agency-show-basic .img-logo-agency {
  object-fit: cover;
  height: 30px !important;
  width: auto !important;
  margin: 5px 10px 5px 20px;
}

agency-show-basic .color-text {
  color: white;
}

agency-show-basic .img-agency {
  object-fit: cover;
  height: 300px !important;
  width: 100% !important;
}

agency-show-basic .img-logo-agency {
  object-fit: cover;
  height: 30px !important;
  width: auto !important;
  margin: 5px 10px 5px 20px;
}

agency-show-basic .color-text {
  color: white;
}

agency-show-contact .agency-show-contact-info {
  border: 1px solid #cfcfcf;
  height: 500px !important;
}

agency-show-contact .agency-show-contact-info .agency-show-contact-info-form {
  min-width: 320px !important;
}

agency-show-contact .mdc-text-blue {
  color: #3330e4 !important;
}

agency-show-highlight {
  background-color: #fafafa;
}

agency-show-highlight .card-highlight {
  height: 150px !important;
  margin: 5px !important;
}

agency-show-highlight .card-highlight md-card-title-text md-icon {
  height: 60px !important;
  width: 60px !important;
}

agency-show-highlight .card-highlight #progress {
  margin-left: 13px !important;
  margin-top: -30px !important;
  font-size: 12px !important;
}

agency-show-highlight .card-highlight md-progress-circular svg path {
  stroke: #fbbc05;
}


agency-show-info {
  background-color: #fafafa;
}

agency-show-info md-icon.icons-info {
  height: 150px;
  width: 150px;
  margin-left: 20px;
}

@media (max-width: 600px) {
  agency-show-info md-icon.icons-info {
    height: 90px;
    width: 90px;
    margin-left: 0;
  }
}

agency-show-info .kw-label-info {
  color: white;
  font-size: 100%;
  padding: 2px 8px;
  border-radius: 4px;
}

agency-show-map .agency-show-map-info {
  border: 1px solid #cfcfcf;
  height: 500px;
}

agency-show-map .as-map-overlay {
  position: relative;
  top: 20px;
  z-index: 11;
}

agency-show-map .as-map-overlay-button {
  position: absolute;
  right: calc(0%);
}

agency-show-service {
  background-color: #fafafa;
}

agency-show-service a {
  text-decoration: none;
}

agency-show-service .color-icon-adaix {
  color: #4a72b2 !important;
}

agency-show-user {
  background-color: #fafafa;
}

agency-show-user md-card {
  height: 280px !important;
  border-radius: 5px;
  padding-top: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

agency-show-user md-card img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid white;
  box-sizing: content-box;
  padding: 2px;
}

agency-show-user md-card md-card-title {
  height: 140px !important;
}

@media screen and (max-width: 599px) {
  agency-show-user md-card md-card-title {
    padding: 5px;
  }
}

agency-show-user md-card md-card-title md-card-title-text p, agency-show-user md-card md-card-title md-card-title-text span {
  text-align: center;
}

agency-show-user md-card md-card-title md-card-title-text p md-icon, agency-show-user md-card md-card-title md-card-title-text span md-icon {
  font-size: 16px;
}

@media screen and (max-width: 599px) {
  agency-show-user md-card md-card-title md-card-title-text p, agency-show-user md-card md-card-title md-card-title-text span {
    margin-bottom: 5px;
  }
}

agency-show-user md-card md-card-content {
  height: 60px !important;
}

agency-show-web .color-text {
  color: white;
}

agency-show-web .colors_info {
  height: 50px !important;
  padding: 15px 10px 15px 10px;
  text-align: center !important;
  background-color: #f7ecec !important;
  margin: auto;
}

agency-show-web .box-color {
  border: 1px solid #000000;
}

agency-show-web .imagen-web-upload {
  object-fit: cover;
  max-height: 300px !important;
  max-width: 300px !important;
}

@media screen and (max-width: 599px) {
  agency-show-web .imagen-web-upload {
    max-height: 280px !important;
    max-width: 280px !important;
  }
}

agency-update-multimedia .color-text {
  color: white;
}

agency-update-multimedia .box-color {
  border: 1px solid #000000;
}

agency-update-multimedia .imagen-web-upload {
  object-fit: cover;
  max-height: 300px !important;
  max-width: 300px !important;
}

@media screen and (max-width: 599px) {
  agency-update-multimedia .imagen-web-upload {
    max-height: 280px !important;
    max-width: 280px !important;
  }
}

agency-update-multimedia .botton-color-option {
  margin-bottom: 40px !important;
}

@media screen and (max-width: 599px) {
  agency-update-multimedia .botton-color-option {
    margin-bottom: 15px !important;
  }
}

agency-update-multimedia a.link-support-web:link {
  color: #c72049;
  font-weight: 600;
  margin-left: 5px;
}

agency-update-multimedia .zone-color-option {
  margin: 20px 0px 20px 0px !important;
}

@media screen and (max-width: 599px) {
  agency-update-multimedia .zone-color-option {
    margin: 0px 0px 0px 0px !important;
  }
}

agency-update-multimedia .botton-domain-option {
  margin: 10px 0px 40px 0px !important;
}

@media screen and (max-width: 599px) {
  agency-update-multimedia .botton-domain-option {
    font-size: 12px !important;
  }
}

agency-update-multimedia .zone-domain-option {
  font-size: 16px;
}

@media screen and (max-width: 599px) {
  agency-update-multimedia .zone-domain-option {
    font-size: 12px !important;
  }
}

agency-update-multimedia .zone-setting-web,
agency-update-multimedia .zone-setting-property {
  background-color: #54e346;
  color: white;
  max-width: 680px !important;
  margin: 20px 20px 20px 5px;
  padding: 20px 10px 20px 10px;
  font-weight: 600;
  border-radius: 10px 10px 10px 10px;
}

agency-update-multimedia .zone-setting-property {
  max-width: 1025px !important;
}

agency-update-service-external .color-text {
  color: white;
}

agency-update-service-external .colors_info {
  height: 50px !important;
  padding: 15px 10px 15px 10px;
  text-align: center !important;
  background-color: #f7ecec !important;
  margin: auto;
}

agency-update-service-external .box-color {
  border: 1px solid #000000;
}


agency-update-web .color-text {
  color: white;
}

agency-update-web .box-color {
  border: 1px solid #000000;
}

agency-update-web .imagen-web-upload {
  object-fit: cover;
  max-height: 300px !important;
  max-width: 300px !important;
}

@media screen and (max-width: 599px) {
  agency-update-web .imagen-web-upload {
    max-height: 280px !important;
    max-width: 280px !important;
  }
}

agency-update-web .botton-color-option {
  margin-bottom: 40px !important;
}

@media screen and (max-width: 599px) {
  agency-update-web .botton-color-option {
    margin-bottom: 15px !important;
  }
}

agency-update-web a.link-support-web:link {
  color: #c72049;
  font-weight: 600;
  margin-left: 5px;
}

agency-update-web .zone-color-option {
  margin: 20px 0px 20px 0px !important;
}

@media screen and (max-width: 599px) {
  agency-update-web .zone-color-option {
    margin: 0px 0px 0px 0px !important;
  }
}

agency-update-web .botton-domain-option {
  margin: 10px 0px 40px 0px !important;
}

@media screen and (max-width: 599px) {
  agency-update-web .botton-domain-option {
    font-size: 12px !important;
  }
}

agency-update-web .zone-domain-option {
  font-size: 16px;
}

@media screen and (max-width: 599px) {
  agency-update-web .zone-domain-option {
    font-size: 12px !important;
  }
}

agency-update-web .zone-setting-web,
agency-update-web .zone-setting-property {
  background-color: #54e346;
  color: white;
  max-width: 680px !important;
  margin: 20px 20px 20px 5px;
  padding: 20px 10px 20px 10px;
  font-weight: 600;
  border-radius: 10px 10px 10px 10px;
}

agency-update-web .zone-setting-property {
  max-width: 1025px !important;
}

app-header .head-image {
  height: auto;
  width: 90%;
}

app-header .logo-background {
  background-color: #000000;
  width: 320px;
}

app-header .logo-flooges {
  fill: white;
  height: 41px;
  width: 119px;
}

@media screen and (max-width: 500px) {
  app-header .logo-flooges {
    height: 41px;
    width: 58px;
  }
}

@media screen and (min-width: 500px) {
  app-header .form-search-reference {
    margin-left: 15px;
  }
}

app-header .form-search-reference md-input-container {
  padding-left: 5px;
}

app-header .form-search-reference md-input-container input {
  min-width: 120px !important;
}

@media screen and (max-width: 500px) {
  app-header .form-search-reference md-input-container input {
    width: 60px !important;
  }
}

@media screen and (max-width: 500px) {
  app-header .form-search-reference md-input-container {
    padding-left: 1px;
  }
}

app-header .float-right {
  float: right;
}

app-header .mail-url {
  text-decoration: none !important;
  color: grey !important;
  font-weight: 600;
  margin-right: 0px !important;
  margin-left: 30px !important;
}

app-header .mail-url span {
  font-size: 14px !important;
  padding-top: -2px !important;
}

@media screen and (min-width: 500px) {
  app-header .mail-url {
    margin-right: 20px !important;
    margin-left: 5px !important;
  }
}

@media screen and (max-width: 500px) {
  app-header .button-menu {
    margin-left: 30px !important;
    margin-right: 1px !important;
    padding-right: 1px !important;
  }
}

@media screen and (max-width: 500px) {
  app-header .button-search {
    margin-left: 1px !important;
    margin-right: 1px !important;
    padding-left: 1px !important;
    padding-right: 1px !important;
    width: 80px !important;
  }
}

app-menu lang,
app-menu .text-menu {
  color: white;
  font-weight: bold;
}

app-menu a:link {
  text-decoration: none;
}

app-menu .icon-menu {
  color: white;
}

app-menu .background {
  background-color: #3330e4 !important;
}

app-menu .background-yellow {
  background-color: #e3b037 !important;
}

app-menu .background-green {
  background-color: #54e346 !important;
}

app-menu .md-button.md-fab.md-mini {
  margin: 0;
  padding: 0;
  line-height: 35px;
  width: 34px;
  height: 36px;
}

app-menu .md-button.md-fab.md-mini .material-icons {
  font-size: 24px;
}

app-menu md-icon {
  color: white;
}


change-password md-content {
  background: #F6B221;
  background-image: url("/img/auth/background-1.jpg");
  background-size: 100% 100%;
  color: #3d3d42 !important;
}

@media screen and (min-width: 600px) {
  change-password .change-password-form-container {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 599px) {
  change-password .change-password-form-container h1 {
    margin: 0px 5px 2px 0px !important;
    font-size: 24px !important;
    font-weight: 600 !important;
  }
}

change-password .box-change-password-email, change-password .box-change-password-captcha {
  border-radius: 10px 10px 10px 10px;
  background-color: #efebeb;
  margin: 0px 10px 20px 10px;
}

change-password .box-change-password-email {
  min-height: 250px !important;
  padding-top: 15px;
}

@media screen and (min-width: 600px) {
  change-password .box-change-password-email {
    min-width: 350px !important;
  }
}

@media screen and (max-width: 599px) {
  change-password .box-change-password-email {
    min-width: 300px !important;
  }
}

change-password .box-change-password {
  margin: 0px !important;
  max-height: 80px !important;
}

change-password .box-change-password md-input-container {
  margin: 0px !important;
}

change-password .box-change-password-captcha {
  height: 100px;
  min-width: 200px;
  padding: 20px !important;
}

forgot-password md-content {
  background: #F6B221;
  background-image: url("/img/auth/background-1.jpg");
  background-size: 100% 100%;
  color: #3d3d42 !important;
}

@media screen and (min-width: 600px) {
  forgot-password .forgot-password-form-container {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 599px) {
  forgot-password .forgot-password-form-container h1 {
    margin: 0px 5px 2px 0px !important;
    font-size: 24px !important;
    font-weight: 600 !important;
  }
}

forgot-password .box-forgot-password-email, forgot-password .box-forgot-password-captcha {
  border-radius: 10px 10px 10px 10px;
  background-color: #efebeb;
  margin: 0px 10px 20px 10px;
}

forgot-password .box-forgot-password-captcha {
  margin: 0px 10px 0px 10px;
}

forgot-password .box-forgot-password-email {
  min-height: 70px !important;
}

@media screen and (min-width: 600px) {
  forgot-password .box-forgot-password-email {
    min-width: 350px !important;
  }
}

@media screen and (max-width: 599px) {
  forgot-password .box-forgot-password-email {
    min-width: 300px !important;
  }
}

forgot-password .box-forgot-password-email md-input-container {
  margin: 0px !important;
}

forgot-password .box-forgot-password-captcha {
  height: 100px;
  min-width: 200px;
  padding: 20px !important;
}

login-form .background-1 {
  background: "#332fe4";
  background-image: url("/img/login/background-1.jpg");
  background-size: auto 100%;
}

login-form .background-2 {
  background: "#54e347";
  background-image: url("/img/login/background-2.jpg");
  background-size: auto 100%;
}

login-form .background-3 {
  background: "#332fe5";
  background-image: url("/img/login/background-3.jpg");
  background-size: 100% 100%;
}

@media screen and (max-width: 599px) {
  login-form .background-3 {
    background-size: auto 100%;
  }
}

login-form .background-4 {
  background: "#54e347";
  background-image: url("/img/login/background-4.jpg");
  background-size: 100% 100%;
}

@media screen and (max-width: 599px) {
  login-form .background-4 {
    background-size: auto 100%;
  }
}

login-form .background-5 {
  background: "#332fe4";
  background-image: url("/img/login/background-5.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
}

@media screen and (max-width: 599px) {
  login-form .background-5 {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    background-color: #332fe4;
  }
}

login-form .background-6 {
  background: "#54e347";
  background-image: url("/img/login/background-6.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
}

@media screen and (max-width: 599px) {
  login-form .background-6 {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    background-color: #54e347;
  }
}

login-form .background-7 {
  background: "#332fe5";
  background-image: url("/img/login/background-7.jpg");
  background-size: 100% 100%;
}

@media screen and (max-width: 599px) {
  login-form .background-7 {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    background-color: #332fe5;
  }
}

login-form .background-8 {
  background: "#54e347";
  background-image: url("/img/login/background-8.jpg");
  background-size: 100% 100%;
}

@media screen and (max-width: 599px) {
  login-form .background-8 {
    background-size: auto 100%;
  }
}

login-form .background-9 {
  background: "#2055d7";
  background-image: url("/img/login/background-9.jpg");
  background-size: 100% 100%;
}

@media screen and (max-width: 599px) {
  login-form .background-9 {
    background-size: auto 100%;
  }
}

login-form .background-10 {
  background: "#2055d7";
  background-image: url("/img/login/background-9.jpg");
  background-size: 100% 100%;
}

@media screen and (max-width: 599px) {
  login-form .background-10 {
    background-size: auto 100%;
  }
}

login-form .background-11 {
  background: "#2055d7";
  background-image: url("/img/login/background-9.jpg");
  background-size: 100% 100%;
}

login-form form {
  z-index: 19999;
  width: 300px;
  background: white;
}

login-form .icon-png {
  height: auto;
  width: 70%;
}

login-form .md-button.md-default-theme.md-raised,
login-form .md-button.md-raised {
  color: white;
  background-color: #f6b221;
}

login-form .md-button.md-default-theme.md-raised:not([disabled]):hover,
login-form .md-button.md-raised:not([disabled]):hover {
  background-color: black;
}

login-form .box-login {
  border-radius: 5px 5px 5px 5px !important;
}

register-form md-content {
  background: #F6B221;
  background-image: url("/img/auth/background-1.jpg");
  background-size: 100% 100%;
  color: #3d3d42 !important;
}

@media screen and (min-width: 600px) {
  register-form .md-toolbar-register-form {
    max-height: 76px !important;
  }
}

@media screen and (max-width: 599px) {
  register-form .md-toolbar-register-form {
    max-height: 64px !important;
  }
  register-form .md-toolbar-register-form h1 {
    margin: 0px 2px 2px -5px !important;
    font-size: 24px !important;
    font-weight: 600 !important;
  }
}

register-form .register-container {
  background-color: #d6d1d1 !important;
  height: auto;
  padding-top: 0px !important;
}

@media screen and (min-width: 600px) {
  register-form .register-container {
    padding-top: 20px !important;
  }
}

@media screen and (max-width: 599px) {
  register-form .register-form-container {
    margin-top: 0px !important;
  }
}

register-form .legal-register-form {
  font-size: 12px;
  margin: 0px 0px 0px 10px;
  padding: 0px;
}

register-form .info-subdomain, register-form .text-info-subdomain {
  background-color: #f1ebeb;
}

register-form .info-subdomain {
  padding: 10px 5px 10px 5px;
}

register-form .text-info-subdomain {
  padding: 5px;
  font-size: 12px;
}

register-form a.link-support-register:link {
  color: #c72049;
  font-weight: 600;
  margin-left: 5px;
}

register-form icon-register {
  color: black;
}

register-form .terms-register-link, register-form .legal-register-link {
  color: #039be5;
  font-weight: 600px;
}

register-form .margin-top-register {
  margin-top: 15px !important;
}

@media screen and (max-width: 599px) {
  register-form .margin-top-register {
    margin-top: 5px !important;
  }
}

@media screen and (max-width: 599px) {
  register-form .margin-bottom-register {
    margin-bottom: 10px !important;
    margin-top: 0px !important;
  }
}

register-form .zone-top {
  height: 100px !important;
}

vacation-index .img-thumbnail {
  height: 55px;
  width: 80px;
  cursor: pointer;
}

vacation-index .img-no-thumbnail {
  height: 55px;
  width: 55px;
  cursor: pointer;
}

vacation-index .toolbar-table {
  padding: 0;
}


cashier-plan .border-radius, cashier-plan .zone-plan-now, cashier-plan .zone-plan, cashier-plan .zone-calculated-subscription, cashier-plan .btn-cancel-plan, cashier-plan .zone-fiscal {
  border-radius: 5px;
}

cashier-plan .zone-plan-now {
  background-color: #ffd3d3;
  color: grey;
  font-weight: 600;
  height: 70px;
  margin-top: 20px;
}

cashier-plan .zone-plan {
  margin-top: 50px;
  background-color: #efebeb;
}

cashier-plan .btn-contract {
  height: 100px;
  width: 300px;
  font-size: 24px;
  border-radius: 10px 10px 10px 10px !important;
  margin: 0px;
}

cashier-plan .btn-cancel-plan {
  height: 35px;
  width: 180px;
  padding: 2px;
  margin: 0px 5px 0px 5px;
  font-size: 10px;
}

cashier-plan .btn-cancel-plan span {
  padding: 0px;
}

cashier-plan .btn-cancel-plan md-icon {
  padding: 0px;
  margin: 0px;
}

cashier-plan .btn-flash {
  box-shadow: 0 0 30px #4f9 !important;
}

cashier-plan .zone-fiscal {
  margin-top: 50px;
  background-color: #efebeb;
}

cashier-plan .border-radius, cashier-plan .zone-plan-now, cashier-plan .zone-plan, cashier-plan .zone-calculated-subscription, cashier-plan .btn-cancel-plan, cashier-plan .zone-fiscal {
  border-radius: 5px;
}

cashier-plan .zone-cashier-plan-offer, cashier-plan .zone-cashier-pay-error {
  background-color: #54e346;
  color: white;
  max-width: 620px;
  margin: 20px 20px 20px 5px;
  padding: 20px 10px 20px 10px;
  border-radius: 10px 10px 10px 10px;
}

cashier-plan .zone-cashier-plan-offer span, cashier-plan .zone-cashier-pay-error span {
  font-style: italic;
  font-weight: 600;
  font-size: 18px;
}

cashier-plan .zone-cashier-pay-error {
  background-color: #f7eded;
  color: black;
  margin: 20px 20px 20px 5px;
  padding: 5px 2px 5px 5px;
}

cashier-plan .zone-cashier-pay-error p {
  font-weight: 600;
  font-size: 16px;
  margin: 5px;
}

cashier-plan .zone-cashier-pay-error ol {
  font-size: 12px;
  margin: 5px;
}

cashier-plan .zone-cashier-pay-error span, cashier-plan .zone-cashier-pay-error a {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

cashier-plan .zone-plan-now {
  background-color: #54e346;
  color: white;
  font-weight: 600;
  height: 70px;
  margin-top: 20px;
}

@media screen and (max-width: 599px) {
  cashier-plan .zone-plan-now {
    height: 120px !important;
  }
}

cashier-plan .zone-plan {
  margin-top: 50px;
  background-color: #efebeb;
}

cashier-plan .btn-contract {
  height: 100px;
  width: 300px;
  font-size: 24px;
  border-radius: 10px 10px 10px 10px !important;
  margin: 0px;
}

cashier-plan .btn-contract-print {
  height: 40px !important;
  width: 170px !important;
  font-size: 16px !important;
  border-radius: 10px 10px 10px 10px !important;
  margin: 35px 30px 0px 0px !important;
}

cashier-plan .btn-tax {
  border-radius: 10px 10px 10px 10px !important;
  padding: 10px;
}

cashier-plan .btn-cancel-plan {
  height: 35px;
  width: 180px;
  padding: 2px;
  margin: 0px 5px 0px 5px;
  font-size: 10px;
}

cashier-plan .btn-cancel-plan span {
  padding: 0px;
}

cashier-plan .btn-cancel-plan md-icon {
  padding: 0px;
  margin: 0px;
}

cashier-plan .btn-flash {
  box-shadow: 0 0 30px #4f9 !important;
}

cashier-plan .zone-fiscal {
  margin-top: 50px;
  background-color: #efebeb;
}

cashier-plan .link-plus-info {
  margin-top: 10px;
  padding-left: 10px;
  text-decoration: none;
  color: #039be5;
}

cashier-plan .plan-label, cashier-plan .bronze, cashier-plan .silver, cashier-plan .gold, cashier-plan .platinum {
  color: white;
  font-size: 24px;
  font-weight: 600;
  padding: 2px 8px;
  border-radius: 4px;
}

cashier-plan .bronze {
  background: #cd7f32 !important;
}

cashier-plan .silver {
  background: #c0c0c0 !important;
}

cashier-plan .gold {
  background: #ffd700 !important;
}

cashier-plan .platinum {
  background: #e5e4e2 !important;
}


collaboration-index-filter kw-select-address div md-input-container {
  margin: 0px !important;
}


collaboration-index-petition-received .no-shadow-chips md-chips-wrap {
  box-shadow: none !important;
}

collaboration-index-petition-sent .no-shadow-chips md-chips-wrap {
  box-shadow: none !important;
}

collaboration-list-private .no-shadow-chips md-chips-wrap {
  box-shadow: none !important;
}

collaboration-list-private .img-thumbnail {
  height: 100px;
  width: 150px;
  cursor: pointer;
  padding: 2px;
  margin-right: 15px;
}

@media screen and (max-width: 599px) {
  collaboration-list-private .img-thumbnail {
    margin-right: 5px;
  }
}

collaboration-list-public .no-shadow-chips md-chips-wrap {
  box-shadow: none !important;
}

collaboration-list-public .img-thumbnail {
  height: 100px;
  width: 150px;
  cursor: pointer;
  padding: 2px;
}

collaboration-list-public .img-no-thumbnail {
  height: 55px;
  width: 55px;
  cursor: pointer;
  padding: 2px;
}

md-content {
  min-height: 600px;
}

collaboration-map-pagination .paginate {
  background-color: rgba(169, 168, 168, 0.21);
  border-radius: 10px;
  padding: 1px;
  margin: 10px 5px 5px 5px;
}

@media screen and (max-width: 599px) {
  collaboration-map-pagination .paginate {
    margin: 0px 5px 0px 5px !important;
  }
}

collaboration-map-pagination .linkDisabled {
  cursor: not-allowed;
  pointer-events: none;
}

collaboration-map-pagination .md-button {
  background-color: #3330e4 !important;
}

collaboration-map-public #map-agencies {
  min-height: 300px !important;
  min-width: 300px !important;
}

@media screen and (max-width: 599px) {
  collaboration-map-public #map-agencies {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
}

collaboration-map-public .no-shadow-chips md-chips-wrap {
  box-shadow: none !important;
}

collaboration-map-public .collaboration-agency-list {
  background-color: #f7f3f3;
}

@media screen and (max-width: 599px) {
  collaboration-map-public .collaboration-agency-list {
    margin-top: 0px !important;
  }
}

collaboration-map-public md-card-title {
  padding: 10px 5px 5px 5px;
}

collaboration-map-public md-card-content {
  padding-bottom: 0px;
}

collaboration-property-index .td-reference {
  padding-right: 10px !important;
  width: 180px !important;
}

collaboration-property-index a:link {
  text-decoration: none;
}

collaboration-property-index .img-thumbnail {
  height: 55px;
  width: 80px;
  cursor: pointer;
}

collaboration-property-index .img-no-thumbnail {
  height: 55px;
  width: 55px;
  cursor: pointer;
}

collaboration-property-index .toolbar-table {
  padding: 0;
}

collaboration-property-index .space-span {
  height: 10px !important;
}



collaboration-property-show-photos img {
  height: 100%;
  width: 100%;
}

@charset "UTF-8";
.responsive-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  /* Mantiene la proporción de la imagen y llena el espacio disponible */
  border-radius: 4px;
  /* Opcional: esquinas redondeadas */
  display: block;
  /* Elimina cualquier espacio no deseado */
}

collaboration-property-particular-index .td-reference {
  padding-right: 10px !important;
  width: 180px !important;
}

collaboration-property-particular-index a:link {
  text-decoration: none;
}

collaboration-property-particular-index .img-thumbnail {
  height: 55px;
  width: 80px;
  cursor: pointer;
}

collaboration-property-particular-index .img-no-thumbnail {
  height: 55px;
  width: 55px;
  cursor: pointer;
}

collaboration-property-particular-index .toolbar-table {
  padding: 0;
}



collaboration-property-show-photos img {
  height: 100%;
  width: 100%;
}

contact-index a:link {
  text-decoration: none;
}

contact-index .toolbar-table {
  padding: 0;
}

@media screen and (max-width: 599px) {
  contact-index .limit-select {
    display: none;
  }
}

contact-index .contact-unsubscribe-alert {
  color: red !important;
}


.contact-unsubscribe-alert {
  color: red !important;
}

agency-show-collaboration .img-agency {
  object-fit: cover;
  height: 300px !important;
  width: 100% !important;
}

agency-show-collaboration .img-logo-agency {
  object-fit: cover;
  height: 30px !important;
  width: auto !important;
  margin: 5px 10px 5px 20px;
}

agency-show-collaboration #agency-show-header {
  background-size: 100% 100% !important;
}

agency-show-collaboration #agency-show-header h1 {
  font-family: 'Varela Round', sans-serif !important;
  font-weight: bold;
  margin: 0px;
}

agency-show-collaboration #agency-show-header .agency-show-botton-zone {
  width: 800px !important;
}

@media screen and (max-width: 599px) {
  agency-show-collaboration #agency-show-header .agency-show-botton-zone {
    width: 400px !important;
  }
}

@media screen and (max-width: 599px) {
  agency-show-collaboration #angency-show-map .agency-show-map-info {
    height: 320px !important;
    width: 320px !important;
  }
}

agency-show-collaboration .as-map-overlay {
  position: relative;
  top: 20px;
  z-index: 11;
}

agency-show-collaboration .as-map-overlay-button {
  position: absolute;
  right: calc(0%);
}

agency-show-collaboration .agency-img {
  max-height: 500px;
  max-width: 650px;
}

agency-show-collaboration .contact-agency {
  padding: 5px 30px 5px 30px;
  margin-top: 25px;
  margin-bottom: 0px;
}

@media screen and (max-width: 599px) {
  agency-show-collaboration .contact-agency {
    padding: 0px;
    margin-bottom: 25px;
  }
}

agency-show-collaboration .contact-agency .button-contact {
  width: 100px !important;
  margin-left: 20px !important;
}

@media screen and (max-width: 599px) {
  agency-show-collaboration .contact-agency .button-contact {
    width: 140px !important;
    margin-left: 5px !important;
  }
}

contact-particular-show-contact .contact-particular-show-contact-info {
  border: 1px solid #cfcfcf;
  height: 500px !important;
}

contact-particular-show-contact .contact-particular-show-contact-info .contact-particular-show-contact-info-form {
  min-width: 320px !important;
}

contact-particular-show-contact .mdc-text-blue {
  color: #3330e4 !important;
}

.link-plus-info {
  margin-top: 10px;
  padding-left: 10px;
  text-decoration: none;
  color: #039be5;
}

.contact-unsubscribe-alert {
  color: red !important;
}


contact-show-criterias .clickable {
  cursor: help;
}

contact-show-discarded .clickable {
  cursor: help;
}

.link-plus-info {
  margin-top: 10px;
  padding-left: 10px;
  text-decoration: none;
  color: #039be5;
}

.zone-login-extranet {
  width: 400px !important;
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.btn-flash {
  box-shadow: 0 0 30px #4f9 !important;
}


contact-show-interested .clickable {
  cursor: help;
}

.border-left {
  border-left: 1px solid #d4d4d4 !important;
}




contact-show-selected .clickable {
  cursor: help;
}

contact-show-selected .kw-label-selected {
  font-size: 14px;
  margin-left: 5px;
  margin-right: 5px;
}

@media screen and (max-width: 599px) {
  contact-show-selected .kw-label-selected {
    font-size: 10px !important;
  }
}

contact-show-suggested .clickable {
  cursor: help;
}

contact-show-suggested a:link {
  text-decoration: none;
}

contact-show-suggested .img-thumbnail {
  height: 75px;
  width: 125px;
  cursor: pointer;
}

contact-show-suggested .img-no-thumbnail {
  height: 55px;
  width: 55px;
  cursor: pointer;
}

contact-show-suggested .toolbar-table {
  padding: 0;
}

contact-show-suggested-collaboration-private .clickable {
  cursor: help;
}

contact-show-suggested-collaboration-private a:link {
  text-decoration: none;
}

contact-show-suggested-collaboration-private .img-thumbnail {
  height: 75px;
  width: 125px;
  cursor: pointer;
}

contact-show-suggested-collaboration-private .img-no-thumbnail {
  height: 55px;
  width: 55px;
  cursor: pointer;
}

contact-show-suggested-collaboration-private .toolbar-table {
  padding: 0;
}

contact-show-suggested-collaboration-private .clickable {
  cursor: help;
}

contact-show-suggested-collaboration-private a:link {
  text-decoration: none;
}

contact-show-suggested-collaboration-private .img-thumbnail {
  height: 75px;
  width: 125px;
  cursor: pointer;
}

contact-show-suggested-collaboration-private .img-no-thumbnail {
  height: 55px;
  width: 55px;
  cursor: pointer;
}

contact-show-suggested-collaboration-private .toolbar-table {
  padding: 0;
}

contact-show-suggested-collaboration-public .clickable {
  cursor: help;
}

contact-show-suggested-collaboration-public a:link {
  text-decoration: none;
}

contact-show-suggested-collaboration-public .img-thumbnail {
  height: 75px;
  width: 125px;
  cursor: pointer;
}

contact-show-suggested-collaboration-public .img-no-thumbnail {
  height: 55px;
  width: 55px;
  cursor: pointer;
}

contact-show-suggested-collaboration-public .toolbar-table {
  padding: 0;
}


contact-show-visited .clickable {
  cursor: help;
}

contact-show-workflow .workflow-line {
  position: relative;
  top: 27px;
  left: 48px;
  border: solid 1px;
  border-color: black;
}

contact-show-workflow .clickable {
  cursor: help;
}

contact-show-workflow .md-fab-grey {
  background: grey !important;
}



dashboard-contact-favourite {
  height: 150px !important;
}

dashboard-contact-favourite md-card-title-text md-icon {
  height: 60px !important;
  width: 60px !important;
}

dashboard-contact-favourite #progress {
  margin-left: 10px !important;
  margin-top: -35px !important;
}

dashboard-contact-favourite md-progress-circular svg path {
  stroke: #fbbc05;
}


dashboard-index .no-clickable {
  cursor: help;
}

dashboard-index .head-image {
  height: auto;
  width: 100%;
}

dashboard-meeting-day md-content {
  margin-top: 10px;
  height: 595px !important;
}

@media screen and (max-width: 959px) {
  dashboard-meeting-day md-content {
    height: 333px !important;
  }
}

dashboard-meeting-day .fc-center {
  margin-top: 10px;
}

dashboard-precontact img {
  height: 59px;
  width: 78px;
}

dashboard-property-last {
  height: 160px !important;
}

dashboard-property-last md-card-title-text md-icon {
  height: 60px !important;
  width: 60px !important;
}

dashboard-property-last #progress {
  margin-left: 10px !important;
  margin-top: -35px !important;
}

dashboard-property-last .shadow:hover {
  background-color: #fff;
}

dashboard-property-last md-progress-circular svg path {
  stroke: #fbbc05;
}

.zone-bottom {
  height: 100px !important;
}

.captcha-input {
  width: 2em;
  text-align: center;
  margin-top: -5px;
}

.margin-top-captcha {
  margin-top: 50px !important;
}

@media screen and (max-width: 599px) {
  .margin-top-captcha {
    margin-top: 10px !important;
  }
}


.grey-light-background-check-list {
  background-color: #f7efef;
  border-radius: 10px;
  min-height: 250px;
  padding: 5px;
}

.grey-light-background-check-list-big {
  background-color: #f7efef;
  border-radius: 10px;
  min-height: 750px;
  padding: 5px;
}




slick .slick-slide {
  text-align: center;
}

slick .slick-slide img {
  display: inline;
  max-width: 100%;
}

slick .arrow-prev {
  left: 0px;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  margin-top: -10px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

slick .arrow-prev md-icon {
  font-size: 90px;
  width: 90px;
  height: 90px;
}

@media (max-width: 959px) {
  slick .arrow-prev {
    top: 100%;
  }
}

slick .arrow-next {
  right: 10px;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  margin-top: -10px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

slick .arrow-next md-icon {
  font-size: 90px;
  width: 90px;
  height: 90px;
}

@media (max-width: 959px) {
  slick .arrow-next {
    top: 100%;
  }
}

crm-photos-edit {
  border: 3px double rgba(0, 0, 0, 0.5);
  border-style: double double dashed double;
}

crm-photos-edit img {
  height: 120px;
  width: 180px;
}

crm-photos-edit .as-sortable-placeholder {
  display: inline-block !important;
  height: 180px !important;
}

crm-photos-edit .as-sortable-drag {
  opacity: 0.8;
}

crm-photos-edit .as-sortable-hidden {
  display: none !important;
}


.autocomplete-contact-template li {
  border-bottom: 1px solid #CCCCCC;
  height: auto;
  padding-top: 8px;
  padding-bottom: 8px;
  white-space: normal;
}

.autocomplete-contact-template li:last-child {
  border-bottom-width: 0;
}

.autocomplete-contact-template .contact-info-basic, .autocomplete-contact-template .contact-info-extra {
  display: block;
  line-height: 2;
}

.btn-flash {
  box-shadow: 0 0 30px #4f9 !important;
}




.autocomplete-contact-template li {
  border-bottom: 1px solid #CCCCCC;
  height: auto;
  padding-top: 8px;
  padding-bottom: 8px;
  white-space: normal;
}

.autocomplete-contact-template li:last-child {
  border-bottom-width: 0;
}

.autocomplete-contact-template .contact-info-basic, .autocomplete-contact-template .contact-info-extra {
  display: block;
  line-height: 2;
}







.autocomplete-precontact-template li {
  border-bottom: 1px solid #CCCCCC;
  height: auto;
  padding-top: 8px;
  padding-bottom: 8px;
  white-space: normal;
}

.autocomplete-precontact-template li:last-child {
  border-bottom-width: 0;
}

.autocomplete-precontact-template .precontact-info-basic, .autocomplete-precontact-template .precontact-info-extra {
  display: block;
  line-height: 2;
}

.autocomplete-property-template li {
  border-bottom: 1px solid #CCCCCC;
  height: auto;
  padding-top: 8px;
  padding-bottom: 8px;
  white-space: normal;
}

.autocomplete-property-template li:last-child {
  border-bottom-width: 0;
}

.autocomplete-property-template .property-info-basic, .autocomplete-property-template .property-info-extra {
  display: block;
  line-height: 2;
}











.autocomplete-user-template li {
  border-bottom: 1px solid #CCCCCC;
  height: auto;
  padding-top: 8px;
  padding-bottom: 8px;
  white-space: normal;
}

.autocomplete-user-template li:last-child {
  border-bottom-width: 0;
}

.autocomplete-user-template .user-info-basic, .autocomplete-user-template .user-info-extra {
  display: block;
  line-height: 2;
}




.mailrelay-table {
  padding: 0px 0px 0px 2px;
}

.mailrelay-table {
  padding: 0px 0px 0px 2px;
}

.zone-mailrelay {
  background-color: #1c2433;
  color: white;
  max-height: 200px !important;
}

.logo-mailrelay {
  padding: 10px;
  border-radius: 5px;
  max-width: 380px;
}

.zone-row-mailrelay {
  margin: 20px 0px 20px 0px !important;
}

.mailrelay-table {
  padding: 0px 0px 0px 2px;
}

.mailrelay-table {
  padding: 0px 0px 0px 2px;
}

#login-form form {
  width: 300px;
  background: white;
}

@media screen and (max-width: 599px) {
  #login-form form {
    width: 280px;
  }
}

#login-form .icon-png {
  height: auto;
  width: 70%;
}

#login-form .md-button.md-default-theme.md-raised,
#login-form .md-button.md-raised {
  color: white;
  background-color: #f6b221;
}

#login-form .md-button.md-default-theme.md-raised:not([disabled]):hover,
#login-form .md-button.md-raised:not([disabled]):hover {
  background-color: black;
}

#login-form .box-login {
  border-radius: 5px 5px 5px 5px !important;
}

@media screen and (max-width: 599px) {
  #login-form .box-login {
    margin-top: 15px !important;
  }
}

#login-form .internal-form {
  margin-top: 20px !important;
  margin-bottom: 0px !important;
}

@media screen and (max-width: 599px) {
  #login-form .internal-form {
    margin-top: 5px !important;
  }
}

crm-captcha-simple h1 {
  font-size: 12px !important;
  margin: 10px !important;
}

crm-captcha-simple .margin-top-captcha {
  margin-top: 10px !important;
}

.background-1 {
  background: "#332fe4";
  background-image: url("/img/login/background-1.jpg");
  background-size: auto 100%;
}

.background-2 {
  background: "#54e347";
  background-image: url("/img/login/background-2.jpg");
  background-size: auto 100%;
}

.background-3 {
  background: "#332fe5";
  background-image: url("/img/login/background-3.jpg");
  background-size: 100% 100%;
}

@media screen and (max-width: 599px) {
  .background-3 {
    background-size: auto 100%;
  }
}

.background-4 {
  background: "#54e347";
  background-image: url("/img/login/background-4.jpg");
  background-size: 100% 100%;
}

@media screen and (max-width: 599px) {
  .background-4 {
    background-size: auto 100%;
  }
}

.background-5 {
  background: "#332fe4";
  background-image: url("/img/login/background-5.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
}

@media screen and (max-width: 599px) {
  .background-5 {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    background-color: #332fe4;
  }
}

.background-6 {
  background: "#54e347";
  background-image: url("/img/login/background-6.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
}

@media screen and (max-width: 599px) {
  .background-6 {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    background-color: #54e347;
  }
}

.background-7 {
  background: "#332fe5";
  background-image: url("/img/login/background-7.jpg");
  background-size: 100% 100%;
}

@media screen and (max-width: 599px) {
  .background-7 {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    background-color: #332fe5;
  }
}

.background-8 {
  background: "#54e347";
  background-image: url("/img/login/background-8.jpg");
  background-size: 100% 100%;
}

@media screen and (max-width: 599px) {
  .background-8 {
    background-size: auto 100%;
  }
}

.background-9 {
  background: "#2055d7";
  background-image: url("/img/login/background-9.jpg");
  background-size: 100% 100%;
}

@media screen and (max-width: 599px) {
  .background-9 {
    background-size: auto 100%;
  }
}

.background-10 {
  background: "#2055d7";
  background-image: url("/img/login/background-9.jpg");
  background-size: 100% 100%;
}

@media screen and (max-width: 599px) {
  .background-10 {
    background-size: auto 100%;
  }
}

.background-11 {
  background: "#2055d7";
  background-image: url("/img/login/background-9.jpg");
  background-size: 100% 100%;
}

#kw-map-extranet kw-map {
  height: 100% !important;
}

@media screen and (max-width: 599px) {
  #kw-map-extranet kw-map {
    height: 260px !important;
    width: 260px !important;
  }
}

.logo-portal-extranet {
  height: 50px !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.img-property {
  height: 100%;
  width: 100%;
}

property-show-video h3 {
  display: none !important;
}

.background-final-management {
  background-color: #96c596;
}

.btn-web {
  height: 42px;
  min-width: 320px;
  font-size: 16px;
  border-radius: 20px 20px 20px 20px !important;
  margin: 0px 5px 0px 5px;
}

@media screen and (max-width: 599px) {
  .btn-web {
    height: 32px;
    min-width: 260px;
    font-size: 12px;
    border-radius: 16px 16px 16px 16px !important;
    margin: 0px 5px 0px 5px;
  }
}

.avatar-icon {
  margin: 2px !important;
  padding: 0px !important;
  max-height: 30 !important;
  min-width: 30 !important;
}

.no-photo-extranet {
  max-height: 60px !important;
  margin: 5px !important;
}

.photo-extranet {
  max-height: 120px !important;
  margin: 20px !important;
  border-radius: 50%;
}

.workflow-extranet,
.agency-extranet,
.user-extranet {
  border: 1px solid #cfcfcf;
}

@media screen and (max-width: 599px) {
  .h1-extranet {
    font-size: 20px !important;
    margin-bottom: 10px !important;
  }
}

@media screen and (max-width: 599px) {
  .h2-extranet {
    font-size: 18px !important;
    margin-bottom: 10px !important;
  }
}

@media screen and (max-width: 599px) {
  dl {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
}

.botton-login {
  margin-top: 20px !important;
}

@media screen and (max-width: 599px) {
  .botton-login {
    margin-top: 0px !important;
  }
}

.zone-top {
  height: 100px !important;
}

@charset "UTF-8";
ia-index .img-thumbnail {
  height: 55px;
  width: 80px;
  cursor: pointer;
}

ia-index .img-no-thumbnail {
  height: 55px;
  width: 55px;
  cursor: pointer;
}

ia-index .toolbar-table {
  padding: 0;
}

ia-index .card-ia {
  margin: 0px !important;
  min-height: 100vh;
}

ia-index .card-ia md-card-header {
  background-color: black;
  color: white;
}

ia-index .zone-chat-ia {
  background-color: white;
  color: black;
}

ia-index .zone-button {
  height: 100px !important;
}

ia-index .warning-errors {
  margin: 20px 20px 20px 5px;
  padding: 20px 10px 20px 10px;
  font-weight: 600;
  border-radius: 10px 10px 10px 10px;
  background-color: #54e346;
  color: white;
}

ia-index .warning-success {
  margin: 10px 10px 10px 5px;
  padding: 10px 5px 10px 5px;
  color: #155724;
  background-color: #d4edda;
  border-color: #bec5bf;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

ia-index .warning-success md-icon {
  color: #155724;
}

ia-index .btn-flash {
  box-shadow: 0 0 30px #4f9 !important;
}

ia-index a:link {
  text-decoration: none;
}

ia-index .ia-logo {
  max-width: 280px;
  max-height: 70px;
}

ia-index .logo-ia {
  background-color: white;
  max-width: 50px;
  max-height: 25px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 10px 10px 10px 10px;
}

ia-index .card-ia {
  background-color: #f5f5f5;
}

ia-index .md-card-header {
  background-color: #4caf50;
  color: white;
}

ia-index .zone-chat-ia {
  display: flex;
  flex-direction: column;
  flex: 1;
}

ia-index .messages-container {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
  min-height: 160px;
}

ia-index .message {
  padding: 5px 10px;
  margin: 5px 0;
  border-radius: 5px;
  word-wrap: break-word;
  /* Asegura que el texto largo se ajuste al contenedor */
  display: flex;
  align-items: center;
  position: relative;
  /* Para posicionar el botón de copiar */
}

ia-index .message-header {
  position: absolute;
  top: -10px;
  right: 10px;
}

ia-index .message.user {
  background-color: #e0f7fa;
  text-align: left;
  justify-content: flex-start;
}

ia-index .message.chatgpt {
  background-color: #fff8e1;
  text-align: right;
  justify-content: flex-end;
}

ia-index .account-icon,
ia-index .chatgpt-icon {
  margin-right: 8px;
}

ia-index .chatgpt-icon {
  margin-right: 8px;
  margin-left: 0px !important;
}

ia-index .message:nth-child(odd) {
  background-color: #e0e0e0;
  text-align: right;
  justify-content: flex-end;
}

ia-index .message:nth-child(even) {
  background-color: #c8e6c9;
  text-align: left;
  justify-content: flex-start;
}

ia-index .input-container {
  display: flex;
  align-items: center;
}

ia-index textarea {
  width: 100%;
  min-height: 50px;
  resize: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
}

ia-index .md-fab.md-mini.md-primary {
  margin-left: 10px;
}

.div-container-insurance {
  text-align: center;
  margin-top: 50px;
}

.link-insurance {
  display: inline-block;
  background-color: #3330e4;
  color: white;
  font-size: 18px;
  font-weight: bold;
  padding: 12px 24px;
  border-radius: 8px;
  text-decoration: none;
}

.link-secondary-insurance {
  color: #3330e4;
  text-decoration: underline;
}

.p-insurance {
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 14px;
  color: #333;
}





property-card-basic img {
  max-height: 112px;
  max-width: 168px;
}


insurer-card img {
  max-height: 140px;
  max-width: 100%;
}

insurer-card span {
  margin: 2px 5px 0px 0px;
}

.img-thumbnail-property-key-list {
  height: 50px;
  width: 75px;
  cursor: pointer;
}

crm-select-type-key md-icon {
  color: gray !important;
}

.md-toolbar-register-form {
  max-height: 76px important;
}

.register-container {
  background-color: #d6d1d1 !important;
  padding-top: 20px;
  height: auto;
}

.legal-register-form {
  font-size: 12px;
  margin: 0px 0px 0px 10px;
  padding: 0px;
}

.card-access-data {
  background-color: #d6d1d1 !important;
}

.info-subdomain, .text-info-subdomain {
  background-color: #f1ebeb;
}

.info-subdomain {
  padding: 10px 5px 10px 5px;
}

.text-info-subdomain {
  padding: 5px;
  font-size: 12px;
}

a.link-support-register:link {
  color: #c72049;
  font-weight: 600;
  margin-left: 5px;
}

icon-register {
  color: black;
}

.terms-register-link, .legal-register-link {
  color: #039be5;
  font-weight: 600px;
}

.md-toolbar-register-form {
  max-height: 76px important;
}

.register-container {
  background-color: #d6d1d1 !important;
  padding-top: 20px;
  height: auto;
}

.legal-register-form {
  font-size: 12px;
  margin: 0px 0px 0px 10px;
  padding: 0px;
}

.card-access-data {
  background-color: #d6d1d1 !important;
}

.info-subdomain, .text-info-subdomain {
  background-color: #f1ebeb;
}

.info-subdomain {
  padding: 10px 5px 10px 5px;
}

.text-info-subdomain {
  padding: 5px;
  font-size: 12px;
}

a.link-support-register:link {
  color: #c72049;
  font-weight: 600;
  margin-left: 5px;
}

icon-register {
  color: black;
}

.terms-register-link, .legal-register-link {
  color: #039be5;
  font-weight: 600px;
}




operation-chart canvas {
  height: auto;
  width: 100%;
}

operation-index a:link {
  text-decoration: none;
}

operation-index .toolbar-table {
  padding: 0;
}

@media screen and (max-width: 599px) {
  operation-index .limit-select {
    display: none;
  }
}

operation-index .img-thumbnail {
  height: 65px;
  width: 90px;
  cursor: pointer;
}

operation-index .canceled {
  background: #F44336;
}

operation-index .confirmed {
  background: #1E88E5;
}

operation-index .pending {
  background: #43A047;
}

@media screen and (max-width: 599px) {
  operation-index .zone-title-operation {
    width: 100% !important;
  }
}

operation-index-filter .canceled {
  background: #F44336;
}

operation-index-filter .confirmed {
  background: #1E88E5;
}

operation-index-filter .pending {
  background: #43A047;
}

.contact-unsubscribe-alert {
  color: red !important;
}

operation-show-property img {
  max-height: 112px;
  max-width: 168px;
}

operation-show-property .contact-unsubscribe-alert {
  color: red !important;
}

.contact-unsubscribe-alert {
  color: red !important;
}


.logo-index-papernest {
  -webkit-clip-path: ellipse(12px 10px 12px 10px);
  clip-path: ellipse(12px 10px 12px 10px);
  background-color: white;
  border-radius: 35px;
  max-width: 220px;
  max-height: 65px;
}

.logo-index-xs-papernest {
  -webkit-clip-path: ellipse(4px 3px 4px 3px);
  clip-path: ellipse(4px 3px 4px 3px);
  background-color: white;
  border-radius: 6px;
  max-width: 80px;
  max-height: 25px;
}

.div-container-papernest {
  text-align: center;
  margin-top: 50px;
}

.link-papernest {
  display: inline-block;
  background-color: #3330e4;
  color: white;
  font-size: 18px;
  font-weight: bold;
  padding: 12px 24px;
  border-radius: 8px;
  text-decoration: none;
}

.link-secondary-papernest {
  color: #3330e4;
  text-decoration: underline;
}

.p-papernest {
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 14px;
  color: #333;
}












property-card-basic img {
  max-height: 112px;
  max-width: 168px;
}





portal-property-index a:link {
  text-decoration: none;
}

portal-property-index .img-thumbnail {
  height: 55px;
  width: 80px;
  cursor: pointer;
}

portal-property-index .img-no-thumbnail {
  height: 55px;
  width: 55px;
  cursor: pointer;
}

portal-property-index .clickable {
  cursor: pointer;
}

portal-property-index .toolbar-table {
  padding: 0;
}



.zone-embed-pdf {
  background-color: #d0cece;
  width: 100% !important;
  margin-top: 8px;
}

.embed-pdf {
  width: 100% !important;
  z-index: 99;
}

property-service-index .md-card-summary {
  text-align: center;
  background-color: #f5f5f5;
  margin-bottom: 20px;
  min-height: 150px;
  min-width: 150px;
}

property-service-index .md-toolbar-tools {
  color: white;
}

property-service-index .md-card-summary h3 {
  font-size: 24px;
  margin-bottom: 5px;
}

property-service-index .status-badge,
property-service-index .status-badge-red,
property-service-index .status-badge-yellow {
  display: inline-block;
  background-color: #3f51b5;
  color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-right: 10px;
}

property-service-index .status-badge-red {
  background-color: #c31c4a;
}

property-service-index .status-badge-yellow {
  background-color: #ff9800;
}

property-card img {
  max-height: 140px;
  max-width: 100%;
}

property-card span {
  margin: 2px 5px 0px 0px;
}

property-card-basic img {
  max-height: 112px;
  max-width: 168px;
}

property-index a:link {
  text-decoration: none;
}

property-index .img-thumbnail {
  height: 100px;
  width: 150px;
  cursor: pointer;
}

property-index .img-no-thumbnail {
  height: 55px;
  width: 55px;
  cursor: pointer;
}

property-index .toolbar-table {
  padding: 0;
}

@media screen and (max-width: 599px) {
  property-index .limit-select {
    display: none;
  }
}


property-index-map a:link {
  text-decoration: none;
}

property-index-map .img-thumbnail {
  height: auto;
  cursor: pointer;
}

property-index-map .img-no-photo {
  height: auto;
  cursor: pointer;
}

property-index-map .toolbar-table {
  padding: 0;
}

property-list-item img {
  height: 59px;
  width: 78px;
}


property-photos-upload .icon-help {
  color: #54e346 !important;
}

property-reference .td-reference {
  padding-right: 10px !important;
  width: 180px !important;
}

property-reference a:link {
  text-decoration: none;
}

property-reference .img-thumbnail {
  height: 55px;
  width: 80px;
  cursor: pointer;
}

property-reference .img-no-thumbnail {
  height: 55px;
  width: 55px;
  cursor: pointer;
}

property-reference .toolbar-table {
  padding: 0;
}



property-show-basic-client .clickable {
  cursor: pointer;
}


.grey-light-background-check-list {
  background-color: #f7efef;
  border-radius: 10px;
  min-height: 250px;
  padding: 5px;
}

.background-list-grey {
  background-color: #f7efef;
  border-radius: 10px;
  min-height: 100px;
  padding: 5px;
}

.grey-light-background-check-list {
  background-color: #f7efef;
  border-radius: 10px;
  min-height: 250px;
  padding: 5px;
}

.grey-light-background-check-list-big,
.grey-light-background-check-list-big-padding {
  background-color: #f7efef;
  border-radius: 10px;
  min-height: 750px;
  padding: 5px;
}

.grey-light-background-check-list-big-padding {
  padding: 10px !important;
}



property-show-discarded .no-clickable {
  cursor: help;
}

property-show-discarded .contact-unsubscribe-alert {
  color: red !important;
}


property-show-interested .no-clickable {
  cursor: help;
}

property-show-interested .contact-unsubscribe-alert {
  color: red !important;
}


.grey-light-min {
  background-color: #f7efef;
  border-radius: 10px;
  min-height: 80px;
  padding: 15px 10px 15px 10px;
}


.grey-light-min {
  background-color: #f7efef;
  border-radius: 10px;
  min-height: 80px;
  padding: 15px 10px 15px 10px;
}

property-show-photos img {
  height: 100%;
  width: 100%;
}



.grey-light-background-check-list {
  background-color: #f7efef;
  border-radius: 10px;
  min-height: 150px;
  padding: 15px 10px 15px 10px;
}

.grey-light-background-check-list-big {
  background-color: #f7efef;
  border-radius: 10px;
  min-height: 750px;
  padding: 5px;
}


property-show-suggested .no-clickable {
  cursor: help;
}

property-show-suggested .contact-unsubscribe-alert {
  color: red !important;
}

.grey-light-min-turism {
  background-color: #f7efef;
  border-radius: 10px;
  min-height: 50px;
  padding: 15px 10px 10px 10px;
}


.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
}

.embed-responsive.embed-responsive-16by9 {
  padding-bottom: 56.25%;
}

.embed-responsive-item {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
}

.embed-responsive.embed-responsive-16by9 {
  padding-bottom: 56.25%;
}

.embed-responsive-item {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

property-show-workflow .workflow-line {
  position: relative;
  top: 27px;
  left: 48px;
  border: solid 1px;
  border-color: black;
}

property-show-workflow .clickable {
  cursor: help;
}

property-show-workflow .md-fab-grey {
  background: grey !important;
}

property-show-workflow .label-status-workflow {
  margin-left: -30px !important;
  margin-right: 15px !important;
}

@media screen and (max-width: 599px) {
  property-show-workflow .label-status-workflow {
    margin: 0px;
  }
}

property-store .icon-help {
  color: #54e346 !important;
}

property-store .zone-top {
  height: 150px !important;
}

property-store .grey-light-background {
  background-color: #f7efef;
  border-radius: 10px;
  min-height: 250px;
}

property-store .warning-payment-procedure {
  margin: 0px 5px 0px 5px;
  padding: 2px 2px 2px 2px;
  min-width: 350px !important;
  font-weight: normal;
  font-size: 11px !important;
  border-radius: 10px 10px 10px 10px;
  background-color: #bbdefb !important;
  color: black;
}


crm-captcha-simple h1 {
  font-size: 12px !important;
  margin: 10px !important;
}

crm-captcha-simple .margin-top-captcha {
  margin-top: 10px !important;
}

.legals-unsubscribe-link {
  color: #039be5;
  font-weight: 600px;
}

.color-agency {
  color: #039be5;
}

.color-flooges {
  color: #c31c4a !important;
}

.warning-unsubscribe {
  margin: 20px 20px 20px 5px;
  padding: 20px 10px 20px 10px;
  max-height: 60px !important;
  font-weight: 600;
  border-radius: 10px 10px 10px 10px;
  background-color: #c31c4a;
  color: white;
}

.icon-user {
  height: 80px !important;
  width: 80px !important;
  border-radius: 50%;
  padding: 5px !important;
}

.user-divider {
  margin-bottom: 10px;
  border-bottom: 1px solid #DCDCDC;
}

vacation-index .img-thumbnail {
  height: 55px;
  width: 80px;
  cursor: pointer;
}

vacation-index .img-no-thumbnail {
  height: 55px;
  width: 55px;
  cursor: pointer;
}

vacation-index .toolbar-table {
  padding: 0;
}





/*# sourceMappingURL=app.css.map */
