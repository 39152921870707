collaboration-map-public #map-agencies {
  min-height: 300px !important;
  min-width: 300px !important;
}

@media screen and (max-width: 599px) {
  collaboration-map-public #map-agencies {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
}

collaboration-map-public .no-shadow-chips md-chips-wrap {
  box-shadow: none !important;
}

collaboration-map-public .collaboration-agency-list {
  background-color: #f7f3f3;
}

@media screen and (max-width: 599px) {
  collaboration-map-public .collaboration-agency-list {
    margin-top: 0px !important;
  }
}

collaboration-map-public md-card-title {
  padding: 10px 5px 5px 5px;
}

collaboration-map-public md-card-content {
  padding-bottom: 0px;
}
