/*
 * -----------------------------------------------------------------------------
 *	MARGIN
 * -----------------------------------------------------------------------------
*/
.margin-top-0 {
  margin-top: 0px !important;
}

.margin-bottom-0 {
  margin-bottom: 0px !important;
}

.margin-left-0 {
  margin-left: 0px !important;
}

.margin-right-0 {
  margin-right: 0px !important;
}

.margin-0 {
  margin: 0px !important;
}

.margin-top-1 {
  margin-top: 1px !important;
}

.margin-bottom-1 {
  margin-bottom: 1px !important;
}

.margin-left-1 {
  margin-left: 1px !important;
}

.margin-right-1 {
  margin-right: 1px !important;
}

.margin-1 {
  margin: 1px !important;
}

.margin-top-2 {
  margin-top: 2px !important;
}

.margin-bottom-2 {
  margin-bottom: 2px !important;
}

.margin-left-2 {
  margin-left: 2px !important;
}

.margin-right-2 {
  margin-right: 2px !important;
}

.margin-2 {
  margin: 2px !important;
}

.margin-top-3 {
  margin-top: 3px !important;
}

.margin-bottom-3 {
  margin-bottom: 3px !important;
}

.margin-left-3 {
  margin-left: 3px !important;
}

.margin-right-3 {
  margin-right: 3px !important;
}

.margin-3 {
  margin: 3px !important;
}

.margin-top-5 {
  margin-top: 5px !important;
}

.margin-bottom-5 {
  margin-bottom: 5px !important;
}

.margin-left-5 {
  margin-left: 5px !important;
}

.margin-right-5 {
  margin-right: 5px !important;
}

.margin-5 {
  margin: 5px !important;
}

.margin-top-8 {
  margin-top: 8px !important;
}

.margin-bottom-8 {
  margin-bottom: 8px !important;
}

.margin-left-8 {
  margin-left: 8px !important;
}

.margin-right-8 {
  margin-right: 8px !important;
}

.margin-8 {
  margin: 8px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-left-10 {
  margin-left: 10px !important;
}

.margin-right-10 {
  margin-right: 10px !important;
}

.margin-10 {
  margin: 10px !important;
}

.margin-top-15 {
  margin-top: 15px !important;
}

.margin-bottom-15 {
  margin-bottom: 15px !important;
}

.margin-left-15 {
  margin-left: 15px !important;
}

.margin-right-15 {
  margin-right: 15px !important;
}

.margin-15 {
  margin: 15px !important;
}

.margin-top-17 {
  margin-top: 17px !important;
}

.margin-bottom-17 {
  margin-bottom: 17px !important;
}

.margin-left-17 {
  margin-left: 17px !important;
}

.margin-right-17 {
  margin-right: 17px !important;
}

.margin-17 {
  margin: 17px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-left-20 {
  margin-left: 20px !important;
}

.margin-right-20 {
  margin-right: 20px !important;
}

.margin-20 {
  margin: 20px !important;
}

.margin-top-25 {
  margin-top: 25px !important;
}

.margin-bottom-25 {
  margin-bottom: 25px !important;
}

.margin-left-25 {
  margin-left: 25px !important;
}

.margin-right-25 {
  margin-right: 25px !important;
}

.margin-25 {
  margin: 25px !important;
}

.margin-top-30 {
  margin-top: 30px !important;
}

.margin-bottom-30 {
  margin-bottom: 30px !important;
}

.margin-left-30 {
  margin-left: 30px !important;
}

.margin-right-30 {
  margin-right: 30px !important;
}

.margin-30 {
  margin: 30px !important;
}

.margin-top-35 {
  margin-top: 35px !important;
}

.margin-bottom-35 {
  margin-bottom: 35px !important;
}

.margin-left-35 {
  margin-left: 35px !important;
}

.margin-right-35 {
  margin-right: 35px !important;
}

.margin-35 {
  margin: 35px !important;
}

.margin-top-40 {
  margin-top: 40px !important;
}

.margin-bottom-40 {
  margin-bottom: 40px !important;
}

.margin-left-40 {
  margin-left: 40px !important;
}

.margin-right-40 {
  margin-right: 40px !important;
}

.margin-40 {
  margin: 40px !important;
}

.margin-top-45 {
  margin-top: 45px !important;
}

.margin-bottom-45 {
  margin-bottom: 45px !important;
}

.margin-left-45 {
  margin-left: 45px !important;
}

.margin-right-45 {
  margin-right: 45px !important;
}

.margin-45 {
  margin: 45px !important;
}

.margin-top-50 {
  margin-top: 50px !important;
}

.margin-bottom-50 {
  margin-bottom: 50px !important;
}

.margin-left-50 {
  margin-left: 50px !important;
}

.margin-right-50 {
  margin-right: 50px !important;
}

.margin-50 {
  margin: 50px !important;
}

.margin-top-55 {
  margin-top: 55px !important;
}

.margin-bottom-55 {
  margin-bottom: 55px !important;
}

.margin-left-55 {
  margin-left: 55px !important;
}

.margin-right-55 {
  margin-right: 55px !important;
}

.margin-55 {
  margin: 55px !important;
}

.margin-top-60 {
  margin-top: 60px !important;
}

.margin-bottom-60 {
  margin-bottom: 60px !important;
}

.margin-left-60 {
  margin-left: 60px !important;
}

.margin-right-60 {
  margin-right: 60px !important;
}

.margin-60 {
  margin: 60px !important;
}

.margin-top-65 {
  margin-top: 65px !important;
}

.margin-bottom-65 {
  margin-bottom: 65px !important;
}

.margin-left-65 {
  margin-left: 65px !important;
}

.margin-right-65 {
  margin-right: 65px !important;
}

.margin-65 {
  margin: 65px !important;
}

.margin-top-70 {
  margin-top: 70px !important;
}

.margin-bottom-70 {
  margin-bottom: 70px !important;
}

.margin-left-70 {
  margin-left: 70px !important;
}

.margin-right-70 {
  margin-right: 70px !important;
}

.margin-70 {
  margin: 70px !important;
}

.margin-top-75 {
  margin-top: 75px !important;
}

.margin-bottom-75 {
  margin-bottom: 75px !important;
}

.margin-left-75 {
  margin-left: 75px !important;
}

.margin-right-75 {
  margin-right: 75px !important;
}

.margin-75 {
  margin: 75px !important;
}

.margin-top-80 {
  margin-top: 80px !important;
}

.margin-bottom-80 {
  margin-bottom: 80px !important;
}

.margin-left-80 {
  margin-left: 80px !important;
}

.margin-right-80 {
  margin-right: 80px !important;
}

.margin-80 {
  margin: 80px !important;
}

.margin-top-85 {
  margin-top: 85px !important;
}

.margin-bottom-85 {
  margin-bottom: 85px !important;
}

.margin-left-85 {
  margin-left: 85px !important;
}

.margin-right-85 {
  margin-right: 85px !important;
}

.margin-85 {
  margin: 85px !important;
}

.margin-top-90 {
  margin-top: 90px !important;
}

.margin-bottom-90 {
  margin-bottom: 90px !important;
}

.margin-left-90 {
  margin-left: 90px !important;
}

.margin-right-90 {
  margin-right: 90px !important;
}

.margin-90 {
  margin: 90px !important;
}

.margin-top-95 {
  margin-top: 95px !important;
}

.margin-bottom-95 {
  margin-bottom: 95px !important;
}

.margin-left-95 {
  margin-left: 95px !important;
}

.margin-right-95 {
  margin-right: 95px !important;
}

.margin-95 {
  margin: 95px !important;
}

.margin-top-100 {
  margin-top: 100px !important;
}

.margin-bottom-100 {
  margin-bottom: 100px !important;
}

.margin-left-100 {
  margin-left: 100px !important;
}

.margin-right-100 {
  margin-right: 100px !important;
}

.margin-100 {
  margin: 100px !important;
}

.margin-top-105 {
  margin-top: 105px !important;
}

.margin-bottom-105 {
  margin-bottom: 105px !important;
}

.margin-left-105 {
  margin-left: 105px !important;
}

.margin-right-105 {
  margin-right: 105px !important;
}

.margin-105 {
  margin: 105px !important;
}

.margin-top-110 {
  margin-top: 110px !important;
}

.margin-bottom-110 {
  margin-bottom: 110px !important;
}

.margin-left-110 {
  margin-left: 110px !important;
}

.margin-right-110 {
  margin-right: 110px !important;
}

.margin-110 {
  margin: 110px !important;
}

.margin-top-115 {
  margin-top: 115px !important;
}

.margin-bottom-115 {
  margin-bottom: 115px !important;
}

.margin-left-115 {
  margin-left: 115px !important;
}

.margin-right-115 {
  margin-right: 115px !important;
}

.margin-115 {
  margin: 115px !important;
}

.margin-top-120 {
  margin-top: 120px !important;
}

.margin-bottom-120 {
  margin-bottom: 120px !important;
}

.margin-left-120 {
  margin-left: 120px !important;
}

.margin-right-120 {
  margin-right: 120px !important;
}

.margin-120 {
  margin: 120px !important;
}

/*
 * -----------------------------------------------------------------------------
 *	MARGIN-LESS
 * -----------------------------------------------------------------------------
*/
.margin-less-top-0 {
  margin-top: -0px !important;
}

.margin-less-bottom-0 {
  margin-bottom: -0px !important;
}

.margin-less-left-0 {
  margin-left: -0px !important;
}

.margin-less-right-0 {
  margin-right: -0px !important;
}

.margin-less-0 {
  margin: -0px !important;
}

.margin-less-top-1 {
  margin-top: -1px !important;
}

.margin-less-bottom-1 {
  margin-bottom: -1px !important;
}

.margin-less-left-1 {
  margin-left: -1px !important;
}

.margin-less-right-1 {
  margin-right: -1px !important;
}

.margin-less-1 {
  margin: -1px !important;
}

.margin-less-top-2 {
  margin-top: -2px !important;
}

.margin-less-bottom-2 {
  margin-bottom: -2px !important;
}

.margin-less-left-2 {
  margin-left: -2px !important;
}

.margin-less-right-2 {
  margin-right: -2px !important;
}

.margin-less-2 {
  margin: -2px !important;
}

.margin-less-top-3 {
  margin-top: -3px !important;
}

.margin-less-bottom-3 {
  margin-bottom: -3px !important;
}

.margin-less-left-3 {
  margin-left: -3px !important;
}

.margin-less-right-3 {
  margin-right: -3px !important;
}

.margin-less-3 {
  margin: -3px !important;
}

.margin-less-top-5 {
  margin-top: -5px !important;
}

.margin-less-bottom-5 {
  margin-bottom: -5px !important;
}

.margin-less-left-5 {
  margin-left: -5px !important;
}

.margin-less-right-5 {
  margin-right: -5px !important;
}

.margin-less-5 {
  margin: -5px !important;
}

.margin-less-top-8 {
  margin-top: -8px !important;
}

.margin-less-bottom-8 {
  margin-bottom: -8px !important;
}

.margin-less-left-8 {
  margin-left: -8px !important;
}

.margin-less-right-8 {
  margin-right: -8px !important;
}

.margin-less-8 {
  margin: -8px !important;
}

.margin-less-top-10 {
  margin-top: -10px !important;
}

.margin-less-bottom-10 {
  margin-bottom: -10px !important;
}

.margin-less-left-10 {
  margin-left: -10px !important;
}

.margin-less-right-10 {
  margin-right: -10px !important;
}

.margin-less-10 {
  margin: -10px !important;
}

.margin-less-top-15 {
  margin-top: -15px !important;
}

.margin-less-bottom-15 {
  margin-bottom: -15px !important;
}

.margin-less-left-15 {
  margin-left: -15px !important;
}

.margin-less-right-15 {
  margin-right: -15px !important;
}

.margin-less-15 {
  margin: -15px !important;
}

.margin-less-top-17 {
  margin-top: -17px !important;
}

.margin-less-bottom-17 {
  margin-bottom: -17px !important;
}

.margin-less-left-17 {
  margin-left: -17px !important;
}

.margin-less-right-17 {
  margin-right: -17px !important;
}

.margin-less-17 {
  margin: -17px !important;
}

.margin-less-top-20 {
  margin-top: -20px !important;
}

.margin-less-bottom-20 {
  margin-bottom: -20px !important;
}

.margin-less-left-20 {
  margin-left: -20px !important;
}

.margin-less-right-20 {
  margin-right: -20px !important;
}

.margin-less-20 {
  margin: -20px !important;
}

.margin-less-top-25 {
  margin-top: -25px !important;
}

.margin-less-bottom-25 {
  margin-bottom: -25px !important;
}

.margin-less-left-25 {
  margin-left: -25px !important;
}

.margin-less-right-25 {
  margin-right: -25px !important;
}

.margin-less-25 {
  margin: -25px !important;
}

.margin-less-top-30 {
  margin-top: -30px !important;
}

.margin-less-bottom-30 {
  margin-bottom: -30px !important;
}

.margin-less-left-30 {
  margin-left: -30px !important;
}

.margin-less-right-30 {
  margin-right: -30px !important;
}

.margin-less-30 {
  margin: -30px !important;
}

.margin-less-top-35 {
  margin-top: -35px !important;
}

.margin-less-bottom-35 {
  margin-bottom: -35px !important;
}

.margin-less-left-35 {
  margin-left: -35px !important;
}

.margin-less-right-35 {
  margin-right: -35px !important;
}

.margin-less-35 {
  margin: -35px !important;
}

.margin-less-top-40 {
  margin-top: -40px !important;
}

.margin-less-bottom-40 {
  margin-bottom: -40px !important;
}

.margin-less-left-40 {
  margin-left: -40px !important;
}

.margin-less-right-40 {
  margin-right: -40px !important;
}

.margin-less-40 {
  margin: -40px !important;
}

.margin-less-top-45 {
  margin-top: -45px !important;
}

.margin-less-bottom-45 {
  margin-bottom: -45px !important;
}

.margin-less-left-45 {
  margin-left: -45px !important;
}

.margin-less-right-45 {
  margin-right: -45px !important;
}

.margin-less-45 {
  margin: -45px !important;
}

.margin-less-top-50 {
  margin-top: -50px !important;
}

.margin-less-bottom-50 {
  margin-bottom: -50px !important;
}

.margin-less-left-50 {
  margin-left: -50px !important;
}

.margin-less-right-50 {
  margin-right: -50px !important;
}

.margin-less-50 {
  margin: -50px !important;
}

.margin-less-top-55 {
  margin-top: -55px !important;
}

.margin-less-bottom-55 {
  margin-bottom: -55px !important;
}

.margin-less-left-55 {
  margin-left: -55px !important;
}

.margin-less-right-55 {
  margin-right: -55px !important;
}

.margin-less-55 {
  margin: -55px !important;
}

.margin-less-top-60 {
  margin-top: -60px !important;
}

.margin-less-bottom-60 {
  margin-bottom: -60px !important;
}

.margin-less-left-60 {
  margin-left: -60px !important;
}

.margin-less-right-60 {
  margin-right: -60px !important;
}

.margin-less-60 {
  margin: -60px !important;
}

.margin-less-top-65 {
  margin-top: -65px !important;
}

.margin-less-bottom-65 {
  margin-bottom: -65px !important;
}

.margin-less-left-65 {
  margin-left: -65px !important;
}

.margin-less-right-65 {
  margin-right: -65px !important;
}

.margin-less-65 {
  margin: -65px !important;
}

.margin-less-top-70 {
  margin-top: -70px !important;
}

.margin-less-bottom-70 {
  margin-bottom: -70px !important;
}

.margin-less-left-70 {
  margin-left: -70px !important;
}

.margin-less-right-70 {
  margin-right: -70px !important;
}

.margin-less-70 {
  margin: -70px !important;
}

.margin-less-top-75 {
  margin-top: -75px !important;
}

.margin-less-bottom-75 {
  margin-bottom: -75px !important;
}

.margin-less-left-75 {
  margin-left: -75px !important;
}

.margin-less-right-75 {
  margin-right: -75px !important;
}

.margin-less-75 {
  margin: -75px !important;
}

.margin-less-top-80 {
  margin-top: -80px !important;
}

.margin-less-bottom-80 {
  margin-bottom: -80px !important;
}

.margin-less-left-80 {
  margin-left: -80px !important;
}

.margin-less-right-80 {
  margin-right: -80px !important;
}

.margin-less-80 {
  margin: -80px !important;
}

.margin-less-top-85 {
  margin-top: -85px !important;
}

.margin-less-bottom-85 {
  margin-bottom: -85px !important;
}

.margin-less-left-85 {
  margin-left: -85px !important;
}

.margin-less-right-85 {
  margin-right: -85px !important;
}

.margin-less-85 {
  margin: -85px !important;
}

.margin-less-top-90 {
  margin-top: -90px !important;
}

.margin-less-bottom-90 {
  margin-bottom: -90px !important;
}

.margin-less-left-90 {
  margin-left: -90px !important;
}

.margin-less-right-90 {
  margin-right: -90px !important;
}

.margin-less-90 {
  margin: -90px !important;
}

.margin-less-top-95 {
  margin-top: -95px !important;
}

.margin-less-bottom-95 {
  margin-bottom: -95px !important;
}

.margin-less-left-95 {
  margin-left: -95px !important;
}

.margin-less-right-95 {
  margin-right: -95px !important;
}

.margin-less-95 {
  margin: -95px !important;
}

.margin-less-top-100 {
  margin-top: -100px !important;
}

.margin-less-bottom-100 {
  margin-bottom: -100px !important;
}

.margin-less-left-100 {
  margin-left: -100px !important;
}

.margin-less-right-100 {
  margin-right: -100px !important;
}

.margin-less-100 {
  margin: -100px !important;
}

.margin-less-top-105 {
  margin-top: -105px !important;
}

.margin-less-bottom-105 {
  margin-bottom: -105px !important;
}

.margin-less-left-105 {
  margin-left: -105px !important;
}

.margin-less-right-105 {
  margin-right: -105px !important;
}

.margin-less-105 {
  margin: -105px !important;
}

.margin-less-top-110 {
  margin-top: -110px !important;
}

.margin-less-bottom-110 {
  margin-bottom: -110px !important;
}

.margin-less-left-110 {
  margin-left: -110px !important;
}

.margin-less-right-110 {
  margin-right: -110px !important;
}

.margin-less-110 {
  margin: -110px !important;
}

.margin-less-top-115 {
  margin-top: -115px !important;
}

.margin-less-bottom-115 {
  margin-bottom: -115px !important;
}

.margin-less-left-115 {
  margin-left: -115px !important;
}

.margin-less-right-115 {
  margin-right: -115px !important;
}

.margin-less-115 {
  margin: -115px !important;
}

.margin-less-top-120 {
  margin-top: -120px !important;
}

.margin-less-bottom-120 {
  margin-bottom: -120px !important;
}

.margin-less-left-120 {
  margin-left: -120px !important;
}

.margin-less-right-120 {
  margin-right: -120px !important;
}

.margin-less-120 {
  margin: -120px !important;
}

/*
 * -----------------------------------------------------------------------------
 *	MARGIN-AUTO
 * -----------------------------------------------------------------------------
*/
.margin-auto {
  margin: auto !important;
}

/*
 * -----------------------------------------------------------------------------
 *	PADDING
 * -----------------------------------------------------------------------------
*/
.padding-top-0 {
  padding-top: 0px !important;
}

.padding-bottom-0 {
  padding-bottom: 0px !important;
}

.padding-left-0 {
  padding-left: 0px !important;
}

.padding-right-0 {
  padding-right: 0px !important;
}

.padding-0 {
  padding: 0px !important;
}

.padding-top-1 {
  padding-top: 1px !important;
}

.padding-bottom-1 {
  padding-bottom: 1px !important;
}

.padding-left-1 {
  padding-left: 1px !important;
}

.padding-right-1 {
  padding-right: 1px !important;
}

.padding-1 {
  padding: 1px !important;
}

.padding-top-2 {
  padding-top: 2px !important;
}

.padding-bottom-2 {
  padding-bottom: 2px !important;
}

.padding-left-2 {
  padding-left: 2px !important;
}

.padding-right-2 {
  padding-right: 2px !important;
}

.padding-2 {
  padding: 2px !important;
}

.padding-top-5 {
  padding-top: 5px !important;
}

.padding-bottom-5 {
  padding-bottom: 5px !important;
}

.padding-left-5 {
  padding-left: 5px !important;
}

.padding-right-5 {
  padding-right: 5px !important;
}

.padding-5 {
  padding: 5px !important;
}

.padding-top-7 {
  padding-top: 7px !important;
}

.padding-bottom-7 {
  padding-bottom: 7px !important;
}

.padding-left-7 {
  padding-left: 7px !important;
}

.padding-right-7 {
  padding-right: 7px !important;
}

.padding-7 {
  padding: 7px !important;
}

.padding-top-8 {
  padding-top: 8px !important;
}

.padding-bottom-8 {
  padding-bottom: 8px !important;
}

.padding-left-8 {
  padding-left: 8px !important;
}

.padding-right-8 {
  padding-right: 8px !important;
}

.padding-8 {
  padding: 8px !important;
}

.padding-top-10 {
  padding-top: 10px !important;
}

.padding-bottom-10 {
  padding-bottom: 10px !important;
}

.padding-left-10 {
  padding-left: 10px !important;
}

.padding-right-10 {
  padding-right: 10px !important;
}

.padding-10 {
  padding: 10px !important;
}

.padding-top-15 {
  padding-top: 15px !important;
}

.padding-bottom-15 {
  padding-bottom: 15px !important;
}

.padding-left-15 {
  padding-left: 15px !important;
}

.padding-right-15 {
  padding-right: 15px !important;
}

.padding-15 {
  padding: 15px !important;
}

.padding-top-20 {
  padding-top: 20px !important;
}

.padding-bottom-20 {
  padding-bottom: 20px !important;
}

.padding-left-20 {
  padding-left: 20px !important;
}

.padding-right-20 {
  padding-right: 20px !important;
}

.padding-20 {
  padding: 20px !important;
}

.padding-top-25 {
  padding-top: 25px !important;
}

.padding-bottom-25 {
  padding-bottom: 25px !important;
}

.padding-left-25 {
  padding-left: 25px !important;
}

.padding-right-25 {
  padding-right: 25px !important;
}

.padding-25 {
  padding: 25px !important;
}

.padding-top-30 {
  padding-top: 30px !important;
}

.padding-bottom-30 {
  padding-bottom: 30px !important;
}

.padding-left-30 {
  padding-left: 30px !important;
}

.padding-right-30 {
  padding-right: 30px !important;
}

.padding-30 {
  padding: 30px !important;
}

.padding-top-35 {
  padding-top: 35px !important;
}

.padding-bottom-35 {
  padding-bottom: 35px !important;
}

.padding-left-35 {
  padding-left: 35px !important;
}

.padding-right-35 {
  padding-right: 35px !important;
}

.padding-35 {
  padding: 35px !important;
}

.padding-top-40 {
  padding-top: 40px !important;
}

.padding-bottom-40 {
  padding-bottom: 40px !important;
}

.padding-left-40 {
  padding-left: 40px !important;
}

.padding-right-40 {
  padding-right: 40px !important;
}

.padding-40 {
  padding: 40px !important;
}

.padding-top-45 {
  padding-top: 45px !important;
}

.padding-bottom-45 {
  padding-bottom: 45px !important;
}

.padding-left-45 {
  padding-left: 45px !important;
}

.padding-right-45 {
  padding-right: 45px !important;
}

.padding-45 {
  padding: 45px !important;
}

.padding-top-50 {
  padding-top: 50px !important;
}

.padding-bottom-50 {
  padding-bottom: 50px !important;
}

.padding-left-50 {
  padding-left: 50px !important;
}

.padding-right-50 {
  padding-right: 50px !important;
}

.padding-50 {
  padding: 50px !important;
}

.padding-top-55 {
  padding-top: 55px !important;
}

.padding-bottom-55 {
  padding-bottom: 55px !important;
}

.padding-left-55 {
  padding-left: 55px !important;
}

.padding-right-55 {
  padding-right: 55px !important;
}

.padding-55 {
  padding: 55px !important;
}

.padding-top-60 {
  padding-top: 60px !important;
}

.padding-bottom-60 {
  padding-bottom: 60px !important;
}

.padding-left-60 {
  padding-left: 60px !important;
}

.padding-right-60 {
  padding-right: 60px !important;
}

.padding-60 {
  padding: 60px !important;
}

.padding-top-65 {
  padding-top: 65px !important;
}

.padding-bottom-65 {
  padding-bottom: 65px !important;
}

.padding-left-65 {
  padding-left: 65px !important;
}

.padding-right-65 {
  padding-right: 65px !important;
}

.padding-65 {
  padding: 65px !important;
}

.padding-top-70 {
  padding-top: 70px !important;
}

.padding-bottom-70 {
  padding-bottom: 70px !important;
}

.padding-left-70 {
  padding-left: 70px !important;
}

.padding-right-70 {
  padding-right: 70px !important;
}

.padding-70 {
  padding: 70px !important;
}

.padding-top-75 {
  padding-top: 75px !important;
}

.padding-bottom-75 {
  padding-bottom: 75px !important;
}

.padding-left-75 {
  padding-left: 75px !important;
}

.padding-right-75 {
  padding-right: 75px !important;
}

.padding-75 {
  padding: 75px !important;
}

.padding-top-80 {
  padding-top: 80px !important;
}

.padding-bottom-80 {
  padding-bottom: 80px !important;
}

.padding-left-80 {
  padding-left: 80px !important;
}

.padding-right-80 {
  padding-right: 80px !important;
}

.padding-80 {
  padding: 80px !important;
}

.padding-top-85 {
  padding-top: 85px !important;
}

.padding-bottom-85 {
  padding-bottom: 85px !important;
}

.padding-left-85 {
  padding-left: 85px !important;
}

.padding-right-85 {
  padding-right: 85px !important;
}

.padding-85 {
  padding: 85px !important;
}

.padding-top-90 {
  padding-top: 90px !important;
}

.padding-bottom-90 {
  padding-bottom: 90px !important;
}

.padding-left-90 {
  padding-left: 90px !important;
}

.padding-right-90 {
  padding-right: 90px !important;
}

.padding-90 {
  padding: 90px !important;
}

.padding-top-95 {
  padding-top: 95px !important;
}

.padding-bottom-95 {
  padding-bottom: 95px !important;
}

.padding-left-95 {
  padding-left: 95px !important;
}

.padding-right-95 {
  padding-right: 95px !important;
}

.padding-95 {
  padding: 95px !important;
}

.padding-top-100 {
  padding-top: 100px !important;
}

.padding-bottom-100 {
  padding-bottom: 100px !important;
}

.padding-left-100 {
  padding-left: 100px !important;
}

.padding-right-100 {
  padding-right: 100px !important;
}

.padding-100 {
  padding: 100px !important;
}

.padding-top-105 {
  padding-top: 105px !important;
}

.padding-bottom-105 {
  padding-bottom: 105px !important;
}

.padding-left-105 {
  padding-left: 105px !important;
}

.padding-right-105 {
  padding-right: 105px !important;
}

.padding-105 {
  padding: 105px !important;
}

.padding-top-110 {
  padding-top: 110px !important;
}

.padding-bottom-110 {
  padding-bottom: 110px !important;
}

.padding-left-110 {
  padding-left: 110px !important;
}

.padding-right-110 {
  padding-right: 110px !important;
}

.padding-110 {
  padding: 110px !important;
}

.padding-top-115 {
  padding-top: 115px !important;
}

.padding-bottom-115 {
  padding-bottom: 115px !important;
}

.padding-left-115 {
  padding-left: 115px !important;
}

.padding-right-115 {
  padding-right: 115px !important;
}

.padding-115 {
  padding: 115px !important;
}

.padding-top-120 {
  padding-top: 120px !important;
}

.padding-bottom-120 {
  padding-bottom: 120px !important;
}

.padding-left-120 {
  padding-left: 120px !important;
}

.padding-right-120 {
  padding-right: 120px !important;
}

.padding-120 {
  padding: 120px !important;
}

/*
 * -----------------------------------------------------------------------------
 *	POSITION
 * -----------------------------------------------------------------------------
*/
.top-0 {
  top: 0px !important;
}

.bottom-0 {
  bottom: 0px !important;
}

.left-0 {
  left: 0px !important;
}

.right-0 {
  right: 0px !important;
}

.top-2 {
  top: 2px !important;
}

.bottom-2 {
  bottom: 2px !important;
}

.left-2 {
  left: 2px !important;
}

.right-2 {
  right: 2px !important;
}

.top-5 {
  top: 5px !important;
}

.bottom-5 {
  bottom: 5px !important;
}

.left-5 {
  left: 5px !important;
}

.right-5 {
  right: 5px !important;
}

.top-8 {
  top: 8px !important;
}

.bottom-8 {
  bottom: 8px !important;
}

.left-8 {
  left: 8px !important;
}

.right-8 {
  right: 8px !important;
}

.top-10 {
  top: 10px !important;
}

.bottom-10 {
  bottom: 10px !important;
}

.left-10 {
  left: 10px !important;
}

.right-10 {
  right: 10px !important;
}

.top-15 {
  top: 15px !important;
}

.bottom-15 {
  bottom: 15px !important;
}

.left-15 {
  left: 15px !important;
}

.right-15 {
  right: 15px !important;
}

.top-20 {
  top: 20px !important;
}

.bottom-20 {
  bottom: 20px !important;
}

.left-20 {
  left: 20px !important;
}

.right-20 {
  right: 20px !important;
}

.top-25 {
  top: 25px !important;
}

.bottom-25 {
  bottom: 25px !important;
}

.left-25 {
  left: 25px !important;
}

.right-25 {
  right: 25px !important;
}

.top-30 {
  top: 30px !important;
}

.bottom-30 {
  bottom: 30px !important;
}

.left-30 {
  left: 30px !important;
}

.right-30 {
  right: 30px !important;
}

.top-40 {
  top: 40px !important;
}

.bottom-40 {
  bottom: 40px !important;
}

.left-40 {
  left: 40px !important;
}

.right-40 {
  right: 40px !important;
}

.top-45 {
  top: 45px !important;
}

.bottom-45 {
  bottom: 45px !important;
}

.left-45 {
  left: 45px !important;
}

.right-45 {
  right: 45px !important;
}

.top-50 {
  top: 50px !important;
}

.bottom-50 {
  bottom: 50px !important;
}

.left-50 {
  left: 50px !important;
}

.right-50 {
  right: 50px !important;
}

.top-55 {
  top: 55px !important;
}

.bottom-55 {
  bottom: 55px !important;
}

.left-55 {
  left: 55px !important;
}

.right-55 {
  right: 55px !important;
}

.top-60 {
  top: 60px !important;
}

.bottom-60 {
  bottom: 60px !important;
}

.left-60 {
  left: 60px !important;
}

.right-60 {
  right: 60px !important;
}

.top-65 {
  top: 65px !important;
}

.bottom-65 {
  bottom: 65px !important;
}

.left-65 {
  left: 65px !important;
}

.right-65 {
  right: 65px !important;
}

.top-70 {
  top: 70px !important;
}

.bottom-70 {
  bottom: 70px !important;
}

.left-70 {
  left: 70px !important;
}

.right-70 {
  right: 70px !important;
}

.top-75 {
  top: 75px !important;
}

.bottom-75 {
  bottom: 75px !important;
}

.left-75 {
  left: 75px !important;
}

.right-75 {
  right: 75px !important;
}

.top-80 {
  top: 80px !important;
}

.bottom-80 {
  bottom: 80px !important;
}

.left-80 {
  left: 80px !important;
}

.right-80 {
  right: 80px !important;
}

.top-85 {
  top: 85px !important;
}

.bottom-85 {
  bottom: 85px !important;
}

.left-85 {
  left: 85px !important;
}

.right-85 {
  right: 85px !important;
}

.top-90 {
  top: 90px !important;
}

.bottom-90 {
  bottom: 90px !important;
}

.left-90 {
  left: 90px !important;
}

.right-90 {
  right: 90px !important;
}

.top-95 {
  top: 95px !important;
}

.bottom-95 {
  bottom: 95px !important;
}

.left-95 {
  left: 95px !important;
}

.right-95 {
  right: 95px !important;
}

.top-100 {
  top: 100px !important;
}

.bottom-100 {
  bottom: 100px !important;
}

.left-100 {
  left: 100px !important;
}

.right-100 {
  right: 100px !important;
}

.top-105 {
  top: 105px !important;
}

.bottom-105 {
  bottom: 105px !important;
}

.left-105 {
  left: 105px !important;
}

.right-105 {
  right: 105px !important;
}

.top-110 {
  top: 110px !important;
}

.bottom-110 {
  bottom: 110px !important;
}

.left-110 {
  left: 110px !important;
}

.right-110 {
  right: 110px !important;
}

.top-115 {
  top: 115px !important;
}

.bottom-115 {
  bottom: 115px !important;
}

.left-115 {
  left: 115px !important;
}

.right-115 {
  right: 115px !important;
}

.top-120 {
  top: 120px !important;
}

.bottom-120 {
  bottom: 120px !important;
}

.left-120 {
  left: 120px !important;
}

.right-120 {
  right: 120px !important;
}

/*
 * -----------------------------------------------------------------------------
 *	SIZE
 * -----------------------------------------------------------------------------
*/
.font-size-5 {
  font-size: 5px !important;
  min-height: 5px !important;
  height: 5px !important;
  min-width: 5px !important;
  width: 5px !important;
}

.font-size-6 {
  font-size: 6px !important;
  min-height: 6px !important;
  height: 6px !important;
  min-width: 6px !important;
  width: 6px !important;
}

.font-size-8 {
  font-size: 8px !important;
  min-height: 8px !important;
  height: 8px !important;
  min-width: 8px !important;
  width: 8px !important;
}

.font-size-10 {
  font-size: 10px !important;
  min-height: 10px !important;
  height: 10px !important;
  min-width: 10px !important;
  width: 10px !important;
}

.font-size-11 {
  font-size: 11px !important;
  min-height: 11px !important;
  height: 11px !important;
  min-width: 11px !important;
  width: 11px !important;
}

.font-size-12 {
  font-size: 12px !important;
  min-height: 12px !important;
  height: 12px !important;
  min-width: 12px !important;
  width: 12px !important;
}

.font-size-14 {
  font-size: 14px !important;
  min-height: 14px !important;
  height: 14px !important;
  min-width: 14px !important;
  width: 14px !important;
}

.font-size-16 {
  font-size: 16px !important;
  min-height: 16px !important;
  height: 16px !important;
  min-width: 16px !important;
  width: 16px !important;
}

.font-size-18 {
  font-size: 18px !important;
  min-height: 18px !important;
  height: 18px !important;
  min-width: 18px !important;
  width: 18px !important;
}

.font-size-20 {
  font-size: 20px !important;
  min-height: 20px !important;
  height: 20px !important;
  min-width: 20px !important;
  width: 20px !important;
}

.font-size-22 {
  font-size: 22px !important;
  min-height: 22px !important;
  height: 22px !important;
  min-width: 22px !important;
  width: 22px !important;
}

.font-size-24 {
  font-size: 24px !important;
  min-height: 24px !important;
  height: 24px !important;
  min-width: 24px !important;
  width: 24px !important;
}

.font-size-36 {
  font-size: 36px !important;
  min-height: 36px !important;
  height: 36px !important;
  min-width: 36px !important;
  width: 36px !important;
}

.font-size-38 {
  font-size: 38px !important;
  min-height: 38px !important;
  height: 38px !important;
  min-width: 38px !important;
  width: 38px !important;
}

.font-size-40 {
  font-size: 40px !important;
  min-height: 40px !important;
  height: 40px !important;
  min-width: 40px !important;
  width: 40px !important;
}

.font-size-42 {
  font-size: 42px !important;
  min-height: 42px !important;
  height: 42px !important;
  min-width: 42px !important;
  width: 42px !important;
}

.font-size-46 {
  font-size: 46px !important;
  min-height: 46px !important;
  height: 46px !important;
  min-width: 46px !important;
  width: 46px !important;
}

.font-size-48 {
  font-size: 48px !important;
  min-height: 48px !important;
  height: 48px !important;
  min-width: 48px !important;
  width: 48px !important;
}

.font-size-54 {
  font-size: 54px !important;
  min-height: 54px !important;
  height: 54px !important;
  min-width: 54px !important;
  width: 54px !important;
}

.font-size-64 {
  font-size: 64px !important;
  min-height: 64px !important;
  height: 64px !important;
  min-width: 64px !important;
  width: 64px !important;
}

.font-size-72 {
  font-size: 72px !important;
  min-height: 72px !important;
  height: 72px !important;
  min-width: 72px !important;
  width: 72px !important;
}

.font-size-84 {
  font-size: 84px !important;
  min-height: 84px !important;
  height: 84px !important;
  min-width: 84px !important;
  width: 84px !important;
}

.font-size-96 {
  font-size: 96px !important;
  min-height: 96px !important;
  height: 96px !important;
  min-width: 96px !important;
  width: 96px !important;
}

.font-size-112 {
  font-size: 112px !important;
  min-height: 112px !important;
  height: 112px !important;
  min-width: 112px !important;
  width: 112px !important;
}

/*
 * -----------------------------------------------------------------------------
 *	ICONOS SIZE
 * -----------------------------------------------------------------------------
*/
.icon-5 {
  height: 5% !important;
  width: 5% !important;
}

@media screen and (min-width: 600px) {
  .icon-5 {
    margin-left: -10px;
  }
}

.icon-10 {
  height: 10% !important;
  width: 10% !important;
}

@media screen and (min-width: 600px) {
  .icon-10 {
    margin-left: -10px;
  }
}

.icon-15 {
  height: 15% !important;
  width: 15% !important;
}

@media screen and (min-width: 600px) {
  .icon-15 {
    margin-left: -10px;
  }
}

.icon-20 {
  height: 20% !important;
  width: 20% !important;
}

@media screen and (min-width: 600px) {
  .icon-20 {
    margin-left: -10px;
  }
}

.icon-25 {
  height: 25% !important;
  width: 25% !important;
}

@media screen and (min-width: 600px) {
  .icon-25 {
    margin-left: -10px;
  }
}

.icon-30 {
  height: 30% !important;
  width: 30% !important;
}

@media screen and (min-width: 600px) {
  .icon-30 {
    margin-left: -10px;
  }
}

.icon-35 {
  height: 35% !important;
  width: 35% !important;
}

@media screen and (min-width: 600px) {
  .icon-35 {
    margin-left: -10px;
  }
}

.icon-40 {
  height: 40% !important;
  width: 40% !important;
}

@media screen and (min-width: 600px) {
  .icon-40 {
    margin-left: -10px;
  }
}

.icon-42 {
  height: 42% !important;
  width: 42% !important;
}

@media screen and (min-width: 600px) {
  .icon-42 {
    margin-left: -10px;
  }
}

.icon-45 {
  height: 45% !important;
  width: 45% !important;
}

@media screen and (min-width: 600px) {
  .icon-45 {
    margin-left: -10px;
  }
}

.icon-50 {
  height: 50% !important;
  width: 50% !important;
}

@media screen and (min-width: 600px) {
  .icon-50 {
    margin-left: -10px;
  }
}

.icon-55 {
  height: 55% !important;
  width: 55% !important;
}

@media screen and (min-width: 600px) {
  .icon-55 {
    margin-left: -10px;
  }
}

.icon-60 {
  height: 60% !important;
  width: 60% !important;
}

@media screen and (min-width: 600px) {
  .icon-60 {
    margin-left: -10px;
  }
}

.icon-65 {
  height: 65% !important;
  width: 65% !important;
}

@media screen and (min-width: 600px) {
  .icon-65 {
    margin-left: -10px;
  }
}

.icon-70 {
  height: 70% !important;
  width: 70% !important;
}

@media screen and (min-width: 600px) {
  .icon-70 {
    margin-left: -10px;
  }
}

.icon-75 {
  height: 75% !important;
  width: 75% !important;
}

@media screen and (min-width: 600px) {
  .icon-75 {
    margin-left: -10px;
  }
}

.icon-80 {
  height: 80% !important;
  width: 80% !important;
}

@media screen and (min-width: 600px) {
  .icon-80 {
    margin-left: -10px;
  }
}

.icon-85 {
  height: 85% !important;
  width: 85% !important;
}

@media screen and (min-width: 600px) {
  .icon-85 {
    margin-left: -10px;
  }
}

.icon-90 {
  height: 90% !important;
  width: 90% !important;
}

@media screen and (min-width: 600px) {
  .icon-90 {
    margin-left: -10px;
  }
}

.icon-95 {
  height: 95% !important;
  width: 95% !important;
}

@media screen and (min-width: 600px) {
  .icon-95 {
    margin-left: -10px;
  }
}

.icon-100 {
  height: 100% !important;
  width: 100% !important;
}

@media screen and (min-width: 600px) {
  .icon-100 {
    margin-left: -10px;
  }
}

.icon-110 {
  height: 110% !important;
  width: 110% !important;
}

@media screen and (min-width: 600px) {
  .icon-110 {
    margin-left: -10px;
  }
}

.icon-120 {
  height: 120% !important;
  width: 120% !important;
}

@media screen and (min-width: 600px) {
  .icon-120 {
    margin-left: -10px;
  }
}

.icon-130 {
  height: 130% !important;
  width: 130% !important;
}

@media screen and (min-width: 600px) {
  .icon-130 {
    margin-left: -10px;
  }
}

.icon-140 {
  height: 140% !important;
  width: 140% !important;
}

@media screen and (min-width: 600px) {
  .icon-140 {
    margin-left: -10px;
  }
}

.icon-150 {
  height: 150% !important;
  width: 150% !important;
}

@media screen and (min-width: 600px) {
  .icon-150 {
    margin-left: -10px;
  }
}

.icon-180 {
  height: 180% !important;
  width: 180% !important;
}

@media screen and (min-width: 600px) {
  .icon-180 {
    margin-left: -10px;
  }
}

.icon-200 {
  height: 200% !important;
  width: 200% !important;
}

@media screen and (min-width: 600px) {
  .icon-200 {
    margin-left: -10px;
  }
}

.icon-210 {
  height: 210% !important;
  width: 210% !important;
}

@media screen and (min-width: 600px) {
  .icon-210 {
    margin-left: -10px;
  }
}

.icon-220 {
  height: 220% !important;
  width: 220% !important;
}

@media screen and (min-width: 600px) {
  .icon-220 {
    margin-left: -10px;
  }
}

.icon-230 {
  height: 230% !important;
  width: 230% !important;
}

@media screen and (min-width: 600px) {
  .icon-230 {
    margin-left: -10px;
  }
}

.icon-240 {
  height: 240% !important;
  width: 240% !important;
}

@media screen and (min-width: 600px) {
  .icon-240 {
    margin-left: -10px;
  }
}

.icon-250 {
  height: 250% !important;
  width: 250% !important;
}

@media screen and (min-width: 600px) {
  .icon-250 {
    margin-left: -10px;
  }
}

.icon-280 {
  height: 280% !important;
  width: 280% !important;
}

@media screen and (min-width: 600px) {
  .icon-280 {
    margin-left: -10px;
  }
}

.icon-300 {
  height: 300% !important;
  width: 300% !important;
}

@media screen and (min-width: 600px) {
  .icon-300 {
    margin-left: -10px;
  }
}

.icon-350 {
  height: 350% !important;
  width: 350% !important;
}

@media screen and (min-width: 600px) {
  .icon-350 {
    margin-left: -10px;
  }
}

.icon-400 {
  height: 400% !important;
  width: 400% !important;
}

@media screen and (min-width: 600px) {
  .icon-400 {
    margin-left: -10px;
  }
}

/*
 * -----------------------------------------------------------------------------
 *	WIDTH
 * -----------------------------------------------------------------------------
*/
.width-5 {
  width: 5% !important;
}

.width-10 {
  width: 10% !important;
}

.width-15 {
  width: 15% !important;
}

.width-20 {
  width: 20% !important;
}

.width-25 {
  width: 25% !important;
}

.width-30 {
  width: 30% !important;
}

.width-35 {
  width: 35% !important;
}

.width-40 {
  width: 40% !important;
}

.width-42 {
  width: 42% !important;
}

.width-45 {
  width: 45% !important;
}

.width-50 {
  width: 50% !important;
}

.width-55 {
  width: 55% !important;
}

.width-60 {
  width: 60% !important;
}

.width-65 {
  width: 65% !important;
}

.width-70 {
  width: 70% !important;
}

.width-75 {
  width: 75% !important;
}

.width-80 {
  width: 80% !important;
}

.width-85 {
  width: 85% !important;
}

.width-90 {
  width: 90% !important;
}

.width-95 {
  width: 95% !important;
}

.width-100 {
  width: 100% !important;
}

.width-110 {
  width: 110% !important;
}

.width-120 {
  width: 120% !important;
}

.width-130 {
  width: 130% !important;
}

.width-140 {
  width: 140% !important;
}

.width-150 {
  width: 150% !important;
}

.width-180 {
  width: 180% !important;
}

.width-200 {
  width: 200% !important;
}

.width-210 {
  width: 210% !important;
}

.width-220 {
  width: 220% !important;
}

.width-230 {
  width: 230% !important;
}

.width-240 {
  width: 240% !important;
}

.width-250 {
  width: 250% !important;
}

.width-280 {
  width: 280% !important;
}

.width-300 {
  width: 300% !important;
}

.width-350 {
  width: 350% !important;
}

.width-400 {
  width: 400% !important;
}

.width-normal-5 {
  width: 5px !important;
}

.width-normal-10 {
  width: 10px !important;
}

.width-normal-15 {
  width: 15px !important;
}

.width-normal-20 {
  width: 20px !important;
}

.width-normal-25 {
  width: 25px !important;
}

.width-normal-30 {
  width: 30px !important;
}

.width-normal-35 {
  width: 35px !important;
}

.width-normal-40 {
  width: 40px !important;
}

.width-normal-42 {
  width: 42px !important;
}

.width-normal-45 {
  width: 45px !important;
}

.width-normal-50 {
  width: 50px !important;
}

.width-normal-55 {
  width: 55px !important;
}

.width-normal-60 {
  width: 60px !important;
}

.width-normal-65 {
  width: 65px !important;
}

.width-normal-70 {
  width: 70px !important;
}

.width-normal-75 {
  width: 75px !important;
}

.width-normal-80 {
  width: 80px !important;
}

.width-normal-85 {
  width: 85px !important;
}

.width-normal-90 {
  width: 90px !important;
}

.width-normal-95 {
  width: 95px !important;
}

.width-normal-100 {
  width: 100px !important;
}

.width-normal-110 {
  width: 110px !important;
}

.width-normal-120 {
  width: 120px !important;
}

.width-normal-130 {
  width: 130px !important;
}

.width-normal-140 {
  width: 140px !important;
}

.width-normal-150 {
  width: 150px !important;
}

.width-normal-180 {
  width: 180px !important;
}

.width-normal-200 {
  width: 200px !important;
}

.width-normal-210 {
  width: 210px !important;
}

.width-normal-220 {
  width: 220px !important;
}

.width-normal-230 {
  width: 230px !important;
}

.width-normal-240 {
  width: 240px !important;
}

.width-normal-250 {
  width: 250px !important;
}

.width-normal-280 {
  width: 280px !important;
}

.width-normal-300 {
  width: 300px !important;
}

.width-normal-350 {
  width: 350px !important;
}

.width-normal-400 {
  width: 400px !important;
}

/*
 * -----------------------------------------------------------------------------
 *	HEIGHT
 * -----------------------------------------------------------------------------
*/
.height-5 {
  height: 5% !important;
}

.height-10 {
  height: 10% !important;
}

.height-15 {
  height: 15% !important;
}

.height-20 {
  height: 20% !important;
}

.height-25 {
  height: 25% !important;
}

.height-30 {
  height: 30% !important;
}

.height-35 {
  height: 35% !important;
}

.height-40 {
  height: 40% !important;
}

.height-42 {
  height: 42% !important;
}

.height-45 {
  height: 45% !important;
}

.height-50 {
  height: 50% !important;
}

.height-55 {
  height: 55% !important;
}

.height-60 {
  height: 60% !important;
}

.height-65 {
  height: 65% !important;
}

.height-70 {
  height: 70% !important;
}

.height-75 {
  height: 75% !important;
}

.height-80 {
  height: 80% !important;
}

.height-85 {
  height: 85% !important;
}

.height-90 {
  height: 90% !important;
}

.height-95 {
  height: 95% !important;
}

.height-100 {
  height: 100% !important;
}

.height-110 {
  height: 110% !important;
}

.height-120 {
  height: 120% !important;
}

.height-130 {
  height: 130% !important;
}

.height-140 {
  height: 140% !important;
}

.height-150 {
  height: 150% !important;
}

.height-180 {
  height: 180% !important;
}

.height-200 {
  height: 200% !important;
}

.height-210 {
  height: 210% !important;
}

.height-220 {
  height: 220% !important;
}

.height-230 {
  height: 230% !important;
}

.height-240 {
  height: 240% !important;
}

.height-250 {
  height: 250% !important;
}

.height-280 {
  height: 280% !important;
}

.height-300 {
  height: 300% !important;
}

.height-350 {
  height: 350% !important;
}

.height-400 {
  height: 400% !important;
}

.height-450 {
  height: 450% !important;
}

.height-500 {
  height: 500% !important;
}

.height-550 {
  height: 550% !important;
}

.height-600 {
  height: 600% !important;
}

.height-normal-5 {
  height: 5px !important;
}

.height-normal-10 {
  height: 10px !important;
}

.height-normal-15 {
  height: 15px !important;
}

.height-normal-20 {
  height: 20px !important;
}

.height-normal-25 {
  height: 25px !important;
}

.height-normal-30 {
  height: 30px !important;
}

.height-normal-35 {
  height: 35px !important;
}

.height-normal-40 {
  height: 40px !important;
}

.height-normal-42 {
  height: 42px !important;
}

.height-normal-45 {
  height: 45px !important;
}

.height-normal-50 {
  height: 50px !important;
}

.height-normal-55 {
  height: 55px !important;
}

.height-normal-60 {
  height: 60px !important;
}

.height-normal-65 {
  height: 65px !important;
}

.height-normal-70 {
  height: 70px !important;
}

.height-normal-75 {
  height: 75px !important;
}

.height-normal-80 {
  height: 80px !important;
}

.height-normal-85 {
  height: 85px !important;
}

.height-normal-90 {
  height: 90px !important;
}

.height-normal-95 {
  height: 95px !important;
}

.height-normal-100 {
  height: 100px !important;
}

.height-normal-110 {
  height: 110px !important;
}

.height-normal-120 {
  height: 120px !important;
}

.height-normal-130 {
  height: 130px !important;
}

.height-normal-140 {
  height: 140px !important;
}

.height-normal-150 {
  height: 150px !important;
}

.height-normal-180 {
  height: 180px !important;
}

.height-normal-200 {
  height: 200px !important;
}

.height-normal-210 {
  height: 210px !important;
}

.height-normal-220 {
  height: 220px !important;
}

.height-normal-230 {
  height: 230px !important;
}

.height-normal-240 {
  height: 240px !important;
}

.height-normal-250 {
  height: 250px !important;
}

.height-normal-280 {
  height: 280px !important;
}

.height-normal-300 {
  height: 300px !important;
}

.height-normal-350 {
  height: 350px !important;
}

.height-normal-400 {
  height: 400px !important;
}

.height-normal-450 {
  height: 450px !important;
}

.height-normal-500 {
  height: 500px !important;
}

.height-normal-550 {
  height: 550px !important;
}

.height-normal-600 {
  height: 600px !important;
}

/*
 * -----------------------------------------------------------------------------
 *	FONT-WEIGHT
 * -----------------------------------------------------------------------------
*/
.font-weight-0 {
  font-weight: 0 !important;
}

.font-weight-100 {
  font-weight: 100 !important;
}

.font-weight-200 {
  font-weight: 200 !important;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-weight-800 {
  font-weight: 800 !important;
}

.font-weight-900 {
  font-weight: 900 !important;
}

/*
 * -----------------------------------------------------------------------------
 *	ALIGN
 * -----------------------------------------------------------------------------
*/
.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-underline {
  text-decoration: underline !important;
}

.text-decoration-none {
  text-decoration: none;
}

/*
 * -----------------------------------------------------------------------------
 *	MOBILE TEXT ALIGN
 * -----------------------------------------------------------------------------
*/
.mobile-text-justify {
  text-align: center;
}

@media screen and (max-width: 600px) {
  .mobile-text-justify {
    text-align: justify !important;
  }
}

.mobile-text-center {
  text-align: justify;
}

@media screen and (max-width: 600px) {
  .mobile-text-center {
    text-align: center !important;
  }
}

.mobile-text-center-center {
  text-align: center;
}

@media screen and (max-width: 600px) {
  .mobile-text-center-center {
    text-align: center !important;
  }
}

.mobile-text-justify-left {
  text-align: justify;
}

@media screen and (max-width: 600px) {
  .mobile-text-justify-left {
    text-align: left !important;
  }
}

.mobile-text-center-left {
  text-align: center;
}

@media screen and (max-width: 600px) {
  .mobile-text-center-left {
    text-align: left !important;
  }
}

/*
 * -----------------------------------------------------------------------------
 *	BACKGROUND
 * -----------------------------------------------------------------------------
*/
/* BACKGROUND COLOR */
.kw-background-yellow {
  background: #ffc107 !important;
}

.kw-background-orange {
  background: #ff9800 !important;
}

.kw-background-green {
  background: #4caf50 !important;
}

.kw-background-collaborator {
  background: #bbdefb !important;
}

.kw-background-blue {
  background: #3330e4 !important;
}

.kw-background-grey {
  background: #77777a !important;
}

.kw-background-grey-light {
  background: #77777a36 !important;
}

.kw-background-black {
  background: black !important;
}

.kw-background-red {
  background: #c31c4a !important;
}

.kw-background-light-pink {
  background: #ffd3d3 !important;
}

.kw-background-purple {
  background: #c31c4a !important;
}

/*
 * -----------------------------------------------------------------------------
 *	LABEL
 * -----------------------------------------------------------------------------
*/
.kw-label, .kw-label-yellow, .kw-label-orange, .kw-label-green, .kw-label-collaborator, .kw-label-blue, .kw-label-grey, .kw-label-black, .kw-label-red, .kw-label-light-pink, .kw-label-pink, .kw-label-purple, .kw-label-gold {
  color: white;
  font-size: 100%;
  padding: 2px 8px;
  border-radius: 4px;
}

/* LABEL COLOR */
.kw-label-yellow {
  background: #ffc107 !important;
}

.kw-label-orange {
  background: #ff9800 !important;
}

.kw-label-green {
  background: #4caf50 !important;
}

.kw-label-collaborator {
  background: #bbdefb !important;
}

.kw-label-blue {
  background: #3330e4 !important;
}

.kw-label-grey {
  background: #77777a !important;
}

.kw-label-black {
  background: black !important;
}

.kw-label-red {
  background: #c31c4a !important;
}

.kw-label-light-pink {
  background: #ffd3d3 !important;
}

.kw-label-pink {
  background: #c31c4a !important;
}

.kw-label-purple {
  background: #c31c4a !important;
}

.kw-label-gold {
  background: gold !important;
}

/* LABEL CAPTION */
.kw-label-caption, .kw-label-caption-yellow, .kw-label-caption-orange, .kw-label-caption-green, .kw-label-caption-blue, .kw-label-caption-grey, .kw-label-caption-black, .kw-label-caption-red {
  color: white;
  font-size: 60%;
  padding: 2px 4px;
  border-radius: 4px;
}

.kw-label-caption-yellow {
  color: black;
  background: #ffc107 !important;
}

.kw-label-caption-orange {
  background: #ff9800 !important;
}

.kw-label-caption-green {
  background: #4caf50 !important;
}

.kw-label-caption-blue {
  background: #3330e4 !important;
}

.kw-label-caption-grey {
  background: #77777a !important;
}

.kw-label-caption-black {
  background: black !important;
}

.kw-label-caption-red {
  background: #c31c4a !important;
}

/* LABEL PORTAL */
.kw-label-portal, .kw-label-adaix-blue-team, .kw-label-adaix-blue, .kw-label-flooges-red, .kw-label-casatoc-red, .kw-label-myplaze-green {
  color: white !important;
  font-weight: 600 !important;
  padding: 2px 4px !important;
  border-radius: 4px !important;
}

.kw-label-adaix-blue-team {
  background: #2c78b0 !important;
}

.kw-label-adaix-blue {
  background: #0f044c !important;
}

.kw-label-flooges-red {
  background: #c31c4a !important;
}

.kw-label-casatoc-red {
  background: #f73636 !important;
}

.kw-label-myplaze-green {
  background: #0b967f !important;
}

/*
 * -----------------------------------------------------------------------------
 *	BADGE
 * -----------------------------------------------------------------------------
*/
.kw-badge,
.kw-badge {
  position: relative;
  white-space: nowrap;
  margin-right: 24px;
}

.kw-badge:not([data-badge]),
.kw-badge:not([data-badge]) {
  margin-right: auto;
}

.kw-badge[data-badge]:after,
.kw-badge[data-badge]:after {
  content: attr(data-badge);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: absolute;
  top: -11px;
  right: -24px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-size: 12px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #3330e4;
  color: #fff;
}

.kw-button .kw-badge[data-badge]:after, .kw-button
.kw-badge[data-badge]:after {
  top: -10px;
  right: -5px;
}

.kw-badge.kw-badge--no-background[data-badge]:after,
.kw-badge.kw-badge--no-background[data-badge]:after {
  color: #3330e4;
  background: #fff;
  box-shadow: 0 0 1px gray;
}

.kw-badge.kw-badge--overlap,
.kw-badge.kw-badge--overlap {
  margin-right: 10px;
}

.kw-badge.kw-badge--overlap:after,
.kw-badge.kw-badge--overlap:after {
  right: -10px;
}

.kw-badge-yellow[data-badge]:after {
  background: #e3b037;
}

.kw-badge-yellow.kw-badge--no-background[data-badge]:after {
  color: #e3b037 !important;
}

.kw-badge-green[data-badge]:after {
  background: #54e346;
}

.kw-badge-green.kw-badge--no-background[data-badge]:after {
  color: #54e346 !important;
}

/*
 * -----------------------------------------------------------------------------
 *	AVATAR IMG
 * -----------------------------------------------------------------------------
*/
.kw-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-sizing: content-box;
}

/*
 * -----------------------------------------------------------------------------
 *	CURSOR MOUSE
 * -----------------------------------------------------------------------------
*/
.kw-clickable {
  cursor: pointer;
}

/*
 * -----------------------------------------------------------------------------
 *	ICON COLORS
 * -----------------------------------------------------------------------------
*/
.icon-color-white .st0 {
  fill: white !important;
}

.icon-color-black .st0 {
  fill: black !important;
}

.icon-color-blue .st0 {
  fill: #3330e4 !important;
}

.icon-color-blue-dark .st0 {
  fill: #4a72b2 !important;
}

.icon-color-green .st0 {
  fill: green !important;
}

.icon-color-grey .st0 {
  fill: #77777a !important;
}

.icon-color-yellow .st0 {
  fill: #ffc107 !important;
}

.icon-color-red .st0 {
  fill: #c31c4a !important;
}

.icon-color-pink .st0 {
  fill: #ff4081 !important;
}

/*
 * -----------------------------------------------------------------------------
 *	TEXT
 * -----------------------------------------------------------------------------
*/
.kw-strikethrough {
  text-decoration: line-through;
}

.kw-uppercase {
  text-transform: uppercase;
}

.color-white {
  color: white !important;
}

.color-black {
  color: black !important;
}

.color-blue {
  color: #3330e4 !important;
}

.color-green {
  color: green !important;
}

.color-grey {
  color: #77777a !important;
}

.color-yellow {
  color: #ffc107 !important;
}

.color-red {
  color: #c31c4a !important;
}

.color-pink {
  color: #ff4081 !important;
}

/*
 * -----------------------------------------------------------------------------
 *	Z-INDEX:
 * -----------------------------------------------------------------------------
*/
.z-index-0 {
  z-index: 0 !important;
}

.z-index-1 {
  z-index: 1 !important;
}

.z-index-5 {
  z-index: 5 !important;
}

.z-index-10 {
  z-index: 10 !important;
}

.z-index-50 {
  z-index: 50 !important;
}

.z-index-100 {
  z-index: 100 !important;
}

.z-index-200 {
  z-index: 200 !important;
}

.z-index-300 {
  z-index: 300 !important;
}

.z-index-400 {
  z-index: 400 !important;
}

.z-index-500 {
  z-index: 500 !important;
}

.z-index-600 {
  z-index: 600 !important;
}

.z-index-700 {
  z-index: 700 !important;
}

.z-index-800 {
  z-index: 800 !important;
}

.z-index-900 {
  z-index: 900 !important;
}

.z-index-1000 {
  z-index: 1000 !important;
}
