login-form .background-1 {
  background: "#332fe4";
  background-image: url("/img/login/background-1.jpg");
  background-size: auto 100%;
}

login-form .background-2 {
  background: "#54e347";
  background-image: url("/img/login/background-2.jpg");
  background-size: auto 100%;
}

login-form .background-3 {
  background: "#332fe5";
  background-image: url("/img/login/background-3.jpg");
  background-size: 100% 100%;
}

@media screen and (max-width: 599px) {
  login-form .background-3 {
    background-size: auto 100%;
  }
}

login-form .background-4 {
  background: "#54e347";
  background-image: url("/img/login/background-4.jpg");
  background-size: 100% 100%;
}

@media screen and (max-width: 599px) {
  login-form .background-4 {
    background-size: auto 100%;
  }
}

login-form .background-5 {
  background: "#332fe4";
  background-image: url("/img/login/background-5.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
}

@media screen and (max-width: 599px) {
  login-form .background-5 {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    background-color: #332fe4;
  }
}

login-form .background-6 {
  background: "#54e347";
  background-image: url("/img/login/background-6.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
}

@media screen and (max-width: 599px) {
  login-form .background-6 {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    background-color: #54e347;
  }
}

login-form .background-7 {
  background: "#332fe5";
  background-image: url("/img/login/background-7.jpg");
  background-size: 100% 100%;
}

@media screen and (max-width: 599px) {
  login-form .background-7 {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    background-color: #332fe5;
  }
}

login-form .background-8 {
  background: "#54e347";
  background-image: url("/img/login/background-8.jpg");
  background-size: 100% 100%;
}

@media screen and (max-width: 599px) {
  login-form .background-8 {
    background-size: auto 100%;
  }
}

login-form .background-9 {
  background: "#2055d7";
  background-image: url("/img/login/background-9.jpg");
  background-size: 100% 100%;
}

@media screen and (max-width: 599px) {
  login-form .background-9 {
    background-size: auto 100%;
  }
}

login-form .background-10 {
  background: "#2055d7";
  background-image: url("/img/login/background-9.jpg");
  background-size: 100% 100%;
}

@media screen and (max-width: 599px) {
  login-form .background-10 {
    background-size: auto 100%;
  }
}

login-form .background-11 {
  background: "#2055d7";
  background-image: url("/img/login/background-9.jpg");
  background-size: 100% 100%;
}

login-form form {
  z-index: 19999;
  width: 300px;
  background: white;
}

login-form .icon-png {
  height: auto;
  width: 70%;
}

login-form .md-button.md-default-theme.md-raised,
login-form .md-button.md-raised {
  color: white;
  background-color: #f6b221;
}

login-form .md-button.md-default-theme.md-raised:not([disabled]):hover,
login-form .md-button.md-raised:not([disabled]):hover {
  background-color: black;
}

login-form .box-login {
  border-radius: 5px 5px 5px 5px !important;
}
