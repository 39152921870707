vacation-index .img-thumbnail {
  height: 55px;
  width: 80px;
  cursor: pointer;
}

vacation-index .img-no-thumbnail {
  height: 55px;
  width: 55px;
  cursor: pointer;
}

vacation-index .toolbar-table {
  padding: 0;
}
