md-dialog md-toolbar {
  background-color: #3330e4 !important;
}

.link-adaix {
  color: #3330e4 !important;
}

.icon-done {
  color: green;
}

.user-transfer-card {
  height: 200px !important;
  padding: 10px;
}

.property-transfer-card {
  padding: 5px;
  margin: 0px;
}

.icon-user-portal {
  height: 40px !important;
  width: 40px !important;
  border-radius: 50%;
  padding: 2px !important;
}

.user-divider-portal {
  margin-bottom: 10px;
  border-bottom: 1px solid #dcdcdc;
}

.agency-show-basic-image {
  object-fit: cover !important;
  height: "180px" !important;
  width: "100%" !important;
}

.dialog-contact-particular-show {
  height: "600px" !important;
}

.colors_info {
  height: 50px !important;
  padding-top: 15px;
  text-align: center !important;
  background-color: #f7ecec !important;
  margin: auto;
}

.ng-invalid {
  border-color: #f00 !important;
}

.warning-pisoscom {
  background-color: #c31c4a;
  color: white !important;
  max-width: 800px !important;
  margin: 20px 20px 20px 5px;
  padding: 10px 10px 10px 10px;
  font-weight: 600;
  border-radius: 10px 10px 10px 10px;
}

.cashier-payment-dialog {
  min-height: 400px !important;
  padding: 10px;
}

.cashier-payment-error {
  max-width: 400px !important;
  text-align: center !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  padding: 10px;
}

.cashier-payment-zone-plan {
  margin-top: 10px !important;
  background-color: #e8b4b4 !important;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.btn-cashier-payment-cancel-plan {
  height: 35px;
  width: 180px;
  padding: 2px;
  margin: 0px 5px 0px 5px;
  font-size: 10px;
}

.btn-cashier-payment-cancel-plan span {
  padding: 0px;
}

.btn-cashier-payment-cancel-plan md-icon {
  padding: 0px;
  margin: 0px;
}

.auth-register-subdomain-store-subdomain {
  background-color: #349de6;
  color: white;
  padding: 10px;
  border-radius: 10px 10px 10px 10px !important;
}

@media screen and (min-width: 600px) {
  .dialog-agency-show {
    margin-top: 50px !important;
  }
}

.plan-label, .bronze, .silver, .gold, .platinum {
  color: white;
  font-size: 24px;
  font-weight: 600;
  padding: 2px 8px;
  border-radius: 4px;
}

.bronze {
  background: #cd7f32 !important;
}

.silver {
  background: #c0c0c0 !important;
}

.gold {
  background: #ffd700 !important;
}

.platinum {
  background: #e5e4e2 !important;
}

.link-plus-info {
  margin-top: 10px;
  padding-left: 10px;
  text-decoration: none;
  color: #039be5;
}

.logo-portal-dialog {
  height: 50px !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.message-generic-dialog,
.warning-generic-dialog,
.warning-email-marketing-dialog {
  margin: 20px 20px 20px 5px;
  padding: 20px 10px 20px 10px;
  font-weight: 600;
  border-radius: 10px 10px 10px 10px;
}

.warning-generic-dialog,
.warning-email-marketing-dialog {
  background-color: #c31c4a;
  color: white;
}

.warning-meeting-dialog,
.warning-meeting-slim-dialog {
  margin: 0px 20px 5px 20px;
  padding: 2px 2px 2px 2px;
  max-width: 650px !important;
  font-weight: normal;
  font-size: 11px !important;
  border-radius: 10px 10px 10px 10px;
  background-color: #bbdefb !important;
  color: black;
}

.warning-email-marketing-dialog {
  padding: 12px 5px 10px 5px;
  max-width: 350px !important;
  font-weight: 400;
  font-size: 14px;
  height: 50px !important;
}

.property-store-photos-dialog img {
  height: 100%;
  width: 100%;
}

.logo-papernest-p,
.logo-papernest-xs-p {
  max-height: 100px !important;
  margin-top: 10px !important;
  background-color: white;
  padding: 10px;
  border-radius: 50%;
}

.logo-papernest-xs-p {
  max-height: 50px !important;
  padding: 5px;
}

.zone-top-dialog {
  height: 150px !important;
}

.grey-light-background-dialog-descriptions-chatgpt {
  background-color: #f7efef;
  border-radius: 10px;
  min-height: 150px;
  padding: 10px;
}

.grey-light-background-dialog-descriptions-chatgpt,
.grey-light-background-dialog-descriptions-chatgpt-warning {
  background-color: #f7efef;
  border-radius: 10px;
  min-height: 150px;
  padding: 10px;
}

.grey-light-background-dialog-descriptions-chatgpt-warning {
  min-height: 100px;
}

.formatted-description h3,
.formatted-description h2,
.formatted-description h1 {
  font-family: "Gilroy-Regular", sans-serif;
  font-weight: normal;
}

.formatted-description strong {
  font-weight: bold;
}

.formatted-description ul {
  list-style-type: disc;
  padding-left: 20px;
}

.formatted-description li {
  margin-bottom: 5px;
}

.formatted-description p {
  margin-bottom: 10px;
}

.key-property-image {
  width: 90%;
  float: center;
}

.key-card-size-mini {
  min-height: 400px !important;
}

.key-card-size {
  min-height: 680px !important;
}

.img-thumbnail-property-key {
  height: 100px;
  width: 150px;
  cursor: pointer;
}

.img-no-thumbnail-property-key {
  height: 55px;
  width: 55px;
  cursor: pointer;
}

.dialog-content-keychain {
  max-height: 80vh;
  overflow-y: auto;
  padding: 20px;
}

.dialog-content-keychain .dialog-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.dialog-content-keychain .dialog-header md-icon {
  font-size: 48px;
  margin-right: 10px;
}

.dialog-content-keychain .dialog-body .alert-options {
  margin-bottom: 10px;
}

.dialog-content-keychain .dialog-body .resume-section {
  margin-top: 20px;
}

.dialog-content-keychain .dialog-body .resume-section p {
  margin-bottom: 5px;
}
