agency-show-web .color-text {
  color: white;
}

agency-show-web .colors_info {
  height: 50px !important;
  padding: 15px 10px 15px 10px;
  text-align: center !important;
  background-color: #f7ecec !important;
  margin: auto;
}

agency-show-web .box-color {
  border: 1px solid #000000;
}

agency-show-web .imagen-web-upload {
  object-fit: cover;
  max-height: 300px !important;
  max-width: 300px !important;
}

@media screen and (max-width: 599px) {
  agency-show-web .imagen-web-upload {
    max-height: 280px !important;
    max-width: 280px !important;
  }
}
