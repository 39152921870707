agency-show-highlight {
  background-color: #fafafa;
}

agency-show-highlight .card-highlight {
  height: 150px !important;
  margin: 5px !important;
}

agency-show-highlight .card-highlight md-card-title-text md-icon {
  height: 60px !important;
  width: 60px !important;
}

agency-show-highlight .card-highlight #progress {
  margin-left: 13px !important;
  margin-top: -30px !important;
  font-size: 12px !important;
}

agency-show-highlight .card-highlight md-progress-circular svg path {
  stroke: #fbbc05;
}
