.div-container-insurance {
  text-align: center;
  margin-top: 50px;
}

.link-insurance {
  display: inline-block;
  background-color: #3330e4;
  color: white;
  font-size: 18px;
  font-weight: bold;
  padding: 12px 24px;
  border-radius: 8px;
  text-decoration: none;
}

.link-secondary-insurance {
  color: #3330e4;
  text-decoration: underline;
}

.p-insurance {
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 14px;
  color: #333;
}
