agency-show-map .agency-show-map-info {
  border: 1px solid #cfcfcf;
  height: 500px;
}

agency-show-map .as-map-overlay {
  position: relative;
  top: 20px;
  z-index: 11;
}

agency-show-map .as-map-overlay-button {
  position: absolute;
  right: calc(0%);
}
