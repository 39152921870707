contact-index a:link {
  text-decoration: none;
}

contact-index .toolbar-table {
  padding: 0;
}

@media screen and (max-width: 599px) {
  contact-index .limit-select {
    display: none;
  }
}

contact-index .contact-unsubscribe-alert {
  color: red !important;
}
