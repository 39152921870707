contact-show-workflow .workflow-line {
  position: relative;
  top: 27px;
  left: 48px;
  border: solid 1px;
  border-color: black;
}

contact-show-workflow .clickable {
  cursor: help;
}

contact-show-workflow .md-fab-grey {
  background: grey !important;
}
