operation-index-filter .canceled {
  background: #F44336;
}

operation-index-filter .confirmed {
  background: #1E88E5;
}

operation-index-filter .pending {
  background: #43A047;
}
