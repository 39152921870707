app-header .head-image {
  height: auto;
  width: 90%;
}

app-header .logo-background {
  background-color: #000000;
  width: 320px;
}

app-header .logo-flooges {
  fill: white;
  height: 41px;
  width: 119px;
}

@media screen and (max-width: 500px) {
  app-header .logo-flooges {
    height: 41px;
    width: 58px;
  }
}

@media screen and (min-width: 500px) {
  app-header .form-search-reference {
    margin-left: 15px;
  }
}

app-header .form-search-reference md-input-container {
  padding-left: 5px;
}

app-header .form-search-reference md-input-container input {
  min-width: 120px !important;
}

@media screen and (max-width: 500px) {
  app-header .form-search-reference md-input-container input {
    width: 60px !important;
  }
}

@media screen and (max-width: 500px) {
  app-header .form-search-reference md-input-container {
    padding-left: 1px;
  }
}

app-header .float-right {
  float: right;
}

app-header .mail-url {
  text-decoration: none !important;
  color: grey !important;
  font-weight: 600;
  margin-right: 0px !important;
  margin-left: 30px !important;
}

app-header .mail-url span {
  font-size: 14px !important;
  padding-top: -2px !important;
}

@media screen and (min-width: 500px) {
  app-header .mail-url {
    margin-right: 20px !important;
    margin-left: 5px !important;
  }
}

@media screen and (max-width: 500px) {
  app-header .button-menu {
    margin-left: 30px !important;
    margin-right: 1px !important;
    padding-right: 1px !important;
  }
}

@media screen and (max-width: 500px) {
  app-header .button-search {
    margin-left: 1px !important;
    margin-right: 1px !important;
    padding-left: 1px !important;
    padding-right: 1px !important;
    width: 80px !important;
  }
}
