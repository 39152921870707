.zone-mailrelay {
  background-color: #1c2433;
  color: white;
  max-height: 200px !important;
}

.logo-mailrelay {
  padding: 10px;
  border-radius: 5px;
  max-width: 380px;
}

.zone-row-mailrelay {
  margin: 20px 0px 20px 0px !important;
}
