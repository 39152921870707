contact-show-suggested .clickable {
  cursor: help;
}

contact-show-suggested a:link {
  text-decoration: none;
}

contact-show-suggested .img-thumbnail {
  height: 75px;
  width: 125px;
  cursor: pointer;
}

contact-show-suggested .img-no-thumbnail {
  height: 55px;
  width: 55px;
  cursor: pointer;
}

contact-show-suggested .toolbar-table {
  padding: 0;
}
