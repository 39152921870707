#login-form form {
  width: 300px;
  background: white;
}

@media screen and (max-width: 599px) {
  #login-form form {
    width: 280px;
  }
}

#login-form .icon-png {
  height: auto;
  width: 70%;
}

#login-form .md-button.md-default-theme.md-raised,
#login-form .md-button.md-raised {
  color: white;
  background-color: #f6b221;
}

#login-form .md-button.md-default-theme.md-raised:not([disabled]):hover,
#login-form .md-button.md-raised:not([disabled]):hover {
  background-color: black;
}

#login-form .box-login {
  border-radius: 5px 5px 5px 5px !important;
}

@media screen and (max-width: 599px) {
  #login-form .box-login {
    margin-top: 15px !important;
  }
}

#login-form .internal-form {
  margin-top: 20px !important;
  margin-bottom: 0px !important;
}

@media screen and (max-width: 599px) {
  #login-form .internal-form {
    margin-top: 5px !important;
  }
}

crm-captcha-simple h1 {
  font-size: 12px !important;
  margin: 10px !important;
}

crm-captcha-simple .margin-top-captcha {
  margin-top: 10px !important;
}

.background-1 {
  background: "#332fe4";
  background-image: url("/img/login/background-1.jpg");
  background-size: auto 100%;
}

.background-2 {
  background: "#54e347";
  background-image: url("/img/login/background-2.jpg");
  background-size: auto 100%;
}

.background-3 {
  background: "#332fe5";
  background-image: url("/img/login/background-3.jpg");
  background-size: 100% 100%;
}

@media screen and (max-width: 599px) {
  .background-3 {
    background-size: auto 100%;
  }
}

.background-4 {
  background: "#54e347";
  background-image: url("/img/login/background-4.jpg");
  background-size: 100% 100%;
}

@media screen and (max-width: 599px) {
  .background-4 {
    background-size: auto 100%;
  }
}

.background-5 {
  background: "#332fe4";
  background-image: url("/img/login/background-5.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
}

@media screen and (max-width: 599px) {
  .background-5 {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    background-color: #332fe4;
  }
}

.background-6 {
  background: "#54e347";
  background-image: url("/img/login/background-6.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
}

@media screen and (max-width: 599px) {
  .background-6 {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    background-color: #54e347;
  }
}

.background-7 {
  background: "#332fe5";
  background-image: url("/img/login/background-7.jpg");
  background-size: 100% 100%;
}

@media screen and (max-width: 599px) {
  .background-7 {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    background-color: #332fe5;
  }
}

.background-8 {
  background: "#54e347";
  background-image: url("/img/login/background-8.jpg");
  background-size: 100% 100%;
}

@media screen and (max-width: 599px) {
  .background-8 {
    background-size: auto 100%;
  }
}

.background-9 {
  background: "#2055d7";
  background-image: url("/img/login/background-9.jpg");
  background-size: 100% 100%;
}

@media screen and (max-width: 599px) {
  .background-9 {
    background-size: auto 100%;
  }
}

.background-10 {
  background: "#2055d7";
  background-image: url("/img/login/background-9.jpg");
  background-size: 100% 100%;
}

@media screen and (max-width: 599px) {
  .background-10 {
    background-size: auto 100%;
  }
}

.background-11 {
  background: "#2055d7";
  background-image: url("/img/login/background-9.jpg");
  background-size: 100% 100%;
}

#kw-map-extranet kw-map {
  height: 100% !important;
}

@media screen and (max-width: 599px) {
  #kw-map-extranet kw-map {
    height: 260px !important;
    width: 260px !important;
  }
}

.logo-portal-extranet {
  height: 50px !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.img-property {
  height: 100%;
  width: 100%;
}

property-show-video h3 {
  display: none !important;
}

.background-final-management {
  background-color: #96c596;
}

.btn-web {
  height: 42px;
  min-width: 320px;
  font-size: 16px;
  border-radius: 20px 20px 20px 20px !important;
  margin: 0px 5px 0px 5px;
}

@media screen and (max-width: 599px) {
  .btn-web {
    height: 32px;
    min-width: 260px;
    font-size: 12px;
    border-radius: 16px 16px 16px 16px !important;
    margin: 0px 5px 0px 5px;
  }
}

.avatar-icon {
  margin: 2px !important;
  padding: 0px !important;
  max-height: 30 !important;
  min-width: 30 !important;
}

.no-photo-extranet {
  max-height: 60px !important;
  margin: 5px !important;
}

.photo-extranet {
  max-height: 120px !important;
  margin: 20px !important;
  border-radius: 50%;
}

.workflow-extranet,
.agency-extranet,
.user-extranet {
  border: 1px solid #cfcfcf;
}

@media screen and (max-width: 599px) {
  .h1-extranet {
    font-size: 20px !important;
    margin-bottom: 10px !important;
  }
}

@media screen and (max-width: 599px) {
  .h2-extranet {
    font-size: 18px !important;
    margin-bottom: 10px !important;
  }
}

@media screen and (max-width: 599px) {
  dl {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
}

.botton-login {
  margin-top: 20px !important;
}

@media screen and (max-width: 599px) {
  .botton-login {
    margin-top: 0px !important;
  }
}

.zone-top {
  height: 100px !important;
}
