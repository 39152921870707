agency-update-service-external .color-text {
  color: white;
}

agency-update-service-external .colors_info {
  height: 50px !important;
  padding: 15px 10px 15px 10px;
  text-align: center !important;
  background-color: #f7ecec !important;
  margin: auto;
}

agency-update-service-external .box-color {
  border: 1px solid #000000;
}
