agency-update-multimedia .color-text {
  color: white;
}

agency-update-multimedia .box-color {
  border: 1px solid #000000;
}

agency-update-multimedia .imagen-web-upload {
  object-fit: cover;
  max-height: 300px !important;
  max-width: 300px !important;
}

@media screen and (max-width: 599px) {
  agency-update-multimedia .imagen-web-upload {
    max-height: 280px !important;
    max-width: 280px !important;
  }
}

agency-update-multimedia .botton-color-option {
  margin-bottom: 40px !important;
}

@media screen and (max-width: 599px) {
  agency-update-multimedia .botton-color-option {
    margin-bottom: 15px !important;
  }
}

agency-update-multimedia a.link-support-web:link {
  color: #c72049;
  font-weight: 600;
  margin-left: 5px;
}

agency-update-multimedia .zone-color-option {
  margin: 20px 0px 20px 0px !important;
}

@media screen and (max-width: 599px) {
  agency-update-multimedia .zone-color-option {
    margin: 0px 0px 0px 0px !important;
  }
}

agency-update-multimedia .botton-domain-option {
  margin: 10px 0px 40px 0px !important;
}

@media screen and (max-width: 599px) {
  agency-update-multimedia .botton-domain-option {
    font-size: 12px !important;
  }
}

agency-update-multimedia .zone-domain-option {
  font-size: 16px;
}

@media screen and (max-width: 599px) {
  agency-update-multimedia .zone-domain-option {
    font-size: 12px !important;
  }
}

agency-update-multimedia .zone-setting-web,
agency-update-multimedia .zone-setting-property {
  background-color: #54e346;
  color: white;
  max-width: 680px !important;
  margin: 20px 20px 20px 5px;
  padding: 20px 10px 20px 10px;
  font-weight: 600;
  border-radius: 10px 10px 10px 10px;
}

agency-update-multimedia .zone-setting-property {
  max-width: 1025px !important;
}
