[ui-view].ng-enter,
[ui-view].ng-leave {
  -webkit-transition: opacity ease-in-out 200ms;
  transition: opacity ease-in-out 200ms;
}

[ui-view].ng-enter,
[ui-view].ng-leave.ng-leave-active {
  opacity: 0;
}

[ui-view].ng-enter.ng-enter-active {
  opacity: 1;
}

.Page.ng-leave.ng-leave-active {
  display: none;
}
