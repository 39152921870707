agency-show-service {
  background-color: #fafafa;
}

agency-show-service a {
  text-decoration: none;
}

agency-show-service .color-icon-adaix {
  color: #4a72b2 !important;
}
