contact-show-suggested-collaboration-public .clickable {
  cursor: help;
}

contact-show-suggested-collaboration-public a:link {
  text-decoration: none;
}

contact-show-suggested-collaboration-public .img-thumbnail {
  height: 75px;
  width: 125px;
  cursor: pointer;
}

contact-show-suggested-collaboration-public .img-no-thumbnail {
  height: 55px;
  width: 55px;
  cursor: pointer;
}

contact-show-suggested-collaboration-public .toolbar-table {
  padding: 0;
}
