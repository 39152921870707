kw-map #kw-map {
  height: 100%;
  width: 100%;
  z-index: 10;
}

kw-map .cursor-pointer {
  cursor: pointer;
}

kw-map .icon-property {
  height: 24px;
  width: 24px;
}

kw-map .loading-map {
  position: absolute;
  top: 35%;
  left: 45%;
  z-index: 10000000;
}

@media screen and (max-width: 960px) {
  kw-map .loading-map {
    left: 40%;
  }
}

kw-map .leaflet-pane {
  z-index: 10;
}

kw-map .message {
  z-index: 100;
  height: 60px;
  position: relative;
  left: 46px;
  right: 5px;
  top: 12px;
  padding: 10px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  margin-left: 5px;
}

kw-map .message .icon {
  font-size: 32px;
  height: 32px;
  width: 32px;
  color: white;
}
