.autocomplete-user-template li {
  border-bottom: 1px solid #CCCCCC;
  height: auto;
  padding-top: 8px;
  padding-bottom: 8px;
  white-space: normal;
}

.autocomplete-user-template li:last-child {
  border-bottom-width: 0;
}

.autocomplete-user-template .user-info-basic, .autocomplete-user-template .user-info-extra {
  display: block;
  line-height: 2;
}
