app-menu lang,
app-menu .text-menu {
  color: white;
  font-weight: bold;
}

app-menu a:link {
  text-decoration: none;
}

app-menu .icon-menu {
  color: white;
}

app-menu .background {
  background-color: #3330e4 !important;
}

app-menu .background-yellow {
  background-color: #e3b037 !important;
}

app-menu .background-green {
  background-color: #54e346 !important;
}

app-menu .md-button.md-fab.md-mini {
  margin: 0;
  padding: 0;
  line-height: 35px;
  width: 34px;
  height: 36px;
}

app-menu .md-button.md-fab.md-mini .material-icons {
  font-size: 24px;
}

app-menu md-icon {
  color: white;
}
