slick .slick-slide {
  text-align: center;
}

slick .slick-slide img {
  display: inline;
  max-width: 100%;
}

slick .arrow-prev {
  left: 0px;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  margin-top: -10px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

slick .arrow-prev md-icon {
  font-size: 90px;
  width: 90px;
  height: 90px;
}

@media (max-width: 959px) {
  slick .arrow-prev {
    top: 100%;
  }
}

slick .arrow-next {
  right: 10px;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  margin-top: -10px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

slick .arrow-next md-icon {
  font-size: 90px;
  width: 90px;
  height: 90px;
}

@media (max-width: 959px) {
  slick .arrow-next {
    top: 100%;
  }
}
