.icon-user {
  height: 80px !important;
  width: 80px !important;
  border-radius: 50%;
  padding: 5px !important;
}

.user-divider {
  margin-bottom: 10px;
  border-bottom: 1px solid #DCDCDC;
}
