kw-label-efficiency table, kw-label-efficiency th, kw-label-efficiency td {
  border: 4px solid #f3f3f3;
  border-collapse: collapse;
}

kw-label-efficiency .classA, kw-label-efficiency .classB, kw-label-efficiency .classC, kw-label-efficiency .classD, kw-label-efficiency .classE, kw-label-efficiency .classF, kw-label-efficiency .classG {
  position: relative;
  display: block;
  height: 18px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 3px;
  line-height: 18px;
  color: #fff;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
}

kw-label-efficiency .classA::after, kw-label-efficiency .classB::after, kw-label-efficiency .classC::after, kw-label-efficiency .classD::after, kw-label-efficiency .classE::after, kw-label-efficiency .classF::after, kw-label-efficiency .classG::after {
  content: '';
  position: absolute;
  left: 100%;
  border: 9px solid transparent;
  border-left: 9px solid #fbb900;
}

kw-label-efficiency .classA > sup, kw-label-efficiency .classB > sup, kw-label-efficiency .classC > sup, kw-label-efficiency .classD > sup, kw-label-efficiency .classE > sup, kw-label-efficiency .classF > sup, kw-label-efficiency .classG > sup {
  position: absolute;
  top: -3px;
}

kw-label-efficiency .classA {
  width: 30px;
  background-color: #63aa5a;
  border-color: #63aa5a;
}

kw-label-efficiency .classA::after {
  border-left-color: #63aa5a;
}

kw-label-efficiency .classB {
  width: 37px;
  background-color: #7bae4a;
  border-color: #7bae4a;
}

kw-label-efficiency .classB::after {
  border-left-color: #7bae4a;
}

kw-label-efficiency .classC {
  width: 44px;
  background-color: #bdd342;
  border-color: #bdd342;
}

kw-label-efficiency .classC::after {
  border-left-color: #bdd342;
}

kw-label-efficiency .classD {
  width: 51px;
  background-color: #ffe731;
  border-color: #ffe731;
}

kw-label-efficiency .classD::after {
  border-left-color: #ffe731;
}

kw-label-efficiency .classE {
  width: 58px;
  background-color: #fbb900;
  border-color: #fbb900;
}

kw-label-efficiency .classE::after {
  border-left-color: #fbb900;
}

kw-label-efficiency .classF {
  width: 65px;
  background-color: #fb8800;
  border-color: #fb8800;
}

kw-label-efficiency .classF::after {
  border-left-color: #fb8800;
}

kw-label-efficiency .classG {
  width: 72px;
  background-color: #e30613;
  border-color: #e30613;
}

kw-label-efficiency .classG::after {
  border-left-color: #e30613;
}

kw-label-efficiency .tagBlack::before {
  content: '';
  position: absolute;
  right: 100%;
  border: 9px solid transparent;
  border-right: 9px solid black;
}

kw-label-efficiency .tagBlack {
  left: 16px;
  width: 45px;
  background-color: black;
  color: white;
  position: relative;
  display: block;
  height: 18px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 8px;
  line-height: 18px;
  color: #fff;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
}

kw-label-efficiency .font-efficiency-11 {
  font-size: 11px !important;
}
