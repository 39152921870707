operation-index a:link {
  text-decoration: none;
}

operation-index .toolbar-table {
  padding: 0;
}

@media screen and (max-width: 599px) {
  operation-index .limit-select {
    display: none;
  }
}

operation-index .img-thumbnail {
  height: 65px;
  width: 90px;
  cursor: pointer;
}

operation-index .canceled {
  background: #F44336;
}

operation-index .confirmed {
  background: #1E88E5;
}

operation-index .pending {
  background: #43A047;
}

@media screen and (max-width: 599px) {
  operation-index .zone-title-operation {
    width: 100% !important;
  }
}
