agency-show-user {
  background-color: #fafafa;
}

agency-show-user md-card {
  height: 280px !important;
  border-radius: 5px;
  padding-top: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

agency-show-user md-card img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid white;
  box-sizing: content-box;
  padding: 2px;
}

agency-show-user md-card md-card-title {
  height: 140px !important;
}

@media screen and (max-width: 599px) {
  agency-show-user md-card md-card-title {
    padding: 5px;
  }
}

agency-show-user md-card md-card-title md-card-title-text p, agency-show-user md-card md-card-title md-card-title-text span {
  text-align: center;
}

agency-show-user md-card md-card-title md-card-title-text p md-icon, agency-show-user md-card md-card-title md-card-title-text span md-icon {
  font-size: 16px;
}

@media screen and (max-width: 599px) {
  agency-show-user md-card md-card-title md-card-title-text p, agency-show-user md-card md-card-title md-card-title-text span {
    margin-bottom: 5px;
  }
}

agency-show-user md-card md-card-content {
  height: 60px !important;
}
