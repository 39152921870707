agency-show-info {
  background-color: #fafafa;
}

agency-show-info md-icon.icons-info {
  height: 150px;
  width: 150px;
  margin-left: 20px;
}

@media (max-width: 600px) {
  agency-show-info md-icon.icons-info {
    height: 90px;
    width: 90px;
    margin-left: 0;
  }
}

agency-show-info .kw-label-info {
  color: white;
  font-size: 100%;
  padding: 2px 8px;
  border-radius: 4px;
}
