@charset "UTF-8";
.responsive-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  /* Mantiene la proporción de la imagen y llena el espacio disponible */
  border-radius: 4px;
  /* Opcional: esquinas redondeadas */
  display: block;
  /* Elimina cualquier espacio no deseado */
}
