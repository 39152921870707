@charset "UTF-8";
ia-index .img-thumbnail {
  height: 55px;
  width: 80px;
  cursor: pointer;
}

ia-index .img-no-thumbnail {
  height: 55px;
  width: 55px;
  cursor: pointer;
}

ia-index .toolbar-table {
  padding: 0;
}

ia-index .card-ia {
  margin: 0px !important;
  min-height: 100vh;
}

ia-index .card-ia md-card-header {
  background-color: black;
  color: white;
}

ia-index .zone-chat-ia {
  background-color: white;
  color: black;
}

ia-index .zone-button {
  height: 100px !important;
}

ia-index .warning-errors {
  margin: 20px 20px 20px 5px;
  padding: 20px 10px 20px 10px;
  font-weight: 600;
  border-radius: 10px 10px 10px 10px;
  background-color: #54e346;
  color: white;
}

ia-index .warning-success {
  margin: 10px 10px 10px 5px;
  padding: 10px 5px 10px 5px;
  color: #155724;
  background-color: #d4edda;
  border-color: #bec5bf;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

ia-index .warning-success md-icon {
  color: #155724;
}

ia-index .btn-flash {
  box-shadow: 0 0 30px #4f9 !important;
}

ia-index a:link {
  text-decoration: none;
}

ia-index .ia-logo {
  max-width: 280px;
  max-height: 70px;
}

ia-index .logo-ia {
  background-color: white;
  max-width: 50px;
  max-height: 25px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 10px 10px 10px 10px;
}

ia-index .card-ia {
  background-color: #f5f5f5;
}

ia-index .md-card-header {
  background-color: #4caf50;
  color: white;
}

ia-index .zone-chat-ia {
  display: flex;
  flex-direction: column;
  flex: 1;
}

ia-index .messages-container {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
  min-height: 160px;
}

ia-index .message {
  padding: 5px 10px;
  margin: 5px 0;
  border-radius: 5px;
  word-wrap: break-word;
  /* Asegura que el texto largo se ajuste al contenedor */
  display: flex;
  align-items: center;
  position: relative;
  /* Para posicionar el botón de copiar */
}

ia-index .message-header {
  position: absolute;
  top: -10px;
  right: 10px;
}

ia-index .message.user {
  background-color: #e0f7fa;
  text-align: left;
  justify-content: flex-start;
}

ia-index .message.chatgpt {
  background-color: #fff8e1;
  text-align: right;
  justify-content: flex-end;
}

ia-index .account-icon,
ia-index .chatgpt-icon {
  margin-right: 8px;
}

ia-index .chatgpt-icon {
  margin-right: 8px;
  margin-left: 0px !important;
}

ia-index .message:nth-child(odd) {
  background-color: #e0e0e0;
  text-align: right;
  justify-content: flex-end;
}

ia-index .message:nth-child(even) {
  background-color: #c8e6c9;
  text-align: left;
  justify-content: flex-start;
}

ia-index .input-container {
  display: flex;
  align-items: center;
}

ia-index textarea {
  width: 100%;
  min-height: 50px;
  resize: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
}

ia-index .md-fab.md-mini.md-primary {
  margin-left: 10px;
}
