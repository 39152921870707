property-index-map a:link {
  text-decoration: none;
}

property-index-map .img-thumbnail {
  height: auto;
  cursor: pointer;
}

property-index-map .img-no-photo {
  height: auto;
  cursor: pointer;
}

property-index-map .toolbar-table {
  padding: 0;
}
