.card-hibo md-card-header {
  background-color: #001f49;
  color: white;
}

.zone-button {
  height: 100px !important;
}

.warning-errors {
  margin: 20px 20px 20px 5px;
  padding: 20px 10px 20px 10px;
  font-weight: 600;
  border-radius: 10px 10px 10px 10px;
  background-color: #54e346;
  color: white;
}

.warning-success {
  margin: 10px 10px 10px 5px;
  padding: 10px 5px 10px 5px;
  color: #155724;
  background-color: #d4edda;
  border-color: #bec5bf;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.warning-success md-icon {
  color: #155724;
}

.btn-flash {
  box-shadow: 0 0 30px #4f9 !important;
}

a:link {
  text-decoration: none;
}

.hibo-logo {
  max-width: 280px;
  max-height: 70px;
}

.logo-hibo {
  background-color: white;
  max-width: 50px;
  max-height: 25px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 10px 10px 10px 10px;
}
