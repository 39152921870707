agency-show-collaboration .img-agency {
  object-fit: cover;
  height: 300px !important;
  width: 100% !important;
}

agency-show-collaboration .img-logo-agency {
  object-fit: cover;
  height: 30px !important;
  width: auto !important;
  margin: 5px 10px 5px 20px;
}

agency-show-collaboration #agency-show-header {
  background-size: 100% 100% !important;
}

agency-show-collaboration #agency-show-header h1 {
  font-family: 'Varela Round', sans-serif !important;
  font-weight: bold;
  margin: 0px;
}

agency-show-collaboration #agency-show-header .agency-show-botton-zone {
  width: 800px !important;
}

@media screen and (max-width: 599px) {
  agency-show-collaboration #agency-show-header .agency-show-botton-zone {
    width: 400px !important;
  }
}

@media screen and (max-width: 599px) {
  agency-show-collaboration #angency-show-map .agency-show-map-info {
    height: 320px !important;
    width: 320px !important;
  }
}

agency-show-collaboration .as-map-overlay {
  position: relative;
  top: 20px;
  z-index: 11;
}

agency-show-collaboration .as-map-overlay-button {
  position: absolute;
  right: calc(0%);
}

agency-show-collaboration .agency-img {
  max-height: 500px;
  max-width: 650px;
}

agency-show-collaboration .contact-agency {
  padding: 5px 30px 5px 30px;
  margin-top: 25px;
  margin-bottom: 0px;
}

@media screen and (max-width: 599px) {
  agency-show-collaboration .contact-agency {
    padding: 0px;
    margin-bottom: 25px;
  }
}

agency-show-collaboration .contact-agency .button-contact {
  width: 100px !important;
  margin-left: 20px !important;
}

@media screen and (max-width: 599px) {
  agency-show-collaboration .contact-agency .button-contact {
    width: 140px !important;
    margin-left: 5px !important;
  }
}
