property-service-index .md-card-summary {
  text-align: center;
  background-color: #f5f5f5;
  margin-bottom: 20px;
  min-height: 150px;
  min-width: 150px;
}

property-service-index .md-toolbar-tools {
  color: white;
}

property-service-index .md-card-summary h3 {
  font-size: 24px;
  margin-bottom: 5px;
}

property-service-index .status-badge,
property-service-index .status-badge-red,
property-service-index .status-badge-yellow {
  display: inline-block;
  background-color: #3f51b5;
  color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-right: 10px;
}

property-service-index .status-badge-red {
  background-color: #c31c4a;
}

property-service-index .status-badge-yellow {
  background-color: #ff9800;
}
