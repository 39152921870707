.link-plus-info {
  margin-top: 10px;
  padding-left: 10px;
  text-decoration: none;
  color: #039be5;
}

.zone-login-extranet {
  width: 400px !important;
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.btn-flash {
  box-shadow: 0 0 30px #4f9 !important;
}
