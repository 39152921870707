.zone-embed-pdf {
  background-color: #d0cece;
  width: 100% !important;
  margin-top: 8px;
}

.embed-pdf {
  width: 100% !important;
  z-index: 99;
}
