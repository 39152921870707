.md-toolbar-register-form {
  max-height: 76px important;
}

.register-container {
  background-color: #d6d1d1 !important;
  padding-top: 20px;
  height: auto;
}

.legal-register-form {
  font-size: 12px;
  margin: 0px 0px 0px 10px;
  padding: 0px;
}

.card-access-data {
  background-color: #d6d1d1 !important;
}

.info-subdomain, .text-info-subdomain {
  background-color: #f1ebeb;
}

.info-subdomain {
  padding: 10px 5px 10px 5px;
}

.text-info-subdomain {
  padding: 5px;
  font-size: 12px;
}

a.link-support-register:link {
  color: #c72049;
  font-weight: 600;
  margin-left: 5px;
}

icon-register {
  color: black;
}

.terms-register-link, .legal-register-link {
  color: #039be5;
  font-weight: 600px;
}
