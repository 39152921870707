.md-content-list md-content {
  background-color: white !important;
}

.card-contact {
  min-height: 750px !important;
}

.zone-contact {
  background-color: #efebeb;
}

.form-support {
  min-height: 500px !important;
}

.zone-query-details {
  background-color: #54e346;
  color: white;
  margin: 40px 5px 20px 5px;
  padding: 20px 10px 20px 10px;
  border-radius: 10px 10px 10px 10px;
}

.zone-query-details span {
  font-size: 18px;
}

.zone-ticket {
  color: #54e346;
  background-color: white;
  margin: 40px 5px 20px 5px;
  padding: 20px 10px 20px 10px;
  border-radius: 10px 10px 10px 10px;
}

.textarea-query {
  background-color: #fafafa !important;
  border-radius: 10px 10px 10px 10px !important;
  margin-top: 30px !important;
  padding: 10px 20px 10px 10px !important;
}

.mdc-text-blue {
  color: #3330e4 !important;
}

.zone-divider {
  height: 30px !important;
  background-color: white !important;
}

.zone-button {
  height: 100px !important;
  background-color: !important;
}
