body {
  font-family: "Lato", sans-serif;
  overflow-y: hidden;
}

md-toolbar {
  background: #3330e4 !important;
}

.md-fab {
  background: #54e346 !important;
}

.md-toolbar-grey-light {
  background: #fafafa !important;
}
