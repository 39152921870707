cashier-plan .border-radius, cashier-plan .zone-plan-now, cashier-plan .zone-plan, cashier-plan .zone-calculated-subscription, cashier-plan .btn-cancel-plan, cashier-plan .zone-fiscal {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

cashier-plan .zone-plan-now {
  background-color: #ffd3d3;
  color: grey;
  font-weight: 600;
  height: 70px;
  margin-top: 20px;
}

cashier-plan .zone-plan {
  margin-top: 50px;
  background-color: #efebeb;
}

cashier-plan .btn-contract {
  height: 100px;
  width: 300px;
  font-size: 24px;
  border-radius: 10px 10px 10px 10px !important;
  margin: 0px;
}

cashier-plan .btn-cancel-plan {
  height: 35px;
  width: 180px;
  padding: 2px;
  margin: 0px 5px 0px 5px;
  font-size: 10px;
}

cashier-plan .btn-cancel-plan span {
  padding: 0px;
}

cashier-plan .btn-cancel-plan md-icon {
  padding: 0px;
  margin: 0px;
}

cashier-plan .btn-flash {
  box-shadow: 0 0 30px #4f9 !important;
}

cashier-plan .zone-fiscal {
  margin-top: 50px;
  background-color: #efebeb;
}
