property-reference .td-reference {
  padding-right: 10px !important;
  width: 180px !important;
}

property-reference a:link {
  text-decoration: none;
}

property-reference .img-thumbnail {
  height: 55px;
  width: 80px;
  cursor: pointer;
}

property-reference .img-no-thumbnail {
  height: 55px;
  width: 55px;
  cursor: pointer;
}

property-reference .toolbar-table {
  padding: 0;
}
