collaboration-map-pagination .paginate {
  background-color: rgba(169, 168, 168, 0.21);
  border-radius: 10px;
  padding: 1px;
  margin: 10px 5px 5px 5px;
}

@media screen and (max-width: 599px) {
  collaboration-map-pagination .paginate {
    margin: 0px 5px 0px 5px !important;
  }
}

collaboration-map-pagination .linkDisabled {
  cursor: not-allowed;
  pointer-events: none;
}

collaboration-map-pagination .md-button {
  background-color: #3330e4 !important;
}
