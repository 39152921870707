agency-show-contact .agency-show-contact-info {
  border: 1px solid #cfcfcf;
  height: 500px !important;
}

agency-show-contact .agency-show-contact-info .agency-show-contact-info-form {
  min-width: 320px !important;
}

agency-show-contact .mdc-text-blue {
  color: #3330e4 !important;
}
