collaboration-list-private .no-shadow-chips md-chips-wrap {
  box-shadow: none !important;
}

collaboration-list-private .img-thumbnail {
  height: 100px;
  width: 150px;
  cursor: pointer;
  padding: 2px;
  margin-right: 15px;
}

@media screen and (max-width: 599px) {
  collaboration-list-private .img-thumbnail {
    margin-right: 5px;
  }
}
