.u-center {
  text-align: center;
}

/* COLOR TEXTO*/
.text-white {
  color: white !important;
}

.text-blue {
  color: #3330e4 !important;
}

.text-black {
  color: black !important;
}

.text-red {
  color: #c31c4a !important;
}

.text-green {
  color: #009895 !important;
}

.text-grey-100 {
  color: #f5f5f5 !important;
}

.text-grey {
  color: #77777a !important;
}

.text-muted {
  color: rgba(0, 0, 0, 0.65);
}

.background-collaborator {
  background-color: #bbdefb;
}
