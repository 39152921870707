contact-show-selected .clickable {
  cursor: help;
}

contact-show-selected .kw-label-selected {
  font-size: 14px;
  margin-left: 5px;
  margin-right: 5px;
}

@media screen and (max-width: 599px) {
  contact-show-selected .kw-label-selected {
    font-size: 10px !important;
  }
}
