.captcha-input {
  width: 2em;
  text-align: center;
  margin-top: -5px;
}

.margin-top-captcha {
  margin-top: 50px !important;
}

@media screen and (max-width: 599px) {
  .margin-top-captcha {
    margin-top: 10px !important;
  }
}
