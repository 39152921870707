.autocomplete-template li {
  border-bottom: 1px solid #CCCCCC;
  height: auto10px;
  padding-top: 8px;
  padding-bottom: 8px;
  white-space: normal;
}

.autocomplete-template li:last-child {
  border-bottom-width: 0;
}

.autocomplete-template .item-info-basic, .autocomplete-template .item-info-extra {
  display: block;
  line-height: 2;
}
