cashier-plan .border-radius, cashier-plan .zone-plan-now, cashier-plan .zone-plan, cashier-plan .zone-calculated-subscription, cashier-plan .btn-cancel-plan, cashier-plan .zone-fiscal {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

cashier-plan .zone-cashier-plan-offer, cashier-plan .zone-cashier-pay-error {
  background-color: #54e346;
  color: white;
  max-width: 620px;
  margin: 20px 20px 20px 5px;
  padding: 20px 10px 20px 10px;
  border-radius: 10px 10px 10px 10px;
}

cashier-plan .zone-cashier-plan-offer span, cashier-plan .zone-cashier-pay-error span {
  font-style: italic;
  font-weight: 600;
  font-size: 18px;
}

cashier-plan .zone-cashier-pay-error {
  background-color: #f7eded;
  color: black;
  margin: 20px 20px 20px 5px;
  padding: 5px 2px 5px 5px;
}

cashier-plan .zone-cashier-pay-error p {
  font-weight: 600;
  font-size: 16px;
  margin: 5px;
}

cashier-plan .zone-cashier-pay-error ol {
  font-size: 12px;
  margin: 5px;
}

cashier-plan .zone-cashier-pay-error span, cashier-plan .zone-cashier-pay-error a {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

cashier-plan .zone-plan-now {
  background-color: #54e346;
  color: white;
  font-weight: 600;
  height: 70px;
  margin-top: 20px;
}

@media screen and (max-width: 599px) {
  cashier-plan .zone-plan-now {
    height: 120px !important;
  }
}

cashier-plan .zone-plan {
  margin-top: 50px;
  background-color: #efebeb;
}

cashier-plan .btn-contract {
  height: 100px;
  width: 300px;
  font-size: 24px;
  border-radius: 10px 10px 10px 10px !important;
  margin: 0px;
}

cashier-plan .btn-contract-print {
  height: 40px !important;
  width: 170px !important;
  font-size: 16px !important;
  border-radius: 10px 10px 10px 10px !important;
  margin: 35px 30px 0px 0px !important;
}

cashier-plan .btn-tax {
  border-radius: 10px 10px 10px 10px !important;
  padding: 10px;
}

cashier-plan .btn-cancel-plan {
  height: 35px;
  width: 180px;
  padding: 2px;
  margin: 0px 5px 0px 5px;
  font-size: 10px;
}

cashier-plan .btn-cancel-plan span {
  padding: 0px;
}

cashier-plan .btn-cancel-plan md-icon {
  padding: 0px;
  margin: 0px;
}

cashier-plan .btn-flash {
  box-shadow: 0 0 30px #4f9 !important;
}

cashier-plan .zone-fiscal {
  margin-top: 50px;
  background-color: #efebeb;
}

cashier-plan .link-plus-info {
  margin-top: 10px;
  padding-left: 10px;
  text-decoration: none;
  color: #039be5;
}

cashier-plan .plan-label, cashier-plan .bronze, cashier-plan .silver, cashier-plan .gold, cashier-plan .platinum {
  color: white;
  font-size: 24px;
  font-weight: 600;
  padding: 2px 8px;
  border-radius: 4px;
}

cashier-plan .bronze {
  background: #cd7f32 !important;
}

cashier-plan .silver {
  background: #c0c0c0 !important;
}

cashier-plan .gold {
  background: #ffd700 !important;
}

cashier-plan .platinum {
  background: #e5e4e2 !important;
}
