crm-photos-edit {
  border: 3px double rgba(0, 0, 0, 0.5);
  border-style: double double dashed double;
}

crm-photos-edit img {
  height: 120px;
  width: 180px;
}

crm-photos-edit .as-sortable-placeholder {
  display: inline-block !important;
  height: 180px !important;
}

crm-photos-edit .as-sortable-drag {
  opacity: 0.8;
}

crm-photos-edit .as-sortable-hidden {
  display: none !important;
}
