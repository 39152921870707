collaboration-list-public .no-shadow-chips md-chips-wrap {
  box-shadow: none !important;
}

collaboration-list-public .img-thumbnail {
  height: 100px;
  width: 150px;
  cursor: pointer;
  padding: 2px;
}

collaboration-list-public .img-no-thumbnail {
  height: 55px;
  width: 55px;
  cursor: pointer;
  padding: 2px;
}
