change-password md-content {
  background: #F6B221;
  background-image: url("/img/auth/background-1.jpg");
  background-size: 100% 100%;
  color: #3d3d42 !important;
}

@media screen and (min-width: 600px) {
  change-password .change-password-form-container {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 599px) {
  change-password .change-password-form-container h1 {
    margin: 0px 5px 2px 0px !important;
    font-size: 24px !important;
    font-weight: 600 !important;
  }
}

change-password .box-change-password-email, change-password .box-change-password-captcha {
  border-radius: 10px 10px 10px 10px;
  background-color: #efebeb;
  margin: 0px 10px 20px 10px;
}

change-password .box-change-password-email {
  min-height: 250px !important;
  padding-top: 15px;
}

@media screen and (min-width: 600px) {
  change-password .box-change-password-email {
    min-width: 350px !important;
  }
}

@media screen and (max-width: 599px) {
  change-password .box-change-password-email {
    min-width: 300px !important;
  }
}

change-password .box-change-password {
  margin: 0px !important;
  max-height: 80px !important;
}

change-password .box-change-password md-input-container {
  margin: 0px !important;
}

change-password .box-change-password-captcha {
  height: 100px;
  min-width: 200px;
  padding: 20px !important;
}
