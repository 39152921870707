register-form md-content {
  background: #F6B221;
  background-image: url("/img/auth/background-1.jpg");
  background-size: 100% 100%;
  color: #3d3d42 !important;
}

@media screen and (min-width: 600px) {
  register-form .md-toolbar-register-form {
    max-height: 76px !important;
  }
}

@media screen and (max-width: 599px) {
  register-form .md-toolbar-register-form {
    max-height: 64px !important;
  }
  register-form .md-toolbar-register-form h1 {
    margin: 0px 2px 2px -5px !important;
    font-size: 24px !important;
    font-weight: 600 !important;
  }
}

register-form .register-container {
  background-color: #d6d1d1 !important;
  height: auto;
  padding-top: 0px !important;
}

@media screen and (min-width: 600px) {
  register-form .register-container {
    padding-top: 20px !important;
  }
}

@media screen and (max-width: 599px) {
  register-form .register-form-container {
    margin-top: 0px !important;
  }
}

register-form .legal-register-form {
  font-size: 12px;
  margin: 0px 0px 0px 10px;
  padding: 0px;
}

register-form .info-subdomain, register-form .text-info-subdomain {
  background-color: #f1ebeb;
}

register-form .info-subdomain {
  padding: 10px 5px 10px 5px;
}

register-form .text-info-subdomain {
  padding: 5px;
  font-size: 12px;
}

register-form a.link-support-register:link {
  color: #c72049;
  font-weight: 600;
  margin-left: 5px;
}

register-form icon-register {
  color: black;
}

register-form .terms-register-link, register-form .legal-register-link {
  color: #039be5;
  font-weight: 600px;
}

register-form .margin-top-register {
  margin-top: 15px !important;
}

@media screen and (max-width: 599px) {
  register-form .margin-top-register {
    margin-top: 5px !important;
  }
}

@media screen and (max-width: 599px) {
  register-form .margin-bottom-register {
    margin-bottom: 10px !important;
    margin-top: 0px !important;
  }
}

register-form .zone-top {
  height: 100px !important;
}
