.md-content-list md-content {
  background-color: white !important;
}

.import-export-card {
  min-height: 750px !important;
}

.zone-contact {
  background-color: #efebeb;
}

.zone-query-details {
  background-color: #54e346;
  color: white;
  margin: 40px 5px 20px 5px;
  padding: 20px 10px 20px 10px;
  border-radius: 10px 10px 10px 10px;
}

.zone-query-details span {
  font-size: 18px;
}

.zone-ticket {
  color: #54e346;
  background-color: white;
  margin: 40px 5px 20px 5px;
  padding: 20px 10px 20px 10px;
  border-radius: 10px 10px 10px 10px;
}

.textarea-query {
  background-color: #fafafa !important;
  border-radius: 10px 10px 10px 10px !important;
  margin-top: 30px !important;
  padding: 10px 20px 10px 10px !important;
}

.mdc-text-blue {
  color: #3330e4 !important;
}

.zone-divider {
  height: 50px;
  background-color: white;
}

.zone-button {
  height: 100px !important;
}

.warning-errors {
  margin: 20px 20px 20px 5px;
  padding: 20px 10px 20px 10px;
  font-weight: 600;
  border-radius: 10px 10px 10px 10px;
  background-color: #54e346;
  color: white;
}

.warning-success {
  margin: 10px 10px 10px 5px;
  padding: 10px 5px 10px 5px;
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.warning-success md-icon {
  color: #155724;
}

.btn-flash {
  box-shadow: 0 0 30px #4f9 !important;
}

a:link {
  text-decoration: none;
}

.img-thumbnail {
  height: 100px;
  width: 150px;
  cursor: pointer;
}

.img-no-thumbnail {
  height: 55px;
  width: 55px;
  cursor: pointer;
}

.toolbar-table {
  padding: 0;
}

@media screen and (max-width: 599px) {
  .limit-select {
    display: none;
  }
}
