property-show-workflow .workflow-line {
  position: relative;
  top: 27px;
  left: 48px;
  border: solid 1px;
  border-color: black;
}

property-show-workflow .clickable {
  cursor: help;
}

property-show-workflow .md-fab-grey {
  background: grey !important;
}

property-show-workflow .label-status-workflow {
  margin-left: -30px !important;
  margin-right: 15px !important;
}

@media screen and (max-width: 599px) {
  property-show-workflow .label-status-workflow {
    margin: 0px;
  }
}
