.logo-index-papernest {
  clip-path: ellipse(12px 10px 12px 10px);
  background-color: white;
  border-radius: 35px;
  max-width: 220px;
  max-height: 65px;
}

.logo-index-xs-papernest {
  clip-path: ellipse(4px 3px 4px 3px);
  background-color: white;
  border-radius: 6px;
  max-width: 80px;
  max-height: 25px;
}

.div-container-papernest {
  text-align: center;
  margin-top: 50px;
}

.link-papernest {
  display: inline-block;
  background-color: #3330e4;
  color: white;
  font-size: 18px;
  font-weight: bold;
  padding: 12px 24px;
  border-radius: 8px;
  text-decoration: none;
}

.link-secondary-papernest {
  color: #3330e4;
  text-decoration: underline;
}

.p-papernest {
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 14px;
  color: #333;
}
