dashboard-meeting-day md-content {
  margin-top: 10px;
  height: 595px !important;
}

@media screen and (max-width: 959px) {
  dashboard-meeting-day md-content {
    height: 333px !important;
  }
}

dashboard-meeting-day .fc-center {
  margin-top: 10px;
}
