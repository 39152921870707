collaboration-property-index .td-reference {
  padding-right: 10px !important;
  width: 180px !important;
}

collaboration-property-index a:link {
  text-decoration: none;
}

collaboration-property-index .img-thumbnail {
  height: 55px;
  width: 80px;
  cursor: pointer;
}

collaboration-property-index .img-no-thumbnail {
  height: 55px;
  width: 55px;
  cursor: pointer;
}

collaboration-property-index .toolbar-table {
  padding: 0;
}

collaboration-property-index .space-span {
  height: 10px !important;
}
