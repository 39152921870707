property-index a:link {
  text-decoration: none;
}

property-index .img-thumbnail {
  height: 100px;
  width: 150px;
  cursor: pointer;
}

property-index .img-no-thumbnail {
  height: 55px;
  width: 55px;
  cursor: pointer;
}

property-index .toolbar-table {
  padding: 0;
}

@media screen and (max-width: 599px) {
  property-index .limit-select {
    display: none;
  }
}
