dashboard-contact-favourite {
  height: 150px !important;
}

dashboard-contact-favourite md-card-title-text md-icon {
  height: 60px !important;
  width: 60px !important;
}

dashboard-contact-favourite #progress {
  margin-left: 10px !important;
  margin-top: -35px !important;
}

dashboard-contact-favourite md-progress-circular svg path {
  stroke: #fbbc05;
}
