portal-property-index a:link {
  text-decoration: none;
}

portal-property-index .img-thumbnail {
  height: 55px;
  width: 80px;
  cursor: pointer;
}

portal-property-index .img-no-thumbnail {
  height: 55px;
  width: 55px;
  cursor: pointer;
}

portal-property-index .clickable {
  cursor: pointer;
}

portal-property-index .toolbar-table {
  padding: 0;
}
